import React from 'react';
import SVGTypes from './svg.types';

export const Back: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      onClick={props.onClick}
      width={props.width}
      height={props.height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3783_99591)">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
          fill={props.fill}
          fillOpacity="0.0001"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 40V40.5H40V40H0ZM30 20C30 20.3031 29.8749 20.5937 29.6522 20.808C29.4296 21.0223 29.1275 21.1426 28.8126 21.1426L15.0558 21.1427L20.1544 26.0469C20.3773 26.2615 20.5026 26.5525 20.5026 26.8559C20.5026 27.1593 20.3773 27.4503 20.1544 27.6649C19.9314 27.8795 19.629 28 19.3137 28C18.9984 28 18.696 27.8795 18.473 27.6649L11.3488 20.809C11.2383 20.7029 11.1505 20.5768 11.0907 20.4379C11.0308 20.2991 11 20.1503 11 20C11 19.8497 11.0308 19.7009 11.0907 19.5621C11.1505 19.4232 11.2383 19.2971 11.3488 19.191L18.473 12.3351C18.696 12.1205 18.9984 12 19.3137 12C19.629 12 19.9314 12.1205 20.1544 12.3351C20.3773 12.5497 20.5026 12.8407 20.5026 13.1441C20.5026 13.4475 20.3773 13.7385 20.1544 13.9531L15.0558 18.8574L28.8126 18.8573C29.1275 18.8573 29.4296 18.9777 29.6522 19.192C29.8749 19.4063 30 19.6969 30 20Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3783_99591">
          <path
            d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
            fill={props.fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Back;
