import React from 'react';
import { PageLoaderProps } from './PageLoader.types';
import image from './pageLoader.gif';
import './PageLoader.css';

const PageLoader: React.FC<PageLoaderProps> = (props) => {
  return (
    <div className="Center-Placer">
      <div className="Center-Div">
        <div className="Image-Holder">
          <img src={image} alt={props.alt ? props.alt : 'Loading'} />
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
