import React from 'react';
import SVGTypes from './svg.types';

export const Add: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      onClick={props.onClick}
      width={props.width}
      height={props.height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <g clipPath="url(#clip0_3783_99505)">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
          fill={props.fill}
          fillOpacity="0.0001"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 40V40.5H40V40H0ZM10.5154 20.0015C10.5152 19.87 10.541 19.7397 10.5912 19.6181C10.6415 19.4966 10.7152 19.3861 10.8082 19.2931C10.9013 19.2001 11.0117 19.1264 11.1333 19.0761C11.2548 19.0259 11.3851 19.0001 11.5166 19.0002L19.002 19.0016L19.0006 11.5162C19.0006 11.3847 19.0265 11.2545 19.0768 11.133C19.1272 11.0116 19.2009 10.9012 19.2939 10.8082C19.3869 10.7152 19.4972 10.6415 19.6187 10.5912C19.7402 10.5409 19.8704 10.515 20.0019 10.515C20.1334 10.515 20.2636 10.5409 20.3851 10.5912C20.5065 10.6415 20.6169 10.7152 20.7099 10.8082C20.8029 10.9012 20.8766 11.0116 20.9269 11.133C20.9773 11.2545 21.0032 11.3847 21.0032 11.5162L21.0017 19.0016L28.4872 19.0002C28.6187 19.0002 28.7489 19.0261 28.8703 19.0765C28.9918 19.1268 29.1022 19.2005 29.1952 19.2935C29.2882 19.3865 29.3619 19.4969 29.4122 19.6183C29.4625 19.7398 29.4884 19.87 29.4884 20.0015C29.4884 20.133 29.4625 20.2632 29.4122 20.3847C29.3619 20.5061 29.2882 20.6165 29.1952 20.7095C29.1022 20.8025 28.9918 20.8762 28.8703 20.9265C28.7489 20.9769 28.6187 21.0028 28.4872 21.0028L21.0017 21.0013L21.0032 28.4868C21.0032 28.6183 20.9773 28.7485 20.9269 28.8699C20.8766 28.9914 20.8029 29.1018 20.7099 29.1948C20.6169 29.2878 20.5065 29.3615 20.3851 29.4118C20.2636 29.4621 20.1334 29.488 20.0019 29.488C19.8704 29.488 19.7402 29.4621 19.6187 29.4118C19.4972 29.3615 19.3869 29.2878 19.2939 29.1948C19.2009 29.1018 19.1272 28.9914 19.0768 28.8699C19.0265 28.7485 19.0006 28.6183 19.0006 28.4868L19.002 21.0013L11.5166 21.0028C11.3851 21.0029 11.2548 20.9771 11.1333 20.9269C11.0117 20.8766 10.9013 20.8029 10.8082 20.7099C10.7152 20.6169 10.6415 20.5064 10.5912 20.3849C10.541 20.2633 10.5152 20.133 10.5154 20.0015Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3783_99505">
          <path
            d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
            fill={props.fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Add;
