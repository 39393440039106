import React from 'react';
import SVGTypes from './svg.types';

export const Cases: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >

      <g clipPath="url(#clip0_19_78399)">
            <path
              d="M28.4156 22.974L30.7737 24.1538C31.0762 24.305 31.0762 24.7368 30.7737 24.888L20.3677 30.0924C20.2537 30.1493 20.128 30.1789 20.0006 30.1789C19.8731 30.1789 19.7475 30.1493 19.6334 30.0924L9.22745 24.888C9.15947 24.8537 9.10234 24.8013 9.06242 24.7365C9.02251 24.6716 9.00137 24.597 9.00137 24.5209C9.00137 24.4448 9.02251 24.3701 9.06242 24.3053C9.10234 24.2405 9.15947 24.188 9.22745 24.1538L11.5869 22.974L18.8978 26.6301C19.5922 26.9766 20.4103 26.9766 21.1033 26.6301L28.4142 22.9726L28.4156 22.974ZM19.6334 9.08651C19.7475 9.02962 19.8731 9 20.0006 9C20.128 9 20.2537 9.02962 20.3677 9.08651L30.7737 14.2895C30.8416 14.324 30.8987 14.3766 30.9385 14.4415C30.9784 14.5065 30.9995 14.5811 30.9995 14.6573C30.9995 14.7335 30.9784 14.8082 30.9385 14.8731C30.8987 14.938 30.8416 14.9906 30.7737 15.0251L20.3677 20.2268C20.2537 20.2837 20.128 20.3133 20.0006 20.3133C19.8731 20.3133 19.7475 20.2837 19.6334 20.2268L9.22745 15.0251C9.15911 14.991 9.10163 14.9385 9.06145 14.8735C9.02128 14.8086 9 14.7337 9 14.6573C9 14.5809 9.02128 14.5061 9.06145 14.4411C9.10163 14.3761 9.15911 14.3236 9.22745 14.2895L19.6334 9.08651Z"
              fill={props.fill}
            />
            <path
              d="M28.4156 18.0419L30.7737 19.2216C31.0762 19.3729 31.0762 19.8046 30.7737 19.9559L20.3677 25.1589C20.2537 25.2158 20.128 25.2454 20.0006 25.2454C19.8731 25.2454 19.7475 25.2158 19.6334 25.1589L9.22745 19.9559C9.15947 19.9216 9.10234 19.8692 9.06242 19.8043C9.02251 19.7395 9.00137 19.6649 9.00137 19.5888C9.00137 19.5126 9.02251 19.438 9.06242 19.3732C9.10234 19.3084 9.15947 19.2559 9.22745 19.2216L11.5869 18.0419L18.8978 21.698C19.5922 22.0445 20.4103 22.0445 21.1033 21.698L28.4156 18.0419Z"
              fill={props.fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_19_78399">
              <path
                d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
                fill={props.fill}
              />
            </clipPath>
          </defs>
    </svg>
  );
};

export default Cases;
