import React, { FC, useState } from 'react';
import { PillControlProps } from './PillControl.types';
import Button from '../../general/button/Button';
import {
  BsArrowRightSquareFill,
  BsArrowLeftSquareFill,
  BsGear,
} from 'react-icons/bs';

import './PillControl.css';
import StatusBlock from '../../reusableBlocks/statusBlock/StatusBlock';

const PillControl: FC<PillControlProps> = ({
  nextLabel,
  backLabel,
  pages,
  pageChangeAction,
}) => {
  const [activeTab, setActiveTab] = useState(1);

  const renderButtons = () => {
    return (
      <div
        key="FormContainer_ButtonHolder"
        className="form-Container-button-container"
      >
        {pages.length < 2 ? null : pages.some(
            (page) =>
              page.orderNo < activeTab && page.enabled && page.showAsOrdered,
          ) ? (
          <Button
            itemKey="container_back"
            mode="neutral"
            label={backLabel ?? 'Back'}
            clickEvent={(e) => {
              const firstNextOrderNo = pages.find(
                (page) =>
                  page.orderNo < activeTab &&
                  page.enabled &&
                  page.showAsOrdered,
              )?.orderNo;
              if (firstNextOrderNo) {
                changePage(firstNextOrderNo);
              }
            }}
            data-testid="back-button"
          >
            <BsArrowLeftSquareFill className="icon tab-button-icon" />
          </Button>
        ) : null}
        {pages.length < 2 ? null : pages.some(
            (page) =>
              page.orderNo > activeTab && page.enabled && page.showAsOrdered,
          ) ? (
          <Button
            itemKey="container_forward"
            data-testid="NextButton"
            mode="positive"
            label={nextLabel ?? 'Next'}
            clickEvent={(e) => {
              const firstNextOrderNo = pages.find(
                (page) =>
                  page.orderNo > activeTab &&
                  page.enabled &&
                  page.showAsOrdered,
              )?.orderNo;
              if (firstNextOrderNo) {
                changePage(firstNextOrderNo);
              }
            }}
          >
            <span className="fix-icon-bg">
              <BsArrowRightSquareFill className="icon" />
            </span>
          </Button>
        ) : null}
      </div>
    );
  };

  const changePage = (num: number) => {
    if (pageChangeAction) {
      pageChangeAction(
        pages.findIndex((p) => p.orderNo === activeTab),
        pages.findIndex((p) => p.orderNo === num),
      );
    }

    if (pages.some((p) => p.orderNo == num && p.enabled)) {
      setActiveTab(num);
    }
  };

  const renderTabs = () => {
    return (
      <div className="form-Container-tab-container">
        {pages
          .sort((a, b) => {
            if (a.showAsOrdered && !b.showAsOrdered) {
              return -1;
            } else if (!a.showAsOrdered && b.showAsOrdered) {
              return 1;
            } else {
              return a.orderNo - b.orderNo;
            }
          })
          .map((p, i) => {
            return (
              <div
                key={`tab_${i}`}
                className={`form-Container-tab   ${
                  p.orderNo === activeTab ? 'active' : ''
                }${p.enabled ? '' : ' disabled'}`}
                onClick={(e) => {
                  if (!p.enabled) {
                    return;
                  }
                  changePage(p.orderNo);
                }}
                tabIndex={0}
                data-testid={`tab_${i}`}
              >
                <StatusBlock
                  boxSize="small"
                  boxState={p.enabled ? p.mode : 'locked'}
                  id={`tabMainStatus_${i}`}
                  key={`tabMainStatus_${i}`}
                  content={p.enabled ? (p.showAsOrdered ? i + 1 : '') : '-'}
                  showIcon={p.mode !== 'neutral'}
                />
                <label className="tab-label Impact-Regular">{p.name}</label>
              </div>
            );
          })}
      </div>
    );
  };

  return pages === null || pages === undefined || !pages ? (
    <div key="FormContainer_ErrorHolder">Error</div>
  ) : (
    <div className="form-container" key="FormContainer">
      {renderTabs()}
      <div key="FormContainer_Holder" className="form-Container-body-container">
        {!pages.length
          ? null
          : pages.map((page, i) => {
              return (
                <div
                  key={`${page.name}_${i}`}
                  hidden={activeTab !== page.orderNo}
                >
                  {page.content}
                </div>
              );
            })}
      </div>
      {pages.find((p) => p.orderNo === activeTab)?.showAsOrdered === false
        ? null
        : renderButtons()}
    </div>
  );
};

export default PillControl;
