import React, { createContext, useState, useEffect, useContext } from 'react';
import types from '../../../sysObjects/UserSettings.types';
import userSettingFile from '../../../config/userSettings.json';

interface UserSettingsContext {
  userSettings: types.Common;
  setUserSettings: React.Dispatch<React.SetStateAction<types.Common>>;
}

const UserSettingsContext = createContext<UserSettingsContext | undefined>(undefined);

export const  UserSettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userSettings, setUserSettings] = useState<types.Common>(userSettingFile as types.Common);

  useEffect(() => {
    const storedUserVars = localStorage.getItem('Connect-UserSettings');
    if (storedUserVars) {
      setUserSettings(JSON.parse(storedUserVars));
    } else {
      localStorage.setItem('Connect-UserSettings', JSON.stringify(userSettingFile));
      setUserSettings(userSettingFile as types.Common);
    }
  }, []);

  return (
    <UserSettingsContext.Provider value={{ userSettings, setUserSettings }}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export const useUserSettingsContext = () => {
  const context = useContext(UserSettingsContext);
  if (context === undefined) {
    throw new Error('useUserVars must be used within a UserVarsProvider');
  }
  return context;
};