import React from 'react';
import ButtonBoxTypes from './ButtonBox.types';
import Button from '../../general/button/Button';
import BaseBlock from '../baseBlock/BaseBlock';
import { SvgBad } from '../../svg';
import './ButtonBox.css';


const ButtonBox: React.FC<ButtonBoxTypes.Props> = (props) => {
  return (
    <div
          className={`Button-Box-Container${props.displayBorder !== false ? ' Border' : ''
              }${props.className ? ` ${props.className}` : ''}${props.buttons.length > 1 ? '' : ' Empty'}`}
    >
      {props.title && <div className='Button-Box-Header'>{props.title}</div>}
      <div className='Button-Box'>
        {props.buttons?.map((button, index) => {
          return (
            <Button
              key={`${props.id}_${button.id}-${index}`}
              mode={button.controlState}
              label={button.label}
              itemKey={`${props.id}_${button.id}`}
              testId={`${props.id}_${button.id}`}
              clickEvent={button.onClick}
            >
              {button.isChecked !== undefined && (
                <BaseBlock
                  boxSize="small"
                  className="Button-Box-BaseBlock-Checkable Default-Box"
                  id={`BaseBlock_${props.id}_${button.id}-${index}`}
                  key={`BaseBlock_${props.id}_${button.id}-${index}`}
                  content={
                    button.isChecked === true && <SvgBad className="Checkable" />
                  }
                />
              )}
            </Button>
          );
        })}
      </div>
    </div>
  );
};
export default ButtonBox;
