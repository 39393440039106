import React, {
  useState,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
} from 'react';

import  FormDropDownTypes from './FormDropDown.types';
import FormControllerContainer from '../formControlContainer/FormControlContainer';
import { ControlState, KeyValuePair } from '../../../../sysObjects/common.types';
import { FormControlRef } from '../formControlContainer/FormControlContainer.types';
import './FormDropDown.css';


const FormDropDown: ForwardRefRenderFunction<
  FormControlRef,
  FormDropDownTypes.Props
> = (props, ref) => {
  const [errorString, setErrorString] = useState<string>('');
  const [currentBoxState, setBoxState] = useState<ControlState>('neutral');
  const [disableDefault, setDisable] = useState<boolean>(false);

  const isTouched = React.useRef(false);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (props.readonly) {
      return;
    }
    if (props.onChange) {
      if (props.value !== '') {
        isTouched.current = true;
      }
      const id = props.id;
      const fieldId = props.fieldId;

      if (props.multiple) {
        const selectedOptions = Array.from(e.target.options)
          .filter((option) => option.selected)
          .map((option) => option.value);
        validateValue(selectedOptions);
        props.onChange({ id, value: selectedOptions, fieldId });
      } else {
        validateValue(e.target.value);
        props.onChange({ id, value: e.target.value, fieldId });
      }
    }
  };

  React.useEffect(() => {
    setDisable(!props.requiredDetails ? false : true);
  }, []);

  React.useEffect(() => {
    if (props.OverRideValidation) {
      validateValue();
    }
  }, [props.OverRideValidation]);

  const validateValue = (updatedValue?: string | number | string[] | null) => {
    if(props.hidden !== undefined && props.hidden){
      return true;
    }
    if (props.readonly !== undefined && props.readonly) {
      return true;
    }
    if (props.OverRideValidation !== undefined && props.OverRideValidation) {
      setBoxState('negative');
      return false;
    }

    if (updatedValue === undefined || updatedValue === null) {
      updatedValue = props.value;
    }

    if (props.requiredDetails) {
      if (Array.isArray(updatedValue) && updatedValue.length === 0) {
        setErrorString(props.requiredDetails.message || '');
        setBoxState('negative');
        return false;
      }

      if (typeof updatedValue === 'string' && updatedValue === '') {
        setErrorString(props.requiredDetails.message || '');
        setBoxState('negative');
        return false;
      }

      if (updatedValue === null || updatedValue === undefined) {
        setErrorString(props.requiredDetails.message || '');
        setBoxState('negative');
        return false;
      }

      setBoxState('positive');
      setErrorString('');
      return true;
    }
    setBoxState('positive');
    setErrorString('');
    return true;
  };

  useImperativeHandle(ref, () => ({
    triggerValidation: () => validateValue(),
    reset: () => {
      setBoxState('neutral');
      isTouched.current = false;
    },
  }));

  return (
    <FormControllerContainer
      id={props.id}
      boxState={currentBoxState}
      label={props.label}
      errorMessage={errorString}
      helpMessage={props.helpMessage}
      requiredText={props.requiredDetails?.formLabel}
      hidden={props.hidden}
      displayMode={props.displayMode}
      readonly={props.readonly}
    >
      <select
        className="form-select Text-Understated"
        required={!props.requiredDetails}
        key={props.id}
        id={props.id}
        data-testid={props.id}
        onChange={handleChange}
        onBlur={(e) => {
          if (!props.OverRideValidation) {
            handleChange(e);
          }
        }}
        value={props.value ?? ''}
        multiple={props.multiple}
        size={props.size}
      >
        {!props.defaultText ? null : (
          <option className='Text-Understated' value="" disabled={disableDefault}>
            {props.defaultText}
          </option>
        )}
        {props.items.map((i: KeyValuePair<any>) => {
          return (
            <option className='Text-Understated' key={`${props.id}_${i.key}`} value={i.key}>
              {i.value}
            </option>
          );
        })}
      </select>
    </FormControllerContainer>
  );
};

export default forwardRef(FormDropDown);
