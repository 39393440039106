import React from 'react';
import SVGTypes from './svg.types';

export const ToStart: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3783_99576)">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
          fill={props.fill}
          fillOpacity="0.0001"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 40V40.5H40V40H0ZM29.6522 20.808C29.8749 20.5937 30 20.3031 30 20C30 19.697 29.8749 19.4063 29.6522 19.192C29.4296 18.9777 29.1275 18.8573 28.8126 18.8573L15.0558 18.8574L20.1544 13.9531C20.3773 13.7385 20.5026 13.4475 20.5026 13.1441C20.5026 12.8407 20.3773 12.5497 20.1544 12.3351C19.9314 12.1205 19.629 12 19.3137 12C18.9984 12 18.696 12.1205 18.473 12.3351L11.3488 19.191C11.2383 19.2971 11.1505 19.4232 11.0907 19.5621C11.0308 19.7009 11 19.8497 11 20C11 20.1503 11.0308 20.2991 11.0907 20.4379C11.1505 20.5768 11.2383 20.7029 11.3488 20.809L18.473 27.6649C18.696 27.8795 18.9984 28 19.3137 28C19.629 28 19.9314 27.8795 20.1544 27.6649C20.3773 27.4503 20.5026 27.1593 20.5026 26.8559C20.5026 26.5525 20.3773 26.2615 20.1544 26.0469L15.0558 21.1427L28.8126 21.1427C29.1275 21.1427 29.4296 21.0223 29.6522 20.808ZM8.5 12C9.47462 12 10 12.597 10 13.3333L10 26.6667C10 27.403 9.47462 28 8.5 28C7.52538 28 7 27.403 7 26.6667L7 13.3333C7 12.597 7.52538 12 8.5 12Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3783_99576">
          <path
            d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
            fill={props.fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ToStart;
