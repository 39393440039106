import React from 'react';
import SVGTypes from './svg.types';

export const Retract: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19_78457)">
        <path
          d="M16.6763 18.6074H29.8126C30.1908 18.6074 30.5553 18.7518 30.8256 19.0119C31.0961 19.2723 31.25 19.6275 31.25 20C31.25 20.3725 31.0961 20.7277 30.8256 20.9881C30.5553 21.2482 30.1908 21.3927 29.8126 21.3927H16.6763L21.3277 25.8667L21.3277 25.8668C21.5985 26.1274 21.7526 26.483 21.7526 26.8559C21.7526 27.2288 21.5985 27.5844 21.3277 27.845C21.0572 28.1054 20.6923 28.25 20.3137 28.25C19.9351 28.25 19.5702 28.1054 19.2997 27.845L12.1757 20.9894L16.6763 18.6074ZM16.6763 18.6074L21.3277 14.1333L21.3277 14.1332C21.5985 13.8726 21.7526 13.517 21.7526 13.1441C21.7526 12.7712 21.5985 12.4156 21.3277 12.155C21.0572 11.8946 20.6923 11.75 20.3137 11.75C19.9351 11.75 19.5702 11.8946 19.2997 12.155L12.1757 19.0106L16.6763 18.6074ZM11.8611 19.4631C11.9343 19.2933 12.0413 19.1397 12.1755 19.0109V20.9891C12.0413 20.8603 11.9343 20.7067 11.8611 20.5369C11.7878 20.367 11.75 20.1845 11.75 20C11.75 19.8155 11.7878 19.633 11.8611 19.4631Z"
          fill={props.fill}
          stroke={props.stroke}
          strokeWidth="0.5"
        />
        <path
          d="M11.25 13.3333L11.25 26.6667C11.25 27.0956 11.0959 27.497 10.788 27.7904C10.48 28.0839 10.0391 28.25 9.5 28.25C8.96089 28.25 8.51998 28.0839 8.21204 27.7905C7.90409 27.497 7.75 27.0956 7.75 26.6667L7.75 13.3333C7.75 12.9044 7.90409 12.503 8.21204 12.2096C8.51998 11.9161 8.96089 11.75 9.5 11.75C10.0391 11.75 10.48 11.9161 10.788 12.2095C11.0959 12.503 11.25 12.9044 11.25 13.3333Z"
          fill={props.fill}
          stroke={props.stroke}
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_19_78457">
          <path
            d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
            fill={props.fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Retract;
