import React from 'react';
import SVGTypes from './svg.types';

export const Forward: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3783_99606)">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
          fill={props.fill}
          fillOpacity="0.0001"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 40L0 40V40.5H40V40ZM10 20C10 19.6969 10.1251 19.4063 10.3478 19.192C10.5704 18.9777 10.8725 18.8574 11.1874 18.8574L24.9442 18.8573L19.8456 13.9531C19.6227 13.7385 19.4974 13.4475 19.4974 13.1441C19.4974 12.8407 19.6227 12.5497 19.8456 12.3351C20.0686 12.1205 20.371 12 20.6863 12C21.0016 12 21.304 12.1205 21.527 12.3351L28.6512 19.191C28.7617 19.2971 28.8495 19.4232 28.9093 19.5621C28.9692 19.7009 29 19.8497 29 20C29 20.1503 28.9692 20.2991 28.9093 20.4379C28.8495 20.5768 28.7617 20.7029 28.6512 20.809L21.527 27.6649C21.304 27.8795 21.0016 28 20.6863 28C20.371 28 20.0686 27.8795 19.8457 27.6649C19.6227 27.4503 19.4974 27.1593 19.4974 26.8559C19.4974 26.5525 19.6227 26.2615 19.8457 26.0469L24.9442 21.1427L11.1874 21.1427C10.8725 21.1427 10.5704 21.0223 10.3478 20.808C10.1251 20.5937 10 20.303 10 20Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3783_99606">
          <path
            d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
            fill={props.fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Forward;
