import React from 'react';
import SVGTypes from './svg.types';

export const ExternalUser: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19_78403)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 8H29C30.6569 8 32 9.34315 32 11V29C32 30.6569 30.6569 32 29 32H11C9.34315 32 8 30.6569 8 29V11C8 9.34315 9.34315 8 11 8ZM6 11C6 8.23858 8.23858 6 11 6H29C31.7614 6 34 8.23858 34 11V29C34 31.7614 31.7614 34 29 34H11C8.23858 34 6 31.7614 6 29V11ZM22.8284 18.8284C22.0783 19.5786 21.0609 20 20 20C18.9391 20 17.9217 19.5786 17.1716 18.8284C16.4214 18.0783 16 17.0609 16 16C16 14.9391 16.4214 13.9217 17.1716 13.1716C17.9217 12.4214 18.9391 12 20 12C21.0609 12 22.0783 12.4214 22.8284 13.1716C23.5786 13.9217 24 14.9391 24 16C24 17.0609 23.5786 18.0783 22.8284 18.8284ZM26.6667 28C26.6667 28 27.7673 28 27.9686 27H28L27.977 26.9544C27.9919 26.8662 28 26.7705 28 26.6667C28 25.3333 26.6667 21.3333 20 21.3333C13.3333 21.3333 12 25.3333 12 26.6667C12 28 13.3333 28 13.3333 28H26.6667Z"
        fill={props.fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_19_78403">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
          fill={props.fill}
        />
      </clipPath>
    </defs>
    </svg>
  );
};
export default ExternalUser;
