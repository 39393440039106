import React from 'react';
import PickerTypes from './AdvancedDatePicker.types';
import './AdvancedDatePicker.css';
import {
  FaArrowRight,
  FaArrowLeft,
  FaRegClock,
  FaUserDoctor,
} from 'react-icons/fa6';
import { Direction } from '../../../../../../sysObjects/common.types';
import ActionBar from '../../../../../sharedControls/general/actionBar/ActionBar';
import Button from '../../../../../sharedControls/general/button/Button';

export const AdvancedDatePicker: React.FC<PickerTypes.Props> = (props) => {
  const [Year, setYear] = React.useState<number>(props.value.getFullYear());
  const [Month, setMonth] = React.useState<number>(props.value.getMonth());
  const [Day, setDay] = React.useState<number>(0);
  const [Hour, setHour] = React.useState<number | null>(null);
  const [Minutes, setMinutes] = React.useState<number | null>(null);
  const [Users, setUsers] = React.useState<PickerTypes.UserDetail[]>([]);
  const [User, setUser] = React.useState<PickerTypes.UserDetail | null>(null);

  const getNumberOfDaysInMonth = (): number => {
    return new Date(Year, Month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (): number => {
    return new Date(Year, Month, 1).getDay();
  };

  const adjustFirstDay = (firstDay: number): number => {
    return (firstDay - props.startDayOfWeek + 7) % 7;
  };

  /**
   * Rotates the array of day labels to start from a specified day of the week.
   * @returns The adjusted array of day labels.
   */
  const adjustDaysArray = (): string[] => {
    return props.labels.daysOfWeek
      .slice(props.startDayOfWeek)
      .concat(props.labels.daysOfWeek.slice(0, props.startDayOfWeek));
  };

  /**
   * Handles the month change
   * @param direction The direction to change the month
   * @returns void
   */
  const handleMonthChange = (direction: Direction) => {
    setDay(0);
    setHour(null);
    setMinutes(null);
    setUsers([]);
    setUser(null);
    let newMonth = Month;
    let newYear = Year;

    if (direction === 'Down') {
      if (
        Month > props.startingDate.getMonth() ||
        Year > props.startingDate.getFullYear()
      ) {
        if (Month === 0) {
          newMonth = 11;
          newYear = Year - 1;
        } else {
          newMonth = Month - 1;
        }
      } else {
        return;
      }
    } else {
      if (
        props.maxAdvancedMonths === undefined ||
        Month < props.startingDate.getMonth() + props.maxAdvancedMonths
      ) {
        if (Month === 11) {
          newMonth = 0;
          newYear = Year + 1;
        } else {
          newMonth = Month + 1;
        }
      } else {
        return;
      }
    }

    if (!props.events.monthChange || props.events.monthChange === undefined) {
      return;
    }

    // Update the month and year state
    setMonth(newMonth);
    setYear(newYear);

    // Fire the onMonthChange event with the new month and year
    props.events.monthChange({
      day:
        newMonth === props.startingDate.getMonth()
          ? props.startingDate.getDate()
          : 1,
      month: newMonth,
      year: newYear,
    });
  };

  /**
   * This function takes an array of user IDs and returns a sorted array of user details.
   * The sorting is based on the number of times each user appears in the `props.highlightDates` array,
   * with users appearing more frequently being placed earlier in the returned array.
   * Each user's detail includes their `userId` and `displayName`.
   * @param userIds The array of user IDs to sort.
   * @returns The sorted array of user details.
   */
  const getUsersOrderedByTimeSlots = (
    userIds: string[],
  ): PickerTypes.UserDetail[] => {
    const userCountMap: Map<
      string,
      { count: number; userDetail: PickerTypes.UserDetail }
    > = new Map();

    const filteredSlots = props.highlightDates.filter((slot) =>
      userIds.includes(slot.userId),
    );

    filteredSlots.forEach((slot) => {
      const key = slot.userId;
      if (!userCountMap.has(key)) {
        userCountMap.set(key, {
          count: 0,
          userDetail: { userId: slot.userId, displayName: slot.displayName },
        });
      }
      userCountMap.get(key)!.count++;
    });

    const sortedUsers = Array.from(userCountMap.values())
      .sort((a, b) => b.count - a.count)
      .map((entry) => entry.userDetail);

    return sortedUsers;
  };

  /**
   * This function filters slots by the selected day, month, and year, then merges times within these slots.
   * It creates a map where each key is a unique time (hour:minute) and each value is an object containing the time and an array of users available at that time.
   * Finally, it returns an array of these merged time slots, each including the time and the users available at that time.
   * @returns The array of merged time slots.
   */
  const filterAndMergeTimes = (): PickerTypes.FilterSlot[] => {
    const filteredSlots = props.highlightDates.filter(
      (slot) => slot.day === Day && slot.month === Month && slot.year === Year,
    );

    const mergedTimesMap: Map<string, PickerTypes.FilterSlot> = new Map();

    filteredSlots.forEach((slot) => {
      slot.times.forEach((time) => {
        const key = `${time.hour}:${time.minute}`;
        if (!mergedTimesMap.has(key)) {
          mergedTimesMap.set(key, {
            hour: time.hour,
            minute: time.minute,
            users: [],
          });
        }
        mergedTimesMap.get(key)?.users.push({
          userId: slot.userId,
          displayName: slot.displayName,
        });
      });
    });

    return Array.from(mergedTimesMap.values());
  };

  const renderTimes = () => {
    let filteredSlots: PickerTypes.FilterSlot[] = [];

    if (Day !== 0) {
      filteredSlots = filterAndMergeTimes();
    }

    return (
      <>
        <div
          className={`TimeView ${Users.length === 0 ? 'NoPerson' : 'Person'}`}
        >
          {filteredSlots
            .sort((a, b) =>
              a.hour === b.hour ? a.minute - b.minute : a.hour - b.hour,
            )
            .map((slot) => {
              const selected =
                Hour !== null &&
                Minutes !== null &&
                Hour === slot.hour &&
                Minutes === slot.minute
                  ? 'selected'
                  : '';
              return (
                <ActionBar
                  key={`Time_${slot.hour}${slot.minute}_${props.id}`}
                  otherClasses={`${selected}`}
                  content={<FaRegClock />}
                  label={`${
                    slot.hour.toString().length < 2
                      ? '0' + slot.hour
                      : slot.hour
                  }:${
                    slot.minute.toString().length < 2
                      ? '0' + slot.minute
                      : slot.minute
                  }`}
                  onClick={() => {
                    setHour(slot.hour);
                    setMinutes(slot.minute);
                    if (slot.users.length === 0 || slot.users === undefined) {
                      setUsers([]);
                      setUser(null);
                      return;
                    } else if (slot.users.length === 1) {
                      setUsers([...slot.users]);
                      setUser(slot.users[0]);
                      return;
                    }
                    const users = getUsersOrderedByTimeSlots(
                      slot.users.map((user) => user.userId),
                    );
                    setUsers([...users]);
                    setUser(users[0]);
                  }}
                  status="positive"
                />
              );
            })}
        </div>
      </>
    );
  };

  const renderSelection = () => {
    let selection: string = '';

    if (Day !== 0) {
      selection = `${Day}-${props.labels.monthNames[Month]}-${Year}`;
    }

    if (Hour !== null && Minutes !== null) {
      selection = `${selection} ${
        Hour.toString().length < 2 ? '0' + Hour : Hour
      }:${Minutes.toString().length < 2 ? '0' + Minutes : Minutes}`;
    }
    if (User) {
      selection = `${selection} ${User.displayName}`;
    }

    return <div className="Selection-Item">{selection}</div>;
  };

  const renderUsers = () => {
    return Users.length !== 0 ? (
      <div className="Users-Selection">
        {Users.map((user) => (
          <ActionBar
            label={user.displayName}
            content={<FaUserDoctor />}
            status="neutral"
            key={user.userId}
            otherClasses={`${User?.userId === user.userId ? 'selected' : ''}`}
            onClick={() => setUser(user)}
          />
        ))}
      </div>
    ) : null;
  };
  const renderButtons = () => {
    return User === null ? (
      <></>
    ) : (
      <div className="Button-Block">
        <Button
          label={props.labels.bookButton}
          itemKey="Book"
          mode="positive"
          clickEvent={() => {
            if (
              !props.events.onBooking ||
              props.events.onBooking === undefined
            ) {
              return;
            }
            props.events.onBooking({
              userId: User!.userId,
              date: { day: Day, month: Month, year: Year },
              Time: { hour: Hour!, minute: Minutes! },
              username: User.displayName,
            });
          }}
        />
        <Button
          label={props.labels.resetButton}
          itemKey="Reset"
          mode="positive"
          clickEvent={() => {
            setDay(0);
            setHour(null);
            setMinutes(null);
            setUsers([]);
            setUser(null);
          }}
        />
      </div>
    );
  };

  const renderDays = () => {
    const numberOfDays = getNumberOfDaysInMonth();
    const firstDay = getFirstDayOfMonth();
    const adjustedFirstDay = adjustFirstDay(firstDay);
    const days = Array.from({ length: numberOfDays }, (_, i) => i + 1);
    const slots = Array(adjustedFirstDay).fill(null).concat(days);

    return slots.map((day, i) => {
      const highlighted = props.highlightDates.some(
        (item) => item.day === day && item.month === Month,
      );
      return (
        <div
          onClick={() => {
            if (!highlighted) {
              return;
            }
            setDay(day);
            setUsers([]);
            setUser(null);
            setHour(null);
            setMinutes(null);
          }}
          className={`item boxed Action-AllCaps ${day ? 'content' : ''} ${
            Day === day ? 'selected' : highlighted ? 'highlighted' : ''
          }`}
          key={`day_${i}_${props.id}`}
        >
          {day}
        </div>
      );
    });
  };

  const renderDaysView = () => {
    const hide = Month === undefined;
    return (
      <div className="DayView" hidden={hide}>
        {adjustDaysArray().map((day, i) => (
          <div key={`day_${i}_${props.id}`} className="headerItem">
            {day}
          </div>
        ))}
        {renderDays()}
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="Date-Selection">
        <div onClick={() => handleMonthChange('Down')}>
          <FaArrowLeft />
        </div>
        <div>{props.labels.monthNames[Month]}</div>
        <div>{Year}</div>
        <div onClick={() => handleMonthChange('Up')}>
          <FaArrowRight />
        </div>
      </div>
    );
  };

  const renderEmptyTime = () => {
    return <div className="TimeView"></div>;
  };

  return (
    <>
      <div className="AdvancedDatePicker lexxic-text">
        {renderSelection()}
        {renderHeader()}
        {renderDaysView()}
        {Day === 0 ? renderEmptyTime() : renderTimes()}
        {renderUsers()}
        {renderButtons()}
      </div>
    </>
  );
};

export default AdvancedDatePicker;
