import React from 'react';
import ViewTasks from '../../systemComponents/targetedPageControls/taskview/ViewTasks';
import { useIntl } from 'react-intl';
import CommonPageContext from '../../systemComponents/sharedControls/contexts/CrumbUpdateContext';

const Dashboard: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const context = React.useContext(CommonPageContext);

  React.useEffect(() => {
    context?.handleCrumbUpdate(locales.breadcrumbs);
  }, []);

  return (
    <div className="Dashboard" style={{ width: '500px' }}>
      <ViewTasks />
    </div>
  );
};

export default Dashboard;
