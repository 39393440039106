import React from 'react';
import SVGTypes from './svg.types';

export const Good: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
        fill={props.fill}
        fillOpacity="0.0001"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2078 12.292C31.3009 12.3849 31.3748 12.4953 31.4252 12.6168C31.4756 12.7382 31.5016 12.8685 31.5016 13C31.5016 13.1316 31.4756 13.2618 31.4252 13.3833C31.3748 13.5048 31.3009 13.6151 31.2078 13.708L17.2078 27.708C17.1149 27.8011 17.0046 27.875 16.8831 27.9254C16.7616 27.9759 16.6313 28.0018 16.4998 28.0018C16.3683 28.0018 16.238 27.9759 16.1165 27.9254C15.995 27.875 15.8847 27.8011 15.7918 27.708L8.7918 20.708C8.60402 20.5202 8.49854 20.2656 8.49854 20C8.49854 19.7345 8.60402 19.4798 8.7918 19.292C8.97957 19.1042 9.23425 18.9988 9.4998 18.9988C9.76535 18.9988 10.02 19.1042 10.2078 19.292L16.4998 25.586L29.7918 12.292C29.8847 12.1989 29.995 12.125 30.1165 12.0746C30.238 12.0242 30.3683 11.9982 30.4998 11.9982C30.6313 11.9982 30.7616 12.0242 30.8831 12.0746C31.0046 12.125 31.1149 12.1989 31.2078 12.292Z"
        fill={props.fill}
      />
    </svg>
  );
};
export default Good;
