import { EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import PageLoader from "../sharedControls/general/loading/pageLoader/PageLoader";

export const AuthenticatedFilter: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { instance } = useMsal();
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const now = new Date();
    const activeAccount = instance.getActiveAccount();

    const isAuthenticated =
      activeAccount !== null && activeAccount.idTokenClaims!.exp! > now.getTime() / 1000;
    setAuthenticated(isAuthenticated);

    const callbackId = instance.addEventCallback((event) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
      ) {
        setAuthenticated(true);
      } else if (
        event.eventType === EventType.LOGOUT_SUCCESS ||
        event.eventType === EventType.LOGOUT_END ||
        event.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
        event.eventType === EventType.SSO_SILENT_FAILURE
      ) {
        setAuthenticated(false);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  return instance.getActiveAccount()
    ? authenticated
      ? <> {children} </>
      : (
        <div>
          <PageLoader alt="Loading" />
        </div>
      )
    : null;
};