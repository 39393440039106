/**
 * Represents the result of a fetch operation.
 * @template T - The type of the result data.
 */
export class FunctionalResult<T> {
  private _isSuccess: boolean;
  private _result?: T;
  private _error?: string;
  private _errorCode?: number;

  /**
   * Constructs a FunctionalResult instance.
   * @param isSuccess - Indicates if the fetch operation was successful.
   * @param result - The result data (if successful).
   * @param error - The error message (if unsuccessful).
   * @param errorCode - The errorCode (if unsuccessful)
   */
  private constructor(
    isSuccess: boolean,
    result?: T,
    error?: string,
    errorCode?: number 
  ) {
    this._isSuccess = isSuccess;
    this._result = result;
    this._error = error;
    this._errorCode = errorCode;
  }

  /**
   * Creates a Result instance for a successful operation.
   * @param result - The result data.
   * @returns A FunctionalResult instance with success status and result data.
   */
  static Success<T>(result?: T): FunctionalResult<T> {
    if (result === null || result === undefined) {
      return new FunctionalResult<T>(true);
    }
    return new FunctionalResult<T>(true, result);
  }

  /**
   * Creates a Result instance for an unsuccessful operation.
   * @param error - The error message.
   * @param errorCode - The error code if set.
   * @returns A FunctionalResult instance with failure status and error message.
   */
  static Error<T>(error: string, errorCode?: any): FunctionalResult<T> {
    return new FunctionalResult<T>(false, undefined, error, errorCode);
  }

  /**
   * Indicates if the  operation was successful.
   */
  get isSuccess(): boolean {
    return this._isSuccess;
  }

  /**
   * Indicates if the  operation was unsuccessful.
   */
  get isFailure(): boolean {
    return !this._isSuccess;
  }

  /**
   * The result data (if the  operation was successful).
   */
  get result(): T | undefined {
    return this._result;
  }

  /**
   * The error message (if the  operation was unsuccessful).
   */
  get error(): string | undefined {
    return this._error;
  }

  /**
   * The error message (if the operation was unsuccessful).
   */
  get errorCode(): number | undefined {
    return this._errorCode;
  }
}
