import {
  createAction,
  fetchAction,
  updateAction,
  partialAction,
} from '../../systemUtils/common/APIHelper';
import settings from '../../config/services.json';
import { getHostConfigAsync } from '../common/HostConfigActions';
import Common_Types from '../../sysObjects/apiModels/Common.types';
import InternalUser_Types from '../../sysObjects/apiModels/InternalUser.types';
import System_Types from '../../sysObjects/apiModels/System.types';
import { FunctionalResult } from '../../sysObjects/FunctionalResult';

const InternalUserActions = {
  /**
   * Saves the user object.
   * @param obj The record to save.
   * @param {string} usr the usrId to pass to the calling method
   * @returns The result of the action
   */
  saveUserAsync: async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    obj: Common_Types.BaseUser,
    id?: string
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    if (id) {
      return updateAction({
        hostPath: host!.result!.path,
        apiPath: `${settings.InternalUserAccounts.update.path!}/${id}`,
        userId: headerInfo.result!.userId,
        accessToken: headerInfo.result!.token,
        formData: obj,
      });
    }
    return createAction({
      hostPath: host!.result!.path,
      apiPath: settings.InternalUserAccounts.create.path!,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
      formData: obj,
    });
  },

  /**
   * Sets the user state
   * @param headerInfo The headerInfo to pass to the calling method
   * @param userID The id of the user to set the status for
   * @param id The id of the status to set
   * @returns
   */
  setUserStateAsync: async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    userID: string,
    id: number
  ): Promise<FunctionalResult<void>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return partialAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.InternalUserAccounts.setState.path}/${userID}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
      formData: { status: id },
    });
  },

  /**
   * Gets the internal user by the user id
   * @param headerInfo The headerInfo to pass to the calling method
   * @param usrId The id of the user to get
   * @returns
   */
  getUserAsync: async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    usrId: string
  ): Promise<FunctionalResult<Common_Types.BaseUser>> => {
    const host = await getHostConfigAsync();
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.InternalUserAccounts.get.path}/${usrId}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  },

  /**
   * Gets a list of users by the role they are in
   * @param {InternalUser_Types.queryUser} paramObj the query object to pass to the calling method
   * @param {string} usr the usrId to pass to the calling method
   * @returns
   */
  queryUsersAsync: async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    paramObj: InternalUser_Types.queryUser
  ): Promise<FunctionalResult<InternalUser_Types.ByRoleObj[]>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    let params: string[] = [];
    let queryPath = '';
    if (paramObj.status !== undefined) {
      paramObj.status.forEach((status) => {
        params.push(
          `${settings.InternalUserAccounts.findUsers.statusQuery}=${status}`
        );
      });
    }
    if (paramObj.role !== undefined) {
      paramObj.role.forEach((role) => {
        params.push(
          `${settings.InternalUserAccounts.findUsers.roleQuery}=${role}`
        );
      });
    }

    if (params.length > 0) {
      queryPath = `?${params.join('&')}`;
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.InternalUserAccounts.findUsers.path}${queryPath}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  },

  /**
   * Gets a list of bookable staff members.
   * @param {string} usr The user claims of the performing user.
   * @param {string} accessToken The access token of the performing user.
   * @returns The list of bookable staff members.
   */
  findBookingsSystemStaffAsync: async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
  ): Promise<FunctionalResult<InternalUser_Types.bookableStaff[]>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.InternalUserAccounts.findBookingsSystemStaff.path}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  },

  createDefaultUser: (roleId: number): Common_Types.BaseUser => {
    return {
      name: '',
      surname: '',
      primaryEmail: '',
      secondaryEmail: '',
      birthDate: null,
      ethnicity: undefined,
      disabilities: [],
      gender: undefined,
      role: roleId,
      jobTitle: '',
      status: 1,
      primaryTelephoneNumber: '',
      secondaryTelephoneNumber: '',
      msBookingsBusinessId: '',
      msBookingsStaffMemberId: '',
    };
  },
};

export default InternalUserActions;
