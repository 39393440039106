import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import CaseUtils from '../../../systemUtils/case/caseUtils';
import { Case_Types } from '../../../sysObjects/apiModels/Case.types';
import { useMsal } from '@azure/msal-react';
import CaseLayoutRowTypes from '../../../systemComponents/targetedPageControls/case/caseTableRow/CaseTableRowProps.types';
import {
  getHeadersAsync,
  sortServiceLevelAgreementAsc,
  sortStatusHistoryDesc,
} from '../../../systemUtils/common/CommonHelpers';
import DateHelpers from '../../../systemUtils/common/DateHelpers';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import CasesTableRow from '../../../systemComponents/targetedPageControls/case/caseTableRow/CasesTableRow';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import ListTable from '../../../systemComponents/sharedControls/tables/listTable/ListTable';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import './index.css';
import oDataTypes from '../../../sysObjects/apiModels/oDataTypes';
import SharedLogger, {
  LogLevel,
} from '../../../systemUtils/common/logging/SharedLogger';
import CaseIndex_Types from './CaseIndex.types';
import { pagingDirection } from '../../../sysObjects/common.types';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';

const CaseIndexPage = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const context = React.useContext(CommonPageContext);

  const { userClaims } = React.useContext(UserClaimsContext);
  const { userSettings } = useUserSettingsContext();
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [gettingData, setGettingData] = React.useState<boolean>(true);
  const [pagingDetails, setPagingDetails] =
    React.useState<oDataTypes.PagingModel>({
      pageSize: userSettings.startingPageSize,
      currentPage: 1,
      totalAvailableRecords: 0,
      totalPages: 0,
      nextPageUrl: null,
      previousPageUrl: null,
      countOfRecordsInPage: 0,
    });

  const [cases, setCases] = React.useState<CaseLayoutRowTypes.Props[]>([]);

  useEffect(() => {
    context?.handleCrumbUpdate(locales.breadcrumbs);
  }, []);

  useEffect(() => {
    fetchCases({
      skip: 0,
      top: pagingDetails.pageSize,
    });
  }, [pagingDetails.pageSize]);

  const fetchCases = async (caseDetails: CaseIndex_Types.FetchCasesModel) => {
    setGettingData(true);
    CaseUtils.findCasesAsync(await getHeadersAsync(userClaims, instance), {
      oDataQuery: {
        top: caseDetails!.top,
        skip: caseDetails!.skip,
      },
    })
      .then((result) => {
        setIsLoading(false);
        if (result.isFailure) {
          context?.handleMessage({
            message: locales.errorDetails.loadingFailed,
            alertType: 'negative',
          });
          navigate('/');
          return;
        }
        setData(result.result!);
      })
      .catch((error) => {
        SharedLogger(LogLevel.Error, error);
      });
  };

  const setData = (data: oDataTypes.PagedResult<Case_Types.FindCaseResult>) => {
    setPagingDetails(data.pagingMetaData);
    setCases(
      data.resultData.map((caseDetails: Case_Types.FindCaseResult) => {
        const history = caseDetails.statusHistory.sort((x, y) =>
          sortStatusHistoryDesc(x, y),
        );

        const assignedHistory = caseDetails.statusHistory
          .filter((x) => x.value === 1)
          .sort((x, y) => sortStatusHistoryDesc(x, y));

        const sla = caseDetails.caseSlas
          .filter((x) => x.completedDate === null)
          .sort((x, y) => sortServiceLevelAgreementAsc(x, y));

        return {
          idLabel: caseDetails.caseReference,
          intl: intl,
          modLabel: '',
          contacts: caseDetails.caseContacts.map((contact) => {
            return {
              name: `${contact.name}`,
              email: contact.emailAddress,
              relationship: contact.relationshipToCustomer,
              phone: contact.telephoneNumber,
            };
          }),
          id: caseDetails.id,
          customerName: `${caseDetails.customerName} ${caseDetails.customerSurname}`,
          refOrganization: caseDetails.referrerOrganisationName,
          statusLabel: locales.caseStatusLabels[history[0].value || 0],
          categoryLabel: '',
          labels: locales.caseTable.labels.row,
          slas: caseDetails.caseSlas.map((sla) => {
            return {
              id: sla.id,
              slaDefinitionId: sla.slaDefinitionId,
              name: sla.name,
              duration: sla.duration,
              startDate: DateHelpers.parseDate(sla.startDate)!,
              dueDate:
                sla.dueDate === undefined || sla.dueDate === null
                  ? null
                  : DateHelpers.parseDate(sla.dueDate),
              completedDate:
                sla.completedDate === undefined || sla.completedDate === null
                  ? null
                  : DateHelpers.parseDate(sla.completedDate),
            };
          }),
          assignedDate:
            assignedHistory.length > 0
              ? DateHelpers.getLocalDateString(
                  assignedHistory[0].createdDateTime,
                  intl,
                  'MMMM',
                )
              : '',
          assignedTime:
            assignedHistory.length > 0
              ? DateHelpers.getLocalTimeString(
                  assignedHistory[0].createdDateTime,
                  intl,
                )
              : '',
          rowButtons: [
            {
              label: locales.ViewCase,
              controlState: 'positive',
              onClick: () => navigate(`/cases/view/${caseDetails.id}`),
            },
          ],
        };
      }),
    );
    setGettingData(false);
  };

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <ListTable
        isLoading={gettingData}
        serverPaging={{
          pagingDetails: pagingDetails,
          eventHandlers: {
            pageSizeChange: (size: number) => {
              setPagingDetails({
                ...pagingDetails,
                pageSize: size,
              });
            },
            pageChange: (direction: pagingDirection, url?: string) => {
              if (direction === 'Back') {
                fetchCases({
                  skip: pagingDetails.currentPage - 2,
                  top: pagingDetails.pageSize,
                });
              } else if (direction === 'Forward') {
                fetchCases({
                  skip: pagingDetails.currentPage,
                  top: pagingDetails.pageSize,
                });
              } else if (direction === 'Start') {
                fetchCases({
                  skip: 0,
                  top: pagingDetails.pageSize,
                });
              } else if (direction === 'End') {
                fetchCases({
                  skip: pagingDetails.totalPages - 1,
                  top: pagingDetails.pageSize,
                });
              }
            },
          },
        }}
        headers={locales.caseTable.headers.map(
          (header: string, index: number) => {
            return <div key={`Header_${index}`}>{header}</div>;
          },
        )}
        labels={locales.caseTable.labels}
        rows={
          cases.map((row, index) => {
            return (
              <CasesTableRow
                key={`CaseRow-${index}`}
                modLabel={''}
                intl={intl}
                buttons={row.buttons}
                rowButtons={row.rowButtons}
                id={row.id}
                idLabel={row.idLabel}
                customerName={row.customerName}
                refOrganization={row.refOrganization}
                statusLabel={row.statusLabel}
                slas={row.slas}
                assignedDate={row.assignedDate}
                assignedTime={row.assignedTime}
                labels={row.labels}
                contacts={row.contacts}
              />
            );
          }) || []
        }
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        tableClassName="case-layout-container"
      />
      {UserCore.userIsCaseManagerOrHigher(userClaims!.user!) ? (
        <div className="Button-Container">
          <InformationButton
            buttonDetails={{
              itemKey: 'infoButtonCurrency',
              label: locales.createButton,
              mode: 'positive',
              clickEvent: () => navigate(`/cases/referral/create`),
            }}
          >
            {locales.createSummary}
          </InformationButton>
        </div>
      ) : null}
    </div>
  );
};
export default CaseIndexPage;
