import React from 'react';
import { useParams } from 'react-router-dom';
import { CaseDetail_Types } from '../../../../../sysObjects/apiModels/Case.types';
import CaseUtils, {
  doesCaseContainInvoicedBillableItems,
} from '../../../../../systemUtils/case/caseUtils';
import ServicesToFulfillActions from '../../../../../systemUtils/services/ServicesToFulfillActions';
import ServiceRow from '../../service/serviceDetails/ServiceRow';
import AddServiceRow from '../../service/addrow/AddServiceRow';
import Enumerations, {
  LocalEnumerations,
  getCurrentStatus,
  getHeadersAsync,
  getServerErrors,
} from '../../../../../systemUtils/common/CommonHelpers';
import ServiceDefinition_Types from '../../../../../sysObjects/apiModels/ServiceDefinition.types';
import Organisation_Types from '../../../../../sysObjects/apiModels/Organisation.types';
import CaseServicesTabTypes from './CaseServicesTabProps.types';
import InternalUserActions from '../../../../../systemUtils/userUtils/InternalUserActions';
import {
  KeyValuePair,
  OrderedKeyValuePair,
} from '../../../../../sysObjects/common.types';
import ButtonBoxTypes from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox.types';
import PageLoader from '../../../../sharedControls/general/loading/pageLoader/PageLoader';
import ListTable from '../../../../sharedControls/tables/listTable/ListTable';
import InformationButton from '../../../../sharedControls/general/InformationButton/InformationButton';
import { IUserClaims } from '../../../../../sysObjects/UserClaims.types';

import './CaseServicesTab.css';
import ServiceDefinitionActions from '../../../../../systemUtils/services/ServiceDefinitionActions';
import StatusHistory from '../../../../../sysObjects/apiModels/StatusHistory.types';
import { AddServiceDisplayMode } from '../../service/addrow/AddServiceRow.types';
import { ServiceStatusHistoryProps } from '../../service/serviceDetails/ServiceRow.types';
import FormDropDown from '../../../../sharedControls/formControls/formDropDown/FormDropDown';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';

const CaseServiceTab: React.FC<CaseServicesTabTypes.Props> = (props) => {
  const { id } = useParams();

  const [loading, setLoading] = React.useState<boolean>(true);
  const [supportedCurrencies, setSupportedCurrencies] = React.useState<
    Organisation_Types.supportedCurrency[]
  >([]);
  const [deliveryFormats, setDeliveryFormats] = React.useState<
    KeyValuePair<number>[]
  >([]);
  const [relatedConditions, setRelatedConditions] = React.useState<
    KeyValuePair<number>[]
  >([]);
  const [serviceFulfillmentStatuses, setServiceFulfillmentStatuses] =
    React.useState<OrderedKeyValuePair<number>[]>([]);

  const [serviceDefinitionKvp, setServiceDefinitionKvp] = React.useState<
    KeyValuePair<string>[]
  >([]);

  const [serviceDefinitionItems, setServiceDefinitionItems] =
    React.useState<ServiceDefinition_Types.serviceDefinitionItemWithPrice[]>();
  const setErrorMessages = (message: string) => {
    props.events?.onError(message);
  };

  const [assessors, setAssessors] = React.useState<KeyValuePair<string>[]>([]);
  const [caseManagers, setCaseManagers] = React.useState<
    KeyValuePair<string>[]
  >([]);
  const [selectedCaseManager, setSelectedCaseManager] = React.useState<string>(
    props.caseDetails?.assignedCaseManager?.id ?? '',
  );

    React.useEffect(() => {
      const loadDataAsync = async () => {
        setLoading(true);
        const headers = await getHeadersAsync(props.userClaims, props.instance);
        if (props.caseDetails === null) {
          setErrorMessages(props.labels.notFound.message);
          return;
        }

        await InternalUserActions.queryUsersAsync(
          headers,
          {
            role: [LocalEnumerations.Roles.Assessor],
            status: [LocalEnumerations.UserStatuses.Active],
          },
        ).then((result) => {
          if (result.isFailure || !result.result) {
            setErrorMessages(props.labels.errorDetails.loadingFailed);
            return;
          }
          setAssessors(
            result.result!.map((x) => {
              return {
                key: x.id,
                value: `${x.name} ${x.surname}`,
              };
            }),
          );
        });
        await InternalUserActions.queryUsersAsync(
          await getHeadersAsync(props.userClaims, props.instance),
          {
            role: [
              LocalEnumerations.Roles.CaseManager,
              LocalEnumerations.Roles.SuperUser,
            ],
            status: [
              LocalEnumerations.UserStatuses.Active,
              LocalEnumerations.UserStatuses.Invited,
            ],
          },
        ).then((result) => {
          if (result.isFailure || !result.result) {
            setErrorMessages(props.labels.errorDetails.loadingFailed);
            return;
          }
          setCaseManagers(
            result.result!.map((x) => {
              return {
                key: x.id,
                value: `${x.name} ${x.surname}`,
              };
            }),
          );
        });
        if (
          !canServicesBeAdded(
            props.userClaims!.user!,
            props.caseDetails.statusHistory,
          )
        ) {
          return;
        }

        await ServiceDefinitionActions.findWithPricesAsync(
          props.caseDetails!.caseIdentifiers.defaultBillingOrganisationId!,
          headers,
        ).then(async (result) => {
          if (result.isFailure || !result.result) {
            setErrorMessages(props.labels.errorDetails.loadingFailed);
            return;
          }
          await ServiceDefinitionActions.findWithPricesAsync(
            props.caseDetails!.caseIdentifiers.defaultBillingOrganisationId!,
            headers,
          ).then(async (result) => {
            if (result.isFailure || !result.result) {
              setErrorMessages(props.labels.errorDetails.loadingFailed);
              return;
            }

            setServiceDefinitionItems(result.result!);

            ServiceDefinitionActions.fetchServiceDefinitions(
              headers,
              props.intl.locale,
            ).then((rst) => {
              if (rst.isFailure) {
                setErrorMessages(props.labels.errorDetails.loadingFailed);
                return;
              }

              let filteredList = rst.result!.filter((x) => {
                return result.result?.find((y) => y.id === x.key) !== undefined;
              });

              setServiceDefinitionKvp(filteredList);
            });
          });
        })};

        setSupportedCurrencies(
          Enumerations.getSupportedCurrencies(props.intl.locale),
        );
        setRelatedConditions(Enumerations.getRelatedConditions(props.intl.locale));
        setDeliveryFormats(Enumerations.getDeliveryFormats(props.intl.locale));
        setServiceFulfillmentStatuses(
          Enumerations.getServiceFulfillmentStatuses(props.intl.locale),
        );

        loadDataAsync().then(() => {
          setLoading(false);
        });
      }, [props.caseDetails]);

    const determineButtonList = (
      service: CaseDetail_Types.RetrievedServiceToFulfill,
    ): ButtonBoxTypes.Button[] => {
      if (!service.statusHistory) {
        console.error('no status history found for service');
        return [];
      }

      const currentStatus = getCurrentStatus(service.statusHistory);
      const userRole = props.userClaims?.user?.role;
      const invoicedItems = doesCaseContainInvoicedBillableItems(
        props.caseDetails!,
      );
      const caseStatus = getCurrentStatus(
        props.caseDetails?.statusHistory!,
      ).value;

      if (!currentStatus) {
        console.error('cannot determine current status');
        return [];
      }

      if (userRole === undefined || isNaN(userRole!)) {
        console.error('user role must be set');
        return [];
      }

      const buttonList: ButtonBoxTypes.Button[] = [];

      const addButtonIfCondition = (
        condition: boolean,
        button: ButtonBoxTypes.Button,
      ) => {
        if (condition) {
          buttonList.push(button);
        }
      };

      const commonConditions = () => {
        addButtonIfCondition(
          canServiceBeCancelled(caseStatus, invoicedItems),
          CancelServiceButton,
        );
      };

      switch (currentStatus.value) {
        case LocalEnumerations.ServiceStatuses.AwaitingCostApproval:
          if (CaseManagerRoles.includes(userRole)) {
            addButtonIfCondition(
              [
                LocalEnumerations.CaseStatuses.ActiveServiceDeliveries,
                LocalEnumerations.CaseStatuses.AwaitingPrerequisites,
              ].includes(caseStatus),
              ApproveServiceCostButton,
            );
            addButtonIfCondition(
              ServiceEditableCaseStates.includes(caseStatus),
              getDeleteButton(service.id),
            );
          }
          commonConditions();
          break;

        case LocalEnumerations.ServiceStatuses.ReportRequired:
          commonConditions();

          if (AssessorRoles.includes(userRole)) {
            buttonList.push(FinalReportCompleteButton);
            buttonList.push(RequiresProofing);
          }
          break;

        case LocalEnumerations.ServiceStatuses.ReportReadyForReview:
          commonConditions();
          if (AssessorRoles.includes(userRole)) {
            buttonList.push(ReportReviewCompleteButton);
          }
          break;

        case LocalEnumerations.ServiceStatuses.FinalReportPending:
          commonConditions();
          if (AssessorRoles.includes(userRole)) {
            buttonList.push(FinalReportCompleteButton);
          }
          break;

        case LocalEnumerations.ServiceStatuses.FinalReportComplete:
          commonConditions();
          if (CaseManagerRoles.includes(userRole)) {
            buttonList.push(ReportSharedWithCustomerButton);
          }
          break;

        case LocalEnumerations.ServiceStatuses.CustomerReviewPending:
        case LocalEnumerations.ServiceStatuses.RequiresFulfillment:
          commonConditions();
          addButtonIfCondition(
            canServiceBeCompleted(invoicedItems, currentStatus.value),
            ServiceCompleteButton,
          );
          break;

        case LocalEnumerations.ServiceStatuses.AppointmentOffered:
        case LocalEnumerations.ServiceStatuses.RequiresAppointment:
          commonConditions();
          break;

        default:
          return [];
      }

      return buttonList;
    };

    const canServiceBeCancelled = (
      caseStatus: number,
      invoicedItems: boolean,
    ) => {
      return (
        !invoicedItems &&
        [
          LocalEnumerations.CaseStatuses.AwaitingPrerequisites,
          LocalEnumerations.CaseStatuses.ActiveServiceDeliveries,
        ].includes(caseStatus) &&
        CaseManagerRoles.includes(props.userClaims?.user?.role ?? -1)
      );
    };

    const canServiceBeCompleted = (
      invoicedItems: boolean,
      serviceStatus: number,
    ) => {
      /* If the Service is in the Requires Fulfillment state,
       * additionally there must be at least one invoiced billable item linked to the Service.
       */
      if (
        serviceStatus !== LocalEnumerations.ServiceStatuses.RequiresFulfillment
      ) {
        invoicedItems = true;
      }

      return (
        invoicedItems &&
        CaseManagerRoles.includes(props.userClaims?.user?.role ?? -1)
      );
    };

    const getDeleteButton = (id: string): ButtonBoxTypes.Button => {
      return {
        label: props.labels.actionButtons.remove.label,
        id: `button-box-delete-service`,
        controlState: 'negative',
        onClick: async () => {
          ServicesToFulfillActions.deleteAsync(
            await getHeadersAsync(props.userClaims, props.instance),
            id.replace(/['\"]+/g, ''),
            props.caseDetails!.id,
          ).then((result) => {
            if (result.isFailure) {
              setErrorMessages(props.labels.errorDetails.loadingFailed);
              return;
            }
          });
          props.events.setRefreshRequired();
        },
      };
    };

    const ApproveServiceCostButton: ButtonBoxTypes.Button = {
      label: props.labels.actionButtons.approveServiceCost.label,
      id: `button-box-approve-service-cost`,
      controlState: 'positive',
      onClick: () => {
        alert('Approve Service Cost - Not Implemented Yet');
        props.events.setRefreshRequired();
      },
    };

    const ReportReviewCompleteButton: ButtonBoxTypes.Button = {
      label: props.labels.actionButtons.reportReviewComplete.label,
      id: `button-box-report-review-complete`,
      isChecked: false,
      controlState: 'positive',
      onClick: () => { },
    };

    const RequiresProofing: ButtonBoxTypes.Button = {
      label: props.labels.actionButtons.requiredProofing.label,
      id: `button-box-report-review-proofing`,
      isChecked: false,
      controlState: 'positive',
      onClick: () => { },
    };

    const FinalReportCompleteButton: ButtonBoxTypes.Button = {
      label: props.labels.actionButtons.finalReportComplete.label,
      id: `button-box-final-report-complete`,
      controlState: 'positive',
      onClick: () => {
        alert('Final Report Complete - Not Implemented Yet');
        props.events.setRefreshRequired();
      },
    };

    const ReportSharedWithCustomerButton: ButtonBoxTypes.Button = {
      label: props.labels.actionButtons.reportSharedWithCustomer.label,
      id: `button-box-report-shared-with`,
      controlState: 'positive',
      onClick: () => {
        alert('Report Shared with Customer - Not Implemented Yet');
        props.events.setRefreshRequired();
      },
    };

    const ServiceCompleteButton: ButtonBoxTypes.Button = {
      label: props.labels.actionButtons.serviceComplete.label,
      id: `button-box-service-complete`,
      controlState: 'positive',
      onClick: () => {
        alert('Service Complete - Not Implemented Yet');
        props.events.setRefreshRequired();
      },
    };

    const CancelServiceButton: ButtonBoxTypes.Button = {
      label: props.labels.actionButtons.cancel.label,
      id: `button-box-cancel`,
      controlState: 'negative',
      onClick: () => {
        alert('Service Cancelled - Not Implemented Yet');
        props.events.setRefreshRequired();
      },
    };

    const getFormattedPrice = (
      service: CaseDetail_Types.RetrievedServiceToFulfill,
    ) => {
      if (service.organisationPrice == null) {
        return undefined;
      }
      let currencySymbol = supportedCurrencies?.find(
        (x) => x.code === service?.organisationPrice?.currency,
      )?.symbol;
      return `${currencySymbol}${service?.organisationPrice?.unitPrice}`;
    };

    const mapStatusHistoryItems = (
      statusHistory: StatusHistory<number>[],
    ): ServiceStatusHistoryProps[] => {
      return statusHistory
        .map((status) => {
          return {
            statusName:
              serviceFulfillmentStatuses.find((x) => x.key === status.value)
                ?.value ?? status.value.toString(),
            statusStatus: status.value.toString(),
            statusDate: new Date(status.createdDateTime),
          } as ServiceStatusHistoryProps;
        })
        .sort((a, b) => b.statusDate.getTime() - a.statusDate.getTime());
    };

    const markServiceSelectionAsComplete = async () => {
      let caseId = props.caseDetails?.id;

      if (caseId === undefined || caseId === '') {
        setErrorMessages(props.labels.errorDetails.loadingFailed);
        return;
      }

      CaseUtils.completeServiceSelectionAsync(
        await getHeadersAsync(props.userClaims, props.instance),
        caseId,
      ).then((result) => {
        if (result.isFailure) {
          setErrorMessages(getServerErrors(props.labels, result.errorCode));
          return;
        }
        props.events.setRefreshRequired();
      });
    };

    const assignToMe = () => {
      if (props.userClaims?.user?.userAccountId === undefined) {
        return;
      }

      if (
        caseManagers.find(
          (x) => x.key === props.userClaims!.user!.userAccountId,
        ) === undefined
      ) {
        setErrorMessages(props.labels.errorDetails.cannotAssignToSelf);
        return;
      }

      assignCaseManager(props.userClaims.user.userAccountId);
    };

    const assignCaseManager = async (id: string) => {
      if (props.caseDetails?.id === undefined) {
        setErrorMessages(props.labels.errorDetails.loadingFailed);
        return;
      }

      CaseUtils.assignCaseManagerAsync(
        id,
        props.caseDetails.id,
        await getHeadersAsync(props.userClaims, props.instance),
      ).then((result) => {
        if (result.isFailure) {
          setErrorMessages(getServerErrors(props.labels, result.errorCode));
          return;
        }

        props.events.setRefreshRequired();
      });
    };

    const canBeAssignedToSelectedUser = () => {
      return (
        selectedCaseManager !== '' &&
        selectedCaseManager !== props.caseDetails?.assignedCaseManager?.id
      );
    };

    const canBeAssignedToMyself = () => {
      return (
        props.caseDetails?.assignedCaseManager?.id !==
        props.userClaims?.user?.userAccountId
      );
    };

    const isCaseStartable = () => {
      const currentStatus = getCurrentStatus(
        props.caseDetails?.statusHistory ?? [],
      );
      const isCorrectStatus = [
        LocalEnumerations.CaseStatuses.CaseManagerAssigned,
      ].includes(currentStatus.value);
      const serviceCount = props.caseDetails?.servicesToFulfill.length ?? 0;

      return isCorrectStatus && serviceCount > 0;
    };

    const canCaseManagerBeChanged = () => {
      const currentStatus = getCurrentStatus(
        props.caseDetails?.statusHistory ?? [],
      );
      
      const isCaseInCorrectState = ![
        LocalEnumerations.CaseStatuses.CaseCancelled,
        LocalEnumerations.CaseStatuses.CaseComplete,
      ].includes(currentStatus.value);

      return (
        isCaseInCorrectState &&
        CaseManagerRoles.includes(props.userClaims?.user?.role ?? -1)
      );
    };

    const renderCaseManagerSelector = () => {
      return (
        <div className="case-manager-selector-container">
          <div className="Table-Name Font-Heading">
            {props.labels.headers.people}
          </div>
          <br />
          <FormDropDown
            items={caseManagers.map((x) => {
              return x.key === props.caseDetails?.assignedCaseManager?.id
                ? { ...x, value: `${x.value} (Current)` }
                : x;
            })}
            fieldId={'assignedCaseManager'}
            id={'assigned_case_manager'}
            readonly={canCaseManagerBeChanged() === false}
            label={props.labels.rowLabels.caseManager}
            value={selectedCaseManager}
            onChange={(value) => setSelectedCaseManager(value.value as string)}
            defaultText={props.labels.rowLabels.caseManagerDefault}
            requiredDetails={{ formLabel: '', message: '' }}
            displayMode={'Bordered'}
          />
          {[
            LocalEnumerations.Roles.SuperUser,
            LocalEnumerations.Roles.CaseManager,
          ].includes(props.userClaims?.user?.role ?? -1) &&
            canCaseManagerBeChanged() && (
              <ButtonBox
                id={'case_manager_button_box'}
                displayBorder={true}
                buttons={[
                  {
                    controlState: canBeAssignedToSelectedUser()
                      ? 'positive'
                      : 'locked',
                    id: 'assign_case_manager',
                    label: props.labels.actionButtons.assignCaseManager.label,
                    onClick: () => assignCaseManager(selectedCaseManager),
                  },
                  {
                    controlState: canBeAssignedToMyself() ? 'positive' : 'locked',
                    id: 'assign_to_me',
                    label: props.labels.actionButtons.assignToMe.label,
                    onClick: () => assignToMe(),
                  },
                ]}
              />
            )}
        </div>
      );
    };

    return loading ? (
      <PageLoader alt={props.labels.common.load} />
    ) : (
      props.caseDetails && (
        <>
          {renderCaseManagerSelector()}
          <ListTable
            isLoading={false}
            headers={[
              <div key="StatusCol"></div>,
              ...props.labels.table.tableHeaders.map((header, hI) => {
                return <div key={`Service_Header_${hI}`}>{header}</div>;
              }),
            ]}
            labels={{
              of: props.labels.table.of,
              size: props.labels.table.size,
              tableName: props.labels.table.tableName,
              emptyMessage: props.labels.table.emptyMessage,
            }}
            pagingDetails={props.pagingDetails}
            defaultItem={
              canServicesBeAdded(
                props.userClaims!.user!,
                props.caseDetails.statusHistory,
              ) && (
                <AddServiceRow
                  serviceDefinitions={serviceDefinitionKvp}
                  serviceDisplayMode={AddServiceDisplayMode.Row}
                  serviceDefinitionItems={serviceDefinitionItems}
                  supportedCurrencies={supportedCurrencies}
                  caseId={id!}
                  events={{
                    onError: setErrorMessages,
                    onServiceAdded: () => props.events.setRefreshRequired(),
                  }}
                  relatedConditions={relatedConditions}
                  deliveryFormats={deliveryFormats}
                />
              )
            }
            rows={
              props.caseDetails.servicesToFulfill.length > 0
                ? props.caseDetails?.servicesToFulfill.map(
                  (
                    service: CaseDetail_Types.RetrievedServiceToFulfill,
                    index,
                  ) => (
                    <ServiceRow
                      assessors={assessors}
                      key={service.id}
                      id={service.id}
                      caseId={props.caseDetails!.id}
                      labels={props.labels.rowLabels}
                      lastModified={new Date()}
                      deliveryFormat={
                        deliveryFormats.find(
                          (x) =>
                            x.key === service.serviceDefinition!.deliveryFormat,
                        )?.value ??
                        service.serviceDefinition!.deliveryFormat!.toString()
                      }
                      status={
                        serviceFulfillmentStatuses.find(
                          (x) =>
                            x.key ===
                            getCurrentStatus(service.statusHistory)?.value,
                        )?.value ??
                        getCurrentStatus(service.statusHistory).value.toString()
                      }
                      relatedCondition={
                        relatedConditions.find(
                          (x) =>
                            x.key ===
                            service.serviceDefinition!.relatedCondition,
                        )?.value ??
                        service.serviceDefinition!.relatedCondition!.toString()
                      }
                      otherRelatedCondition={service.serviceDefinition!.otherRelatedCondition?.toString()}
                      serviceType={service.serviceDefinition!.serviceType}
                      serviceCode={service.serviceDefinition!.serviceCode}
                      nominalCode={service.serviceDefinition!.nominalCode}
                      description={service.serviceDefinition!.description}
                      selected={true}
                      buttons={determineButtonList(service)}
                      statusHistory={mapStatusHistoryItems(
                        service.statusHistory ?? [],
                      )}
                      price={
                        CaseManagerRoles.includes(
                          props.userClaims?.user?.role ?? -1,
                        )
                          ? getFormattedPrice(service)
                          : undefined
                      }
                      eventHandlers={{
                        onError: props.events.onError,
                        requiresProofing: props.events.requiresProofing,
                        reportReviewComplete: props.events.reportReviewComplete,
                      }}
                      onUpdatePoNumber={(id: string, poNumber: string) => {
                        //todo: replace with call to API when LX-1236 is complete
                        alert(
                          `Updating PO Number from ${service.PurchaseOrderNumber} to ${poNumber} for service ${id}`,
                        );
                      }}
                    />
                  ),
                )
                : []
            }
            tableClassName="Service-Table"
          />

          {props.caseDetails?.statusHistory &&
            CaseManagerRoles.includes(props.userClaims?.user?.role ?? -1) &&
            [LocalEnumerations.CaseStatuses.CaseManagerAssigned].includes(
              getCurrentStatus(props.caseDetails.statusHistory).value,
            ) && (
              <InformationButton
                key="informationSaves"
                buttonDetails={{
                  itemKey: 'informationSaves',
                  clickEvent: () => markServiceSelectionAsComplete(),
                  label: props.labels.completeServicesButton.label,
                  mode: 'positive',
                  disabled:
                    servicesCannotBeCompleted(
                      props.caseDetails.servicesToFulfill,
                    ) || !isCaseStartable(),
                }}
              >
                <>{props.labels.completeServicesButton.summary}</>
              </InformationButton>
            )}
        </>
      )
    );
  };

  const servicesCannotBeCompleted = (
    services: CaseDetail_Types.RetrievedServiceToFulfill[],
  ): boolean => {
    return (
      services.length === 0 ||
      services.some((service) => {
        return service.organisationPrice === null;
      })
    );
  };

  const canServicesBeAdded = (
    userClaims: IUserClaims,
    statusHistory: StatusHistory<number>[],
  ): boolean => {
    return (
      statusHistory &&
      CaseManagerRoles.includes(userClaims.role ?? -1) &&
      ServiceEditableCaseStates.includes(getCurrentStatus(statusHistory)?.value)
    );
  };

  export const ServiceEditableCaseStates = [
    LocalEnumerations.CaseStatuses.NewReferral,
    LocalEnumerations.CaseStatuses.CaseManagerAssigned,
  ] as number[];
  export const CaseManagerRoles = [
    LocalEnumerations.Roles.CaseManager,
    LocalEnumerations.Roles.SuperUser,
  ] as number[];
  export const AssessorRoles = [
    LocalEnumerations.Roles.Assessor,
    LocalEnumerations.Roles.AssociateAssessor,
  ] as number[];

  export default CaseServiceTab;
