import React from 'react';
import SVGTypes from './svg.types';

export const LogOut: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19_78475)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.625 11.75C29.625 11.3853 29.4801 11.0356 29.2223 10.7777C28.9644 10.5199 28.6147 10.375 28.25 10.375H11.75C11.3853 10.375 11.0356 10.5199 10.7777 10.7777C10.5199 11.0356 10.375 11.3853 10.375 11.75V28.25C10.375 28.6147 10.5199 28.9644 10.7777 29.2223C11.0356 29.4801 11.3853 29.625 11.75 29.625H28.25C28.6147 29.625 28.9644 29.4801 29.2223 29.2223C29.4801 28.9644 29.625 28.6147 29.625 28.25V11.75ZM9 11.75C9 11.0207 9.28973 10.3212 9.80546 9.80546C10.3212 9.28973 11.0207 9 11.75 9H28.25C28.9793 9 29.6788 9.28973 30.1945 9.80546C30.7103 10.3212 31 11.0207 31 11.75V28.25C31 28.9793 30.7103 29.6788 30.1945 30.1945C29.6788 30.7103 28.9793 31 28.25 31H11.75C11.0207 31 10.3212 30.7103 9.80546 30.1945C9.28973 29.6788 9 28.9793 9 28.25V11.75ZM22.882 23.8541C22.9454 23.9198 23.0213 23.9722 23.1052 24.0082C23.189 24.0442 23.2793 24.0632 23.3705 24.064C23.4618 24.0648 23.5524 24.0474 23.6368 24.0128C23.7213 23.9782 23.7981 23.9272 23.8626 23.8626C23.9272 23.7981 23.9782 23.7213 24.0128 23.6368C24.0474 23.5524 24.0648 23.4618 24.064 23.3705C24.0632 23.2793 24.0442 23.189 24.0082 23.1052C23.9722 23.0213 23.9198 22.9454 23.8541 22.882L18.2221 17.25H22.0281C22.2105 17.25 22.3853 17.1776 22.5143 17.0486C22.6432 16.9197 22.7156 16.7448 22.7156 16.5625C22.7156 16.3802 22.6432 16.2053 22.5143 16.0764C22.3853 15.9474 22.2105 15.875 22.0281 15.875H16.5625C16.3802 15.875 16.2053 15.9474 16.0764 16.0764C15.9474 16.2053 15.875 16.3802 15.875 16.5625V22.0281C15.875 22.2105 15.9474 22.3853 16.0764 22.5143C16.2053 22.6432 16.3802 22.7156 16.5625 22.7156C16.7448 22.7156 16.9197 22.6432 17.0486 22.5143C17.1776 22.3853 17.25 22.2105 17.25 22.0281V18.2221L22.882 23.8541Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_19_78475">
          <path
            d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
            fill={props.fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default LogOut;
