import React from 'react';
import BookingRepoActions from '../../../../../systemUtils/booking/BookingRepoActions';
import AppointmentRepoActions from '../../../../../systemUtils/appointment/AppointmentsActions';
import './AppointmentsTab.css';
import PageLoader from '../../../../sharedControls/general/loading/pageLoader/PageLoader';
import ListTable from '../../../../sharedControls/tables/listTable/ListTable';
import { FunctionalResult } from '../../../../../sysObjects/FunctionalResult';
import AppointmentProps from './AppointmentsTab.types';
import { KeyValuePair } from '../../../../../sysObjects/common.types';
import BookingRowTypes from '../../appointments/bookingRow/BookingRow.types';
import Enumerations, { getHeadersAsync } from '../../../../../systemUtils/common/CommonHelpers';
import BookingTypes from '../../../../../sysObjects/apiModels/Booking.Types';
import AdvancedDatePickerTypes from '../../appointments/bookingRow/advancedDatePicker/AdvancedDatePicker.types';
import BookingRow from '../../appointments/bookingRow/BookingRow';
import { useUserSettingsContext } from '../../../../sharedControls/contexts/UserSettingsContextType';
import DateHelpers from '../../../../../systemUtils/common/DateHelpers';
import AppointmentTypes from '../../../../../sysObjects/apiModels/Appointment.types';
import { useIntl } from 'react-intl';
/**
 * The Appointments Tab
 * @param props The properties for the Appointments Tab
 */
const AppointmentsTab: React.FC<AppointmentProps> = (props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [rowStates, setRowStates] = React.useState<KeyValuePair<number>[]>([]);
  const [rows, setRows] = React.useState<BookingRowTypes.ServiceDetails[]>([]);
  const { userSettings } = useUserSettingsContext();
  const intl = useIntl();
  React.useEffect(() => {
    setIsLoading(false);
    setRowStates(Enumerations.getAppointmentStatuses(intl.locale));
    setRows(props.services);
  }, [props.services]);

  const getTimeSlots = (
    data: BookingTypes.UserAvailabilityDetails[],
  ): AdvancedDatePickerTypes.TimeSlot[] => {
    const result: AdvancedDatePickerTypes.TimeSlot[] = [];
    data.forEach((entry) => {
      const userId = entry.user.id;
      const displayName = `${
        entry.user.surname ? entry.user.surname + ', ' : ''
      }${entry.user.name ? entry.user.name : ''}`.trim();

      const dateMap: { [key: string]: AdvancedDatePickerTypes.SimpleTime[] } =
        {};

      entry.staffAvailabilityPeriods.forEach((period) => {
        const startDateTime = new Date(period.startDateTime);
        const day = startDateTime.getDate();
        const month = startDateTime.getMonth();
        const year = startDateTime.getFullYear();
        const hour = startDateTime.getHours();
        const minute = startDateTime.getMinutes();

        const dateKey = `${year}-${month + 1}-${day}`;

        if (!dateMap[dateKey]) {
          dateMap[dateKey] = [];
        }

        dateMap[dateKey].push({ hour, minute });
      });

      for (const dateKey in dateMap) {
        const [year, month, day] = dateKey.split('-').map(Number);
        result.push({
          userId,
          displayName,
          day,
          month: month - 1, // Adjust month for zero-based index
          year,
          times: dateMap[dateKey],
        });
      }
    });

    return result;
  };

  return isLoading ? (
    <PageLoader alt={props.labels.common.load} />
  ) : (
    <ListTable
    isLoading={false}
      tableClassName="Appointment-Table"
      headers={['Header-Check', ...props.labels.labels.table.headers].map(
        (header, i) => {
          if (i === 0) {
            return <div className="Header-Check" key={`header_${i}`}></div>;
          }
          return (
            <div className="Heading" key={`header_${i}`}>
              {header}
            </div>
          );
        },
      )}
      labels={{
        of: props.labels.labels.table.of,
        size: props.labels.labels.table.size,
        tableName: props.labels.labels.table.tableName,
        emptyMessage: props.labels.labels.table.emptyMessage,
      }}
      pagingDetails={{
        currentPageSize: userSettings.startingPageSize,
        pageSizes: userSettings.pageSizes,
      }}
      rows={rows.map((item, i) => {
        return (
          <BookingRow
            key={`row_${i}`}
            intl={intl}
            service_Details={item}
            stateItems={rowStates}
            labels={props.labels.labels.bookingControl}
            maxAdvancedMonths={userSettings.monthsInAdvance}
            startDate={new Date()}
            startDayOfWeek={0}
            canMakeBooking={props.isCaseMangerOrHigher}
            eventHandlers={{
              onExpandedChanged: (rowId: string, isExpanded: boolean) => {
                const newRows = rows.map((row) => {
                  if (row.rowId === rowId) {
                    return { ...row, isExpanded: isExpanded };
                  } else {
                    if (isExpanded) {
                      return { ...row, isExpanded: false };
                    }
                  }
                  return row;
                });

                setRows(newRows);
              },
              onError: props.events.onError,
              bookingSelected: async (details) => {
                return BookingRepoActions.bookAppointmentAsync(
                  await getHeadersAsync(props.user, props.instance),
                  {
                    assessorAccountId: details.userId,
                    caseId: props.caseId,
                    duration: details.duration,
                    serviceToFulfillId: details.serviceToFulfillId,
                    startDateTime: DateHelpers.updateDateToISO(
                      new Date(
                        details.date.year,
                        details.date.month,
                        details.date.day,
                        details.Time.hour,
                        details.Time.minute,
                      ),
                    ),
                  },
                ).then((result) => {
                  if (result.isFailure) {
                    if (props.events && props.events.onError) {
                      props.events.onError(props.labels.errors.bookingError);
                    }
                    return FunctionalResult.Error(result.error!);
                  }
                  if (props.events && props.events.onBookingComplete) {
                    props.events.onBookingComplete();
                  }
                  return FunctionalResult.Success();
                });
              },
              monthChange: async (
                result,
              ): Promise<
                FunctionalResult<AdvancedDatePickerTypes.TimeSlot[]>
              > => {
                const firstDayNextMonth = new Date(
                  result.date.year,
                  result.date.month + 1,
                  1,
                );
                const lastDayOfMonth = new Date(
                  firstDayNextMonth.getTime() - 24 * 60 * 60 * 1000,
                );

                return BookingRepoActions.findStaffAvailabilityForServiceAsync(
                  await getHeadersAsync(props.user, props.instance),
                  {
                    availabilityStartDateTime: DateHelpers.updateDateToISO(
                      new Date(
                        result.date.year,
                        result.date.month,
                        result.date.day,
                        0,
                        0,
                        0,
                      ),
                    ),
                    availabilityEndDateTime: DateHelpers.updateDateToISO(
                      new Date(
                        result.date.year,
                        result.date.month,
                        lastDayOfMonth.getDate(),
                        23,
                        59,
                        59,
                      ),
                    ),

                    serviceId: result.serviceId,
                    overrideTicks: result.duration,
                  },
                ).then((rst) => {
                  if (rst.isFailure) {
                    if (props.events && props.events.onError) {
                      props.events.onError(props.labels.errors.loadingError);
                    }
                    return FunctionalResult.Error(rst.error!);
                  }
                  return FunctionalResult.Success(getTimeSlots(rst.result!));
                });
              },
              stateChanged: async (state: AppointmentTypes.SetOutcomeBase) => {
                var outcome: AppointmentTypes.SetOutcome = {
                  appointmentId: state.appointmentId,
                  caseId: props.caseId,
                  serviceToFulfillId: state.serviceToFulfillId,
                  status: state.status,
                  isChargeable: state.isChargeable,
                  addReplacementServiceToFulfill: state.addReplacementServiceToFulfill
                };

                return AppointmentRepoActions.setOutcomeAsync(
                  await getHeadersAsync(props.user, props.instance),
                  outcome,
                );
              },
              updateChargeable: async (state: AppointmentTypes.SetOutcomeBase) => {
                const outcome: AppointmentTypes.SetOutcome = {
                  appointmentId: state.appointmentId,
                  caseId: props.caseId,
                  serviceToFulfillId: state.serviceToFulfillId,
                  status: state.status,
                  isChargeable: state.isChargeable
                };
                
                return AppointmentRepoActions.updateChargeableAsync(
                  await getHeadersAsync(props.user, props.instance),
                  outcome
                );
              },
              setRefreshRequired: props.events.setRefreshRequired
            }}
          />
        );
      })}
      key={props.caseId}
    />
  );
};

export default AppointmentsTab;
