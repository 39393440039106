import React from 'react';
import SVGTypes from './svg.types';

export const Extend: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19_78467)">
        <path
          d="M22.3237 18.6074H9.18737C8.80918 18.6074 8.44465 18.7518 8.17442 19.0119C7.90386 19.2723 7.75 19.6275 7.75 20C7.75 20.3725 7.90386 20.7277 8.17442 20.9881C8.44465 21.2482 8.80918 21.3927 9.18737 21.3927H22.3237L17.6723 25.8667L17.6723 25.8668C17.4015 26.1274 17.2474 26.483 17.2474 26.8559C17.2474 27.2288 17.4015 27.5844 17.6723 27.845C17.9428 28.1054 18.3077 28.25 18.6863 28.25C19.0649 28.25 19.4298 28.1054 19.7003 27.845L26.8243 20.9894L22.3237 18.6074ZM22.3237 18.6074L17.6723 14.1333L17.6723 14.1332C17.4015 13.8726 17.2474 13.517 17.2474 13.1441C17.2474 12.7712 17.4015 12.4156 17.6723 12.155C17.9428 11.8946 18.3077 11.75 18.6863 11.75C19.0649 11.75 19.4298 11.8946 19.7003 12.155L26.8243 19.0106L22.3237 18.6074ZM27.1389 19.4631C27.0657 19.2933 26.9587 19.1397 26.8245 19.0109V20.9891C26.9587 20.8603 27.0657 20.7067 27.1389 20.5369C27.2122 20.367 27.25 20.1845 27.25 20C27.25 19.8155 27.2122 19.633 27.1389 19.4631Z"
          fill={props.fill}
          stroke={props.stroke}
          strokeWidth="0.5"
        />
        <path
          d="M27.75 13.3333L27.75 26.6667C27.75 27.0956 27.9041 27.497 28.212 27.7904C28.52 28.0839 28.9609 28.25 29.5 28.25C30.0391 28.25 30.48 28.0839 30.788 27.7905C31.0959 27.497 31.25 27.0956 31.25 26.6667L31.25 13.3333C31.25 12.9044 31.0959 12.503 30.788 12.2096C30.48 11.9161 30.0391 11.75 29.5 11.75C28.9609 11.75 28.52 11.9161 28.212 12.2095C27.9041 12.503 27.75 12.9044 27.75 13.3333Z"
          fill={props.fill}
          stroke={props.stroke}
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_19_78467">
          <path
            d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
            fill={props.fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Extend;
