import React from 'react';
import ActionBarTypes from './ActionBar.types';
import './ActionBar.css';
import StatusBlock from '../../reusableBlocks/statusBlock/StatusBlock';

const ActionBar: React.FC<ActionBarTypes.Props> = (props) => {
  const isLocked = props.status === 'locked';

  const handleClick = (e:React.MouseEvent) => {
    e.preventDefault();
    if (!isLocked && props.onClick !== undefined) {
      props.onClick();
    }
  };
  return (
    <div
      className={`Action-Bar Impact-Regular ${
        props.onClick !== undefined && !isLocked
          ? 'HasEvent'
          : ''
      } ${props.otherClasses ?? ''}`}
      onClick={handleClick}
      aria-roledescription="button"
    >
      <StatusBlock
        boxSize="small"
        boxState={props.status}
        content={props.content}
        showIcon={false}
      />
      <span className="Span-Text">{props.label} </span>
    </div>
  );
};

export default ActionBar;
