import React from 'react';
import { useIntl } from 'react-intl';
import { OrgActions } from '../../../systemUtils/organisation/OrganisationActions';
import { useNavigate } from 'react-router-dom';

import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import Organisation_Types from '../../../sysObjects/apiModels/Organisation.types';
import { useMsal } from '@azure/msal-react';

import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import ButtonBoxTypes from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox.types';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import CommonTable from '../../../systemComponents/sharedControls/tables/commonTable/CommonTable';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import { getHeadersAsync } from '../../../systemUtils/common/CommonHelpers';
import { ControlState } from '../../../sysObjects/common.types';

const OrganisationIndex: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const context = React.useContext(CommonPageContext);
  const { userClaims } = React.useContext(UserClaimsContext);
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { userSettings } = useUserSettingsContext();
  const [orgs, setOrgs] = React.useState<
    Organisation_Types.organisationTableRow[]
  >([]);

  const showMessages = (
    message: string,
    state: ControlState,
    path: string | null = null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  React.useEffect(() => {
    const loadDataAsync = async () => {
      await OrgActions.lookUpAsync(
        {},
        await getHeadersAsync(userClaims, instance)
      )
        .then((result) => {
          if (result.isFailure || !result.result) {
            showMessages(locales.errorDetails.loadingFailed, 'negative');
          } else {
            setOrgs(
              result.result?.map((org) => {
                let buttons: ButtonBoxTypes.Button[] = [];
                if (UserCore.userIsSuperUser(userClaims!.user!)) {
                  buttons.push({
                    controlState: 'positive',
                    id: `nav-edit-${org.id}`,
                    label: locales.editButton,
                    onClick: () => navigate(`/organisations/${org.id}/edit`),
                  });
  
                  if (!org.billingDetailsId) {
                    buttons.push({
                      id: `nav-create-billing-${org.id}`,
                      controlState: 'positive',
                      label: locales.createBillingDetails,
                      onClick: () =>
                        navigate(`/organisations/${org.id}/billing/create`),
                    });
                  } else {
                    buttons.push({
                      id: `nav-edit-billing-${org.id}`,
                      controlState: 'positive',
                      label: locales.editBillingDetails,
                      onClick: () =>
                        navigate(
                          `/organisations/${org.id}/billing/edit/${org.billingDetailsId}`
                        ),
                    });
                    buttons.push({
                      id: `nav-sla-${org.id}`,
                      controlState: 'positive',
                      label: locales.slaButton,
                      onClick: () =>
                        navigate(
                          `/organisations/${org.id}/billing/${org.billingDetailsId}/sla`
                        ),
                    });
                  }
                }
                return {
                  id: org.id,
                  selected: false,
                  data: [org.name, org.industrySectorCode],
                  billingDetailsId: org.billingDetailsId,
                  buttonColumn: (
                    <ButtonBox
                      id={`button-box-${org.id}`}
                      key={`button-box-${org.id}`}
                      displayBorder={false}
                      buttons={buttons}
                    />
                  ),
                };
              }) || []
            );
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
          showMessages(locales.errorDetails.loadingFailed, 'negative', '/');
        });
    };

    if (!UserCore.userIsCaseManagerOrHigher(userClaims!.user!)) {
      navigate('/');
      return;
    }

    loadDataAsync();
    context?.handleCrumbUpdate(locales.breadcrumbs);
  }, []);

  return isLoading ? (
    <PageLoader alt={locales.common.Load} />
  ) : (
    <div className="Main-Form-Layout">
      <CommonTable
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        headers={[locales.colNames.OrgName, locales.colNames.sectorCode]}
        rows={orgs}
        labels={{
          tableName: locales.tableName,
          of: locales.of,
          size: locales.size,
          emptyMessage: locales.emptyMessage,
        }}
      />

      {UserCore.userIsSuperUser(userClaims!.user!) ? (
        <InformationButton
          buttonDetails={{
            itemKey: 'infoButton',
            label: locales.infoButton,
            mode: 'positive',
            clickEvent: () => navigate('/organisations/create'),
          }}
        >
          {locales.infoSummary}
        </InformationButton>
      ) : null}
    </div>
  );
};

export default OrganisationIndex;
