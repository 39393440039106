import React from 'react';
import SVGTypes from './svg.types';

export const Uneditable: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3783_99470)">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
          fill={props.fill}
          fillOpacity="0.0001"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 40V40.5H40V40H0ZM32 20C32 26.6274 26.6274 32 20 32C17.0463 32 14.3418 30.9328 12.2511 29.163L12.2217 29.1924L10.8075 27.7782L10.8369 27.7488C9.06714 25.6581 8 22.9537 8 20C8 13.3726 13.3726 8 20 8C22.9537 8 25.6581 9.06714 27.7488 10.8369L27.7781 10.8076L29.1923 12.2218L29.163 12.2511C30.9328 14.3418 32 17.0463 32 20ZM20 10C22.4012 10 24.6048 10.8463 26.3287 12.257L12.257 26.3287C10.8463 24.6048 10 22.4012 10 20C10 14.4772 14.4772 10 20 10ZM20 30C17.5987 30 15.3951 29.1536 13.6712 27.7429L27.7429 13.6712C29.1536 15.3951 30 17.5987 30 20C30 25.5228 25.5228 30 20 30Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3783_99470">
          <path
            d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
            fill={props.fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Uneditable;
