import React from 'react';
import ListTableTypes from './ListTable.types';
import PagingSize from '../paging/pagingSize/PagingSize';
import PageSelector from '../paging/pageSelector/PageSelector';
import { pagingDirection } from '../../../../sysObjects/common.types';
import PageLoader from '../../general/loading/pageLoader/PageLoader';
import './ListTable.css';

const ListTable: React.FC<ListTableTypes.Props> = (props) => {
  const usingServerPagination = props.serverPaging !== undefined;
  const [currentPageSize, setCurrentPageSize] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [startRange, setStartRange] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  React.useEffect(() => {
    setCurrentPageSize(props.pagingDetails.currentPageSize);
    setTotalPages(
      Math.ceil(props.rows.length / props.pagingDetails.currentPageSize) - 1,
    );
  }, []);

  const renderFooterSeverPaging = () => {
    const pagingDetails = props.serverPaging!.pagingDetails;

    if (
      pagingDetails.totalAvailableRecords <
      Math.min(...props.pagingDetails.pageSizes)
    ) {
      return null;
    }
    const fromVal =
      (pagingDetails.currentPage - 1 < 0 ? 0 : pagingDetails.currentPage - 1) *
        pagingDetails.pageSize +
      1;

    const toVal =
      (pagingDetails.currentPage - 1) * pagingDetails.pageSize +
      pagingDetails.countOfRecordsInPage;
    return (
      <div className="Table-Paging">
        <div></div>
        <div>
          <PagingSize
            label="Size"
            pageSizes={props.pagingDetails.pageSizes}
            value={pagingDetails.pageSize}
            onChange={(size) => {
              props.serverPaging!.eventHandlers.pageSizeChange(size);
            }}
          />
        </div>
        <div>
          <PageSelector
            from={fromVal}
            to={toVal}
            total={pagingDetails.totalAvailableRecords}
            ofLabel="of"
            pageChangeClick={(step: pagingDirection) => {
              if (step === 'Back') {
                props.serverPaging!.eventHandlers.pageChange(step);
              } else if (step === 'Forward') {
                props.serverPaging!.eventHandlers.pageChange(step);
              } else {
                props.serverPaging!.eventHandlers.pageChange(step);
              }
            }}
          />
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return props.rows.length > Math.min(...props.pagingDetails.pageSizes) ? (
      <div className="Table-Paging">
        <div></div>
        <div>
          <PagingSize
            label="Size"
            pageSizes={props.pagingDetails.pageSizes}
            value={currentPageSize}
            onChange={(size) => {
              setCurrentPageSize(size);
              setTotalPages(Math.ceil(props.rows.length / size) - 1);
            }}
          />
        </div>
        <div>
          <PageSelector
            from={startRange}
            to={
              startRange + currentPageSize > props.rows.length
                ? props.rows.length
                : startRange + currentPageSize
            }
            total={props.rows.length}
            ofLabel="of"
            pageChangeClick={(step: pagingDirection) => {
              switch (step) {
                case 'Start':
                  setStartRange(0);
                  break;
                case 'Back':
                  setCurrentPage(currentPage - 1);
                  setStartRange(currentPage * currentPageSize);
                  break;
                case 'Forward':
                  setCurrentPage(currentPage + 1);
                  setStartRange(currentPage * currentPageSize);
                  break;
                case 'End':
                  setCurrentPage(totalPages);
                  setStartRange(totalPages * currentPageSize);
                  break;
              }
            }}
          />
        </div>
      </div>
    ) : null;
  };
  return (
    <div className={`List-Table ${props.tableClassName}`}>
      <div className="Table-Name Font-Heading">{props.labels.tableName}</div>

      <div className="Table">
        <div className="Head-Row Text-Regular">
          {props.headers !== undefined &&
            props.headers &&
            props.headers.map((i) => i)}
        </div>
        {props.isLoading ? (
          <PageLoader alt="Loading Cases" />
        ) : (
          <>
            <div className="Table-Body Text-Regular">
              {props.defaultItem !== undefined &&
              props.defaultItemPlacing === 'top'
                ? props.defaultItem
                : null}
              {props.rows !== undefined &&
              props.rows !== null &&
              props.rows.length > 0
                ? usingServerPagination
                  ? props.rows.map((i) => i)
                  : props.rows
                      .slice(startRange, startRange + currentPageSize)
                      .map((i) => i)
                : !props.defaultItem && (
                    <div className="Empty-Message">
                      {props.labels.emptyMessage}
                    </div>
                  )}
              {props.defaultItem !== undefined &&
              props.defaultItemPlacing !== 'top'
                ? props.defaultItem
                : null}
            </div>
            {usingServerPagination ? renderFooterSeverPaging() : renderFooter()}
          </>
        )}
      </div>
    </div>
  );
};

export default ListTable;
