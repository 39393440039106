import React from 'react';
import SVGTypes from './svg.types';

export const Dash: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19_78479)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 9C9 8.44772 9.44772 8 10 8H19C19.5523 8 20 8.44772 20 9V15C20 15.5523 19.5523 16 19 16H10C9.44771 16 9 15.5523 9 15V9ZM22 9C22 8.44772 22.4477 8 23 8H32C32.5523 8 33 8.44772 33 9V31C33 31.5523 32.5523 32 32 32H23C22.4477 32 22 31.5523 22 31V9ZM10 18C9.44772 18 9 18.4477 9 19V31C9 31.5523 9.44771 32 10 32H19C19.5523 32 20 31.5523 20 31V19C20 18.4477 19.5523 18 19 18H10Z"
        fill={props.fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_19_78479">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
          fill={props.fill}
        />
      </clipPath>
    </defs>
    </svg>
  );
};

export default Dash;
