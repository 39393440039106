import React from 'react';
import { useIntl } from 'react-intl';
import actions from '../../../../systemUtils/slaDefinition/SlaDefinitionActions';
import OrgActions from '../../../../systemUtils/organisation/OrganisationActions';
import { useNavigate, useParams } from 'react-router-dom';
import UserCore from '../../../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import { useUserSettingsContext } from '../../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import { UserClaimsContext } from '../../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import CommonTableTypes from '../../../../systemComponents/sharedControls/tables/commonTable/CommonTable.types';
import ButtonBox from '../../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import PageLoader from '../../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import CommonTable from '../../../../systemComponents/sharedControls/tables/commonTable/CommonTable';
import InformationButton from '../../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import { silentRequest } from '../../../../systemComponents/authentication/ConfigProvider';
import { getHeadersAsync } from '../../../../systemUtils/common/CommonHelpers';
import CommonPageContext from '../../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { ControlState } from '../../../../sysObjects/common.types';

const SLA_Definition_Index: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { userSettings } = useUserSettingsContext();
  const context = React.useContext(CommonPageContext);

  const { orgId, billingId } = useParams();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [list, setList] = React.useState<CommonTableTypes.DataRow[]>([]);

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const findCaseAsync = async () => {
    const headers = await getHeadersAsync(userClaims, instance);
    const orgResult = await OrgActions.getOrgByIDAsync(orgId!, headers);

    if (orgResult.isFailure || !orgResult.result) {
      showMessage(
        locales.errorDetails.loadingFailed,
        'negative',
        '/organisations',
      );
      return;
    }

    await actions
      .findAsync(headers, {
        orgId: orgId!,
      })
      .then((result) => {
        setIsLoading(false);
        if (result.isFailure || !result.result) {
          showMessage(
            locales.errorDetails.loadingFailed,
            'negative',
            `/organisations/${orgId}/billing/${billingId}`,
          );
          return;
        }

        const breadcrumbs = [
          ...locales.breadcrumbs.base,
          {
            label: orgResult.result!.name,
            link: `/organisations/${orgId}/edit`,
            key: orgId,
          },
          {
            label: locales.breadcrumbs.index.label,
            key: 'sla',
          },
        ];
        context?.handleCrumbUpdate(breadcrumbs);    

        setList(
          result.result.map((li) => {
            return {
              id: li.id!,
              data: [li.name, li.description],
              buttonColumn: (
                <ButtonBox
                  id={`sla-${li.id}`}
                  key={`sla-${li.id}`}
                  displayBorder={false}
                  buttons={[
                    {
                      id: `edit-${li.id}`,
                      controlState: 'positive',
                      label: locales.editButton,
                      onClick: () =>
                        navigate(
                          `/organisations/${orgId}/billing/${billingId}/sla/edit/${li.id}`
                        ),
                    },
                  ]}
                />
              ),
            };
          })
        );
      });
  };

  React.useEffect(() => {
    if (!orgId || !billingId) {
      showMessage(
        locales.errorDetails.loadingFailed,
        'negative',
        `${orgId}/billing/`,
      );
      return;
    }
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      navigate('/');
      return;
    }

    findCaseAsync();
  }, []);

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <CommonTable
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        headers={locales.colNames}
        rows={list}
        labels={{
          of: locales.of,
          size: locales.size,
          tableName: locales.tableName,
          emptyMessage: locales.emptyMessage,
        }}
        key="fk"
      />
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'slaCreate',
            label: locales.createButton,
            mode: 'positive',
            clickEvent: () => {
              navigate(
                `/organisations/${orgId}/billing/${billingId}/sla/create`,
              );
            },
          }}
        >
          {locales.createSummary}
        </InformationButton>
      </div>
    </div>
  );
};

export default SLA_Definition_Index;
