import React from 'react';
import BaseBlockTypes from './BaseBlock.types';

import './BaseBlock.css';
const BaseBlock: React.FC<BaseBlockTypes.Props> = (props) => {
  const handleClick = (e?: React.MouseEvent) => {
    if (e != null) {
      e.preventDefault();
    }
    if (props.onClick) {
      props.onClick();
    }
  };

  const classNames = [
    'Base-Box',
    'Impact-Strong',
    'lexxic-text',
    props.boxSize || '',
    !props.className ? 'Default-Box' : props.className,
    props.rounded == undefined || props.rounded === true ? 'Round-Corners' : '',
    props.onClick ? 'Hover' : ''
  ];

  return (
    <div
      onClick={handleClick}
      id={props.id}
      data-testid={props.testid || `Base-box-${props.id}`}
      key={`container_checkbox_${props.id}`}
      role={!props.onClick ? 'none' : 'button'}
      aria-hidden="true"
      aria-label="State Description"
      className={classNames.join(' ')}
    >
      {props.content}
    </div>
  );
};

export default BaseBlock;
