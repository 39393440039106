import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { PublicClientApplication, EventType, InteractionRequiredAuthError, AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { ConfigContext } from '../../systemComponents/sharedControls/contexts/ConfigContext';
import { silentRequest } from '../../systemComponents/authentication/ConfigProvider';
import SharedLogger, { LogLevel } from '../common/logging/SharedLogger';
import { MsalSetupHooksTypes } from './MsalSetup.types';

const useMsalSetup = (props?: MsalSetupHooksTypes.Props) => {
    const [msalSetupTriggered, setMsalSetupTriggered] = useState<boolean>(false);
    const [interactionInProgress, setInteractionInProgress] = useState<boolean>(false);
    const [msalInitialised, setMsalInitialised] = useState<boolean>(false);
    const { msalConfig } = useContext(ConfigContext);
    const [msalInstance, setMsalInstance] = useState<IPublicClientApplication | null>(null);

    const clearCacheAndReauthenticate = useCallback(() => {
        if (msalInstance) {
            msalInstance.clearCache();
            msalInstance.loginRedirect();
        } else {
            console.error("MSAL instance not found");
        }
    }, [msalInstance]);

    const checkAndRenewToken = useCallback(async () => {
        if (!msalInstance || !msalInitialised) {
            return;
        }

        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            try {
                const tokenResponse = await msalInstance.acquireTokenSilent(silentRequest(accounts[0]));
                console.log("WE'RE HITTING MSAL SETUP");
                msalInstance.setActiveAccount(accounts[0]);
                if (!tokenResponse || tokenResponse.expiresOn! < new Date()) {
                    clearCacheAndReauthenticate();
                }
            } catch (error) {
                SharedLogger(LogLevel.Error, "Token renewal failed:", error);
                clearCacheAndReauthenticate();
            }
        }
    }, [msalInstance, msalInitialised, clearCacheAndReauthenticate]);

    useEffect(() => {
        checkAndRenewToken();
    }, [msalInstance, msalInitialised, checkAndRenewToken]);

    useEffect(() => {
        if(msalInitialised){
            return;
        }

        if (msalConfig && !msalSetupTriggered) {
            setMsalSetupTriggered(true);

            const instance = props?.createMsalInstance
                ? props.createMsalInstance(msalConfig)
                : new PublicClientApplication(msalConfig);

            setMsalInstance(instance);

            instance.initialize().then(async () => {
                instance.enableAccountStorageEvents();
                instance.addEventCallback((event: any) => {
                    const { eventType, payload } = event;

                    if (eventType === EventType.LOGIN_START ||
                        eventType === EventType.SSO_SILENT_START) {
                        setInteractionInProgress(true);
                    }

                    if (
                        eventType === EventType.LOGIN_SUCCESS ||
                        eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                        eventType === EventType.SSO_SILENT_SUCCESS
                    ) {
                        setInteractionInProgress(false);
                        instance.setActiveAccount(payload.account);
                    }

                    if (
                        eventType === EventType.LOGIN_FAILURE ||
                        eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
                        eventType === EventType.SSO_SILENT_FAILURE ||
                        eventType === EventType.LOGOUT_SUCCESS ||
                        eventType === EventType.LOGOUT_END
                    ) {
                        setInteractionInProgress(false);
                        instance.setActiveAccount(null);
                    }
                });

                await instance.handleRedirectPromise().then((response) => {
                    if (response) {
                        instance.setActiveAccount(response.account);
                    } else {
                        const accounts = instance.getAllAccounts();
                        if (accounts.length > 0) {
                            instance.setActiveAccount(accounts[0]);
                        }
                    }
                });

                setMsalInitialised(true);
            });
        }
    }, [msalConfig]);

    return {
        msalInstance,
        msalSetupTriggered,
        interactionInProgress,
        msalInitialised,
    };
};

export default useMsalSetup;
