import React, { useEffect, useRef, useState } from 'react';
import AgreementTextTypes from './AgreementText.types';

import './AgreementText.css';

const AgreementText: React.FC<AgreementTextTypes.Props> = ({
  text,
  onScrollStateChange,
}) => {
  const agreementTextRef = useRef<HTMLDivElement>(null);
  const [scrollState, setScrollState] = useState<AgreementTextTypes.ScrollState>({
    hasScrollbar: false,
    isAtBottom: false,
  });

  useEffect(() => {
    const handleScroll = () => {
      const divElement = agreementTextRef.current;

      if (divElement) {
        const hasScrollbar = divElement.scrollHeight > divElement.clientHeight;
        const isAtBottom =
          divElement.scrollHeight - divElement.scrollTop ===
          divElement.clientHeight;

        setScrollState({
          hasScrollbar,
          isAtBottom,
        });

        onScrollStateChange({
          hasScrollbar,
          isAtBottom,
        });
      }
    };

    const divElement = agreementTextRef.current;
    if (divElement) {
      divElement.addEventListener('scroll', handleScroll);
      return () => {
        divElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [onScrollStateChange]);

  return (
    <div
      id="agreementText"
      ref={agreementTextRef}
      dangerouslySetInnerHTML={{ __html: text }}
 />
  );
};

export default AgreementText;
