import React from 'react';
import { RowLoaderProps } from './RowLoader.types';
import image from './loader.gif';

import './RowLoader.css';
const RowLoader: React.FC<RowLoaderProps> = (props) => {
  return (
    <div className={`Row-Loader ${ props.size ?? 'medium'}`}>
      <img
        src={image}
        alt={props.alt !== undefined ? props.alt : 'Row Loader'}
      />
    </div>
  );
};

export default RowLoader;
