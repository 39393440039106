import { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { RouterProvider } from 'react-router-dom';
import { UserClaimsProvider } from './systemComponents/authentication/UserClaimsProvider';
import { MsalProvider } from '@azure/msal-react';
import UserCore from './systemUtils/userUtils/SystemUserActions';
import UserClaimsConsumer from './systemComponents/authentication/UserClaimsConsumer';
import { AuthenticatedFilter } from './systemComponents/authentication/AuthenticatedFilter';
import { UnauthenticatedFilter } from './systemComponents/authentication/UnauthenticatedFilter';
import { Pages } from './pageViews/PageImports';
import { authRouter, notAuthRouter, unauthorisedRouter } from './RoutesConfig';
import UnauthorisedUserPage from './pageViews/unauthorised/UnauthorisedUserPage';
import PageLoader from './systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import useMsalSetup from './systemUtils/customHooks/MsalSetup';
import './misc/root.css';
import {  UserSettingsProvider } from './systemComponents/sharedControls/contexts/UserSettingsContextType';
const App = () => {
  const [locale] = useState('en-GB');

  const { msalInstance, msalInitialised, interactionInProgress } =
    useMsalSetup();

  const loadingScreen = (
    <div>
      <PageLoader alt="Loading" />
    </div>
  );

  return (
    msalInstance && (
      <MsalProvider instance={msalInstance}>
        <IntlProvider messages={{}} locale={locale} defaultLocale="en-GB">
          <UserClaimsProvider msalInitialised={msalInitialised}>
            < UserSettingsProvider>
              <AuthenticatedFilter>
                <UserClaimsConsumer>
                  {(userClaims) =>
                    !userClaims ? (
                      <RouterProvider router={unauthorisedRouter} />
                    ) : !userClaims.loaded || interactionInProgress ? (
                      loadingScreen
                    ) : userClaims.authorised === false ? (
                      <UnauthorisedUserPage />
                    ) : UserCore.userHasAgreedToPolicy(userClaims.user) ? (
                      <RouterProvider router={authRouter} />
                    ) : (
                      <Pages.ConsentView displayAgreement={true} />
                    )
                  }
                </UserClaimsConsumer>
              </AuthenticatedFilter>
              <UnauthenticatedFilter>
                {interactionInProgress ? (
                  loadingScreen
                ) : (
                  <RouterProvider router={notAuthRouter} />
                )}
              </UnauthenticatedFilter>
            </UserSettingsProvider>
          </UserClaimsProvider>
        </IntlProvider>
      </MsalProvider>
    )
  );
};

export default App;
