import React, { useState, useEffect } from 'react';
import NavMenuTypes from './NavMenu.types';
import './NavMenu.css';
import { SvgDarkMode, SvgExtend, SvgLogOut, SvgRetract } from '../../svg';
import { useUserSettingsContext } from '../../contexts/UserSettingsContextType';
import { set } from 'date-fns';

const NavMenu: React.FC<NavMenuTypes.Props> = (props) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [imageSrc, setImageSrc] = useState(props.image);
  const [mode, setMode] = useState<string>();
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    setImageSrc(isCollapsed ? props.image : props.imageLogo);
  };

  const { userSettings, setUserSettings } = useUserSettingsContext();

  useEffect(() => {
    const colorScheme = window.matchMedia('(prefers-color-scheme: dark)')
      ? 'dark'
      : 'light';
    setMode(colorScheme);
  }, []);

  useEffect(() => {
    document.body.className = userSettings.displayMode || '';
  }, [userSettings.displayMode]);

  const updateUserSettings = (mode: 'dark' | 'light' | null) => {
    const newUserVars = { ...userSettings, displayMode: mode };
    setUserSettings(newUserVars);
    localStorage.setItem('Connect-UserSettings', JSON.stringify(newUserVars));
  };

  return (
    <div className={`NavMenu${isCollapsed ? ' Collapsed' : ' Expanded'}`}>
      <div className="Logo-Box">
        <img
          src={imageSrc}
          alt="Logo"
          className={`image ${isCollapsed ? 'fade-out' : 'fade-in'}`}
          onTransitionEnd={() => {
            if (isCollapsed) {
              setImageSrc(props.imageLogo);
            } else {
              setImageSrc(props.image);
            }
          }}
        />
      </div>
      {props.yourNavigation != null && props.yourNavigation.length > 0 && (
        <div className="Nav-Box">
          <nav aria-label={props.labels.yourNavigation}>
            {props.yourNavigation.map((item) => (
              <a
                id={item.id}
                key={item.id}
                aria-label={item.ariaLabel}
                accessKey={item.accessKey}
                className={`Impact-Strong${props.currentArea === item.areaName ? ' Active' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  if (props.eventHandlers.buttonClicked)
                    props.eventHandlers.buttonClicked(item.to);
                }}
              >
                {item.icon}
                <span className="text">{item.label}</span>
              </a>
            ))}
          </nav>
        </div>
      )}
      {props.workFlowNavigation != null &&
        props.workFlowNavigation.length > 0 && (
          <div className="Nav-Box">
            <h4 className="Impact-Medium">{props.labels.workFlowNavigation}</h4>
            <nav aria-label={props.labels.workFlowNavigation}>
              {props.workFlowNavigation.map((item) => (
                <a
                  id={item.id}
                  key={item.id}
                  aria-label={item.ariaLabel}
                  accessKey={item.accessKey}
                  className={`Impact-Strong${props.currentArea === item.areaName ? ' Active' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (props.eventHandlers.buttonClicked)
                      props.eventHandlers.buttonClicked(item.to);
                  }}
                >
                  {item.icon}
                  <span className="text">{item.label}</span>
                </a>
              ))}
            </nav>
          </div>
        )}
      <div className="Nav-Box">
        <h4 className="Impact-Medium">{props.labels.siteNavigation}</h4>
        <nav aria-label={props.labels.siteNavigation}>
          <a
            id="theme"
            key="theme"
            onClick={(e) => {
              e.preventDefault();

              updateUserSettings(
                userSettings.displayMode === null
                  ? mode === 'dark'
                    ? 'light'
                    : 'dark'
                  : userSettings.displayMode === 'dark'
                    ? 'light'
                    : 'dark',
              );
            }}
            accessKey="D"
            className="Impact-Strong"
            aria-label={
              userSettings.displayMode === null
                ? mode === 'dark'
                  ? props.labels.setLight
                  : props.labels.setDark
                : userSettings.displayMode === 'dark'
                  ? props.labels.setLight
                  : props.labels.setDark
            }
          >
            <SvgDarkMode aria-label="icon" className="icon" />
            <span className="text">
              {userSettings.displayMode === null
                ? mode === 'dark'
                  ? props.labels.setLight
                  : props.labels.setDark
                : userSettings.displayMode === 'dark'
                  ? props.labels.setLight
                  : props.labels.setDark}
            </span>
          </a>
          <a
            id="shrink"
            key="shrink"
            onClick={(e) => {
              e.preventDefault();
              toggleCollapse();
            }}
            accessKey="E"
            className="Impact-Strong"
            aria-label={
              isCollapsed ? props.labels.expandButton : props.labels.closeButton
            }
          >
            {isCollapsed ? (
              <SvgExtend aria-label="icon" className="icon" />
            ) : (
              <SvgRetract aria-label="icon" className="icon close" />
            )}
            <span className="text">{props.labels.closeButton}</span>
          </a>
          {props.siteNavigation != null &&
            props.siteNavigation.length > 0 &&
            props.siteNavigation.map((item) => (
              <a
                id={item.id}
                key={item.id}
                aria-label={item.ariaLabel}
                accessKey={item.accessKey}
                className="Impact-Strong"
                onClick={(e) => {
                  e.preventDefault();
                  if (props.eventHandlers.buttonClicked)
                    props.eventHandlers.buttonClicked(item.to);
                }}
              >
                {item.icon}
                <span className="text">{item.label}</span>
              </a>
            ))}
          <a
            id="Logout"
            key="Logout"
            onClick={(e) => {
              e.preventDefault();
              if (props.eventHandlers.logout) props.eventHandlers.logout();
            }}
            accessKey="Q"
            className="Impact-Strong"
            aria-label={
              isCollapsed
                ? props.labels.expandButton
                : props.labels.logoutButton
            }
          >
            <SvgLogOut aria-label="icon" className="icon" />
            <span className="text">{props.labels.logoutButton}</span>
          </a>
        </nav>
      </div>
    </div>
  );
};

export default NavMenu;
