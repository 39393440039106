import React from 'react';
import AddNoteTypes from './AddNote.Types';
import CaseNote_Types from '../../../../../sysObjects/apiModels/CaseNote.types';
import { FormControlRef } from '../../../../sharedControls/formControls/formControlContainer/FormControlContainer.types';
import FormDropDown from '../../../../sharedControls/formControls/formDropDown/FormDropDown';
import FormTextArea from '../../../../sharedControls/formControls/formTextArea/FormTextArea';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import Expander from '../../../../sharedControls/general/expander/Expander';

import './AddNote.css';

const AddNote: React.FC<AddNoteTypes.Props> = (props) => {
  const formRef = React.useRef<FormControlRef>(null);
  const [isExpanded, setExpanded] = React.useState(false);
  const [documentId, setDocumentId] = React.useState('');
  const [note, setNote] = React.useState('');

  return (
    <div className="Note-Adder">
      <Expander
        id={`AddNote_Status`}
        key={`AddNote_Status`}
        isExpanded={isExpanded}
        eventHandler={{ onClick: () => setExpanded(!isExpanded) }}
      />
      <div className="Action-Title Text-Regular">
        <span className="addLabel">{props.labels.addNote}</span>
      </div>
      {isExpanded && (
        <div className="Note-Details">
          <FormDropDown
            items={props.documents ?? []}
            fieldId="documentId"
            id="documentId"
            label={props.labels.documentLabel}
            defaultText={props.labels.documentSelectDefault}
            value={documentId}
            onChange={(e) => {
              setDocumentId(e.value as string);
            }}
            displayMode="Column"
          />
          <FormTextArea
            id="noteText"
            label={props.labels.noteLabel}
            fieldId="noteText"
            value={note}
            displayMode="Column"
            maxLength={255}
            requiredDetails={{
              formLabel: props.labels.addNote,
              message: props.labels.Required,
            }}
            onChange={(e) => {
              setNote(e.value as string);
            }}
            ref={formRef}
          />
          <ButtonBox
            id="noteAdd"
            displayBorder={true}
            buttons={[
              {
                controlState: 'positive',
                label: props.labels.btnSave,
                id: 'noteAdd',
                onClick: () => {
                  if (!formRef.current?.triggerValidation()) {
                    return;
                  }

                  const caseNote = {
                    content: note!,
                    documentId: documentId,
                    caseId: props.caseId,
                  } as CaseNote_Types.Base;

                  if (!props.events.addNote) return;

                  props.events.addNote(caseNote).then((result) => {
                    if (result.isSuccess) {
                      setNote('');
                      setDocumentId('');
                      setExpanded(false);
                    }
                  });
                },
              },
            ]}
            key={`noteAddButtonBox`}
          />
        </div>
      )}
    </div>
  );
};

export default AddNote;
