import React from 'react';
import { useIntl } from 'react-intl';
import actions from '../../../systemUtils/services/ServiceDefinitionActions';
import { useNavigate } from 'react-router-dom';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import CommonTableTypes from '../../../systemComponents/sharedControls/tables/commonTable/CommonTable.types';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import CommonTable from '../../../systemComponents/sharedControls/tables/commonTable/CommonTable';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import { getHeadersAsync } from '../../../systemUtils/common/CommonHelpers';
import { ControlState } from '../../../sysObjects/common.types';

const ServiceDefinition_index: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const context = React.useContext(CommonPageContext);
  const { userClaims } = React.useContext(UserClaimsContext);
  const { userSettings } = useUserSettingsContext();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [pricings, setPricings] = React.useState<CommonTableTypes.DataRow[]>(
    [],
  );
  const { instance } = useMsal();

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const loadDataAsync = async () => {
    actions
      .findAsync(
        { active: 0 },
        await getHeadersAsync(userClaims, instance)
      )
      .then((result) => {
        setIsLoading(false);
        if (result.isFailure || !result.result) {
          showMessage(locales.errorDetails.loadingFailed, 'negative', '/');
          return;
        }

        context?.handleCrumbUpdate(locales.breadcrumbs);

        setPricings(
          result.result.map((service, index) => {
            return {
              id: service.id,
              data: [
                service.serviceCode,
                service.serviceType,
                service.nominalCode,
                service.description,
              ],
              buttonColumn: (
                <ButtonBox
                  id={`ButtonBox-${index}-${service.id}`}
                  displayBorder={false}
                  key={`ButtonBox-${index}-${service.id}`}
                  buttons={[
                    {
                      controlState: 'positive',
                      label: locales.editButton,
                      id: `edit-${service.id}`,
                      onClick: () => navigate(`/services/edit/${service.id}`),
                    },
                    service.assessmentAppointmentDefinition !== null
                      ? {
                          id: `editAssessmentDefinition-${service.id}`,
                          controlState: 'positive',
                          label: locales.editAssessmentDefinitionButton,
                          onClick: () =>
                            navigate(
                              `/services/${
                                service.id
                              }/appointmentTemplate/edit/${
                                service.assessmentAppointmentDefinition!.id
                              }`,
                            ),
                        }
                      : {
                          id: `createAssessmentDefinition-${service.id}`,
                          controlState: 'positive',
                          label: locales.createAssessmentDefinitionButton,
                          onClick: () =>
                            navigate(
                              `/services/${service.id}/appointmentTemplate/create`,
                            ),
                        },
                  ]}
                />
              ),
            };
          }),
        );
      });
  };

  React.useEffect(() => {
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      navigate('/');
      return;
    }
    loadDataAsync();
  }, []);

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <CommonTable
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        headers={locales.colNames}
        rows={pricings}
        labels={{
          tableName: locales.tableHeading,
          of: locales.of,
          size: locales.size,
          emptyMessage: locales.emptyMessage,
        }}
        key="fk"
      />
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'infoButtonBilling',
            label: locales.createButton,
            mode: 'positive',
            clickEvent: () => navigate('/services/create'),
          }}
        >
          {locales.createSummary}
        </InformationButton>
      </div>
    </div>
  );
};

export default ServiceDefinition_index;
