import React from 'react';
import FormControllerContainer from '../formControlContainer/FormControlContainer';
import FormDateTypes from './FormDate.types';
import { FormControlRef } from '../formControlContainer/FormControlContainer.types';
import { ControlState } from '../../../../sysObjects/common.types';
import './FormDate.css';


const FormDate: React.ForwardRefRenderFunction<
  FormControlRef,
  FormDateTypes.Props
> = (props, ref) => {
  const [boxState, setBoxState] = React.useState<ControlState>('neutral');
  const [errorString, setErrorString] = React.useState<string>('');
  const [day, setDay] = React.useState<string>(
    props.value === null || props.value === undefined
      ? ''
      : String(props.value.getDate()).padStart(2, '0')
  );

  const [month, setMonth] = React.useState<string>(
    props.value === null || props.value === undefined
      ? ''
      : String(props.value.getMonth() + 1).padStart(2, '0')
  );

  const [year, setYear] = React.useState<string>(
    props.value === null || props.value === undefined
      ? ''
      : String(props.value.getFullYear())
  );

  const isValidDate = (year: number, month: number, day: number) => {
    if (month < 1 || month > 12 || day < 1 || day > 31) {
      return false;
    }
    const date = new Date(year, month - 1, day);

    if (
      date.getFullYear() !== year ||
      date.getMonth() + 1 !== month ||
      date.getDate() !== day
    ) {
      return false;
    }

    return true;
  };

  const validateValue = () => {
    if (props.hidden !== undefined && props.hidden) {
      return true;
    }
    if (props.readonly !== undefined && props.readonly) {
      return true;
    }

    if (!props.requiredDetails) {
      setErrorString('');
      setBoxState('positive');
      return true;
    }

    if (!day || !month || !year) {
      setErrorString(
        props.requiredDetails
          ? props.requiredDetails.message
          : props.invalidDateText
      );
      setBoxState('negative');
      return false;
    }

    const parsedDay = parseInt(day, 10);
    const parsedMonth = parseInt(month, 10);
    const parsedYear = parseInt(year, 10);

    if (isNaN(parsedDay) || isNaN(parsedMonth) || isNaN(parsedYear)) {
      setErrorString(props.invalidDateText);
      setBoxState('negative');
      return false;
    }

    if (!isValidDate(parsedYear, parsedMonth, parsedDay)) {
      setErrorString(props.invalidDateText);
      setBoxState('negative');
      return false;
    }

    setErrorString('');
    setBoxState('positive');
    return true;
  };

  const handleInputChange = (fieldName: string, fieldValue: string) => {
    if (props.readonly) {
      return;
    }
    switch (fieldName) {
      case 'day':
        setDay(fieldValue);
        break;
      case 'month':
        setMonth(fieldValue);
        break;
      case 'year':
        setYear(fieldValue);
        break;
      default:
        break;
    }

    if (validateValue()) {
      if (props.onChange) {
        const parsedDay = parseInt(day, 10);
        const parsedMonth = parseInt(month, 10) - 1; // Months are 0-indexed in JavaScript Date
        const parsedYear = parseInt(year, 10);

        props.onChange({
          id: props.id,
          value: new Date(parsedYear, parsedMonth, parsedDay),
          fieldId: props.fieldId,
        });
      }
    }
  };

  // Forward the ref to the FormControllerContainer
  React.useImperativeHandle(ref, () => ({
    triggerValidation: () => validateValue(),
    reset: () => setBoxState('neutral'),
  }));

  return (
    <FormControllerContainer
      id={props.id}
      hidden={props.hidden}
      boxState={boxState}
      readonly={props.readonly}
      label={props.label}
      errorMessage={errorString}
      helpMessage={props.helpMessage}
      insertPadder={true}
      requiredText={
        !props.requiredDetails ? undefined : props.requiredDetails.formLabel
      }
      displayMode={props.displayMode}
    >
      <div className="Date-box Text-Understated Text-Un">
        <div className="date-div date-day">
          <label htmlFor={`${props.id}_day`}>
           {props.datePartLabels.day}
          </label>
          <input
            className="date-font"
            type="text"
            maxLength={2}
            id={`${props.id}_day`}
            data-testid={`${props.id}_day`}
            name="day"
            value={day}
            onChange={(e) => handleInputChange('day', e.target.value)}
            onBlur={(e) => {
              handleInputChange('day', e.target.value);
            }}
            readOnly={props.readonly}
          />
        </div>
        <div className="date-div date-month">
          <label htmlFor={`${props.id}_month`} className="date-font">
            {props.datePartLabels.month}
          </label>
          <input
            className="date-font"
            type="text"
            maxLength={2}
            id={`${props.id}_month`}
            data-testid={`${props.id}_month`}
            name="month"
            value={month}
            onChange={(e) => handleInputChange('month', e.target.value)}
            onBlur={(e) => handleInputChange('month', e.target.value)}
            readOnly={props.readonly}
          />
        </div>
        <div className="date-div date-year">
          <label htmlFor={`${props.id}_year`} className="date-font">
            {props.datePartLabels.year}
          </label>
          <input
            className="date-font"
            type="text"
            maxLength={4}
            id={`${props.id}_year`}
            data-testid={`${props.id}_year`}
            name="year"
            value={year}
            onChange={(e) => handleInputChange('year', e.target.value)}
            onBlur={(e) => handleInputChange('year', e.target.value)}
            readOnly={props.readonly}
          />
        </div>
      </div>
    </FormControllerContainer>
  );
};

export default React.forwardRef(FormDate);
