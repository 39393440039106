import React from 'react';
import SVGTypes from './svg.types';

export const Clock: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3783_99462)">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
          fill={props.fill}
          fillOpacity="0.0001"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 40V40.5H40V40H0ZM20 32C27.1797 32 33 26.1797 33 19C33 11.8203 27.1797 6 20 6C12.8203 6 7 11.8203 7 19C7 26.1797 12.8203 32 20 32ZM20 30C26.0751 30 31 25.0751 31 19C31 12.9249 26.0751 8 20 8C13.9249 8 9 12.9249 9 19C9 25.0751 13.9249 30 20 30ZM17.4 11.2H19.4V19H25.2V21H17.4L17.4 11.2Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3783_99462">
          <path
            d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
            fill={props.fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Clock;
