import React from 'react';
import FormCheckboxTypes from './FormCheckbox.types';
import FormControllerContainer from '../formControlContainer/FormControlContainer';
import { ControlState } from '../../../../sysObjects/common.types';
import { FormControlRef } from '../formControlContainer/FormControlContainer.types';
import './FormCheckbox.css';


const FormCheckboxList: React.ForwardRefRenderFunction<
  FormControlRef,
  FormCheckboxTypes.Props
> = (props, ref) => {
  const [currentBoxState, setBoxState] =
    React.useState<ControlState>('neutral');

  React.useImperativeHandle(ref, () => ({
    triggerValidation: () => validateValue(),
    reset: () => setBoxState('neutral'),
  }));

  const handleChange = (checked: boolean) => {
    if (props.readonly) {
      return;
    }
    validateValue();
    props.onChange &&
      props.onChange({ id: props.id, value: checked, fieldId: props.fieldId });
  };

  const validateValue = () => {
    if (props.hidden !== undefined && props.hidden) {
      return true;
    }
    if (props.hidden !== undefined && props.readonly) {
      return true;
    }
    setBoxState('positive');
    return true;
  };

  return (
    <FormControllerContainer
      id={props.id}
      boxState={currentBoxState}
      label={props.label}
      hidden={props.hidden}
      displayMode={props.displayMode}
      readonly={props.readonly}
    >
      <fieldset className="checkBox-single">
        <legend>{props.helpMessage}</legend>
        <label
          id={`${props.id}_Label`}
          className="check-label"
          htmlFor={`${props.id}`}
        >
          <input
            id={`${props.id}`}
            onBlur={validateValue}
            type="checkbox"
            checked={props.value}
            onChange={(e) => handleChange(e.target.checked)}
            readOnly={props.readonly}
            data-testid={`${props.id}`}
          />
        </label>
      </fieldset>
    </FormControllerContainer>
  );
};

export default React.forwardRef(FormCheckboxList);
