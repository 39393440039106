import React, {
  useState,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
} from 'react';
import FormTextCaptureTypes from './FormTextCapture.types';
import FormControllerContainer from '../formControlContainer/FormControlContainer';
import { FormControlRef } from '../formControlContainer/FormControlContainer.types';
import { ControlState } from '../../../../sysObjects/common.types';

import './FormTextCapture.css';

const FormTextCapture: ForwardRefRenderFunction<
  FormControlRef,
  FormTextCaptureTypes.Props
> = (props, ref) => {
  const [errorString, setErrorString] = useState<string>('');
  const [currentBoxState, setBoxState] = useState<ControlState>('neutral');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.readonly) {
      return;
    }
    let updatedValue = e.target.value;

    if (props.textInputType === 'tel') {
      updatedValue = e.target.value.replace(/\D/g, '');
    }

    if (props.textInputType === 'number' && props.decimalPlaces) {
      if (updatedValue.includes('.')) {
        const items = updatedValue.split('.');
        if (items.length > 1) {
          if (items[1].length > props.decimalPlaces) {
            updatedValue = `${items[0]}.${items[1].substring(
              0,
              props.decimalPlaces
            )}`;
          }
        }
      }
    }

    setErrorString(
      props.requiredDetails && !updatedValue.trim()
        ? props.requiredDetails.message || ''
        : ''
    );

    if (props.onChange) {
      props.onChange({
        id: props.id,
        value: updatedValue,
        fieldId: props.fieldId,
      });
    }
  };

  const validateValue = () => {
    if (props.readonly) {
      return true;
    }
    if (props.hidden === true) {
      return true;
    }
    if (props.requiredDetails) {
      if (
        !props.value ||
        (typeof props.value === 'string' && !props.value.trim())
      ) {
        setErrorString(props.requiredDetails.message || '');
        setBoxState('negative');
        return false;
      }
      setBoxState('positive');
    }

    setErrorString('');
    setBoxState('positive');

    if (
      props.textInputType === 'email' &&
      typeof props.value === 'string' &&
      !isValidEmail(props.value)
    ) {
      setBoxState('negative');
      setErrorString(props.invalidedEmailText || '');
      return false;
    }

    return true;
  };

  const isValidEmail = (email: string | null | undefined): boolean => {
    if (email === null || email === undefined || email.trim() === '') {
      return true;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useImperativeHandle(ref, () => ({
    triggerValidation: () => validateValue(),
    reset: () => setBoxState('neutral'),
  }));

  const displayValue = () => {
    if (props.value && typeof props.value === 'string') {
      if (props.textInputType === 'tel') {
        if (props.value.length > 5) {
          return props.value.slice(0, 5) + ' ' + props.value.slice(5);
        }
      }
      return props.value;
    }
    return '';
  };

  return (
    <FormControllerContainer
      id={props.id}
      boxState={currentBoxState}
      label={props.label}
      errorMessage={errorString}
      helpMessage={props.helpMessage}
      requiredText={props.requiredDetails?.formLabel}
      hidden={props.hidden}
      displayMode={props.displayMode}
      readonly={props.readonly}
    >
      <input
        id={props.id}
        data-testid={props.id}
        type={props.textInputType}
        value={displayValue()}
        className="form-textBox Text-Understated"
        onBlur={(e) => validateValue()}
        maxLength={
          props.maxLength !== undefined
            ? props.maxLength
            : props.textInputType === 'tel'
            ? 12
            : undefined
        }
        onChange={handleChange}
        readOnly={props.readonly}
      />
    </FormControllerContainer>
  );
};

export default forwardRef(FormTextCapture);
