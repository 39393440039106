import React, { FC, useEffect, useState } from 'react';
import AlertListTypes from './AlertList.types';
import './AlertList.css';
import StatusBlock from '../../../reusableBlocks/statusBlock/StatusBlock';
import { ControlState } from '../../../../../sysObjects/common.types';
import { SvgBad } from '../../../svg';
import ButtonBox from '../../../reusableBlocks/buttonBox/ButtonBox';

const AlertList: FC<AlertListTypes.Props> = (props) => {
  const controlStates: ControlState[] = [
    'positive',
    'negative',
    'neutral',
    'warning',
    'locked',
  ];
  const [messages, setMessages] = useState<AlertListTypes.messageItem[]>([]);
  const [selectedType, setSelectedType] = useState<ControlState | null>(null);

  useEffect(() => {
    if (props.detail) {
      setMessages([
        ...messages,
        { ...props.detail, id: Date.now().toString() },
      ]);
      props.propUpdate();
    }
  }, [props.detail]);

  return (
    <div className={`Alert-List${messages.length > 0 ? ' Expanded' : ''}`}>
      <div className="Totals">
        {messages.length > 0 &&
          controlStates.map(
            (type) =>
              messages.filter((message) => message.alertType === type).length >
                0 && (
                <div
                  key={type}
                  className={`Total${selectedType === type ? ' Selected' : ''}`}
                  onClick={() => setSelectedType(type)}
                >
                  <StatusBlock
                    boxSize="medium"
                    boxState={type}
                    id={`Total-${type.charAt(0).toUpperCase() + type.slice(1)}`}
                    allowHover={false}
                    showIcon={true}
                  />
                  <span className="Action-AllCaps">
                    {messages.filter((m) => m.alertType === type).length}
                  </span>
                </div>
              ),
          )}
      </div>
      <div
        className={`MessageDetails${selectedType !== null ? ' Expanded' : ' Collapsed'}`}
      >
        <div className="Messages">
          {messages
            .filter((message) => message.alertType === selectedType)
            .map((message) => (
              <div key={message.id} className="Message">
                <StatusBlock
                  boxSize="small"
                  boxState={message.alertType as ControlState}
                  id={`Message-${message.id}`}
                  showIcon={true}
                  allowHover={false}
                />
                <span>{message.message}</span>
                <StatusBlock
                  boxSize="small"
                  boxState="neutral"
                  id={`Message-close-${message.id}`}
                  allowHover={false}
                  showIcon={false}
                  content={<SvgBad className="icon-dark" />}
                  onclick={() =>
                    setMessages(messages.filter((m) => m.id !== message.id))
                  }
                />
              </div>
            ))}
        </div>
        <div className="Footer">
          <div />
          <ButtonBox
            className={selectedType !== null ? '' : 'hidden'}
            displayBorder={false}
            id="Messages-Buttons"
            buttons={[
              {
                id: 'Close',
                controlState: 'positive',
                label: props.labels.closeButton,
                onClick: () => setSelectedType(null),
              },
              {
                id: 'MarkAllRead',
                controlState: 'positive',
                label:props.labels.allClearButton,
                onClick: () => {
                  setMessages(
                    messages.filter((m) => m.alertType !== selectedType),
                  );
                  setSelectedType(null);
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default AlertList;
