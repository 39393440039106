import React, {
  useState,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
} from 'react';
import FormTextAreaTypes from './FormTextArea.types';
import FormControllerContainer from '../formControlContainer/FormControlContainer';
import { FormControlRef } from '../formControlContainer/FormControlContainer.types';
import { ControlState } from '../../../../sysObjects/common.types';

import './FormTextArea.css';

const FormTextCapture: ForwardRefRenderFunction<
  FormControlRef,
  FormTextAreaTypes.Props
> = (props, ref) => {
  const [errorString, setErrorString] = useState<string>('');
  const [currentBoxState, setBoxState] = useState<ControlState>('neutral');

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (props.readonly) {
      return;
    }
    let updatedValue = e.target.value;

    setErrorString(
      props.requiredDetails && !updatedValue.trim()
        ? props.requiredDetails.message || ''
        : ''
    );

    if (props.onChange) {
      props.onChange({
        id: props.id,
        value: updatedValue,
        fieldId: props.fieldId
      });
    }
  };

  const validateValue = () => {
    if (props.readonly !== undefined && props.readonly) {
      return true;
    }
    if (props.hidden !== undefined && props.hidden) {
      return true;
    }
    if (props.requiredDetails) {
      if (
        !props.value ||
        (typeof props.value === 'string' && !props.value.trim())
      ) {
        setErrorString(props.requiredDetails.message || '');
        setBoxState('negative');
        return false;
      }
      setBoxState('positive');
    }

    setErrorString('');
    setBoxState('positive');
    return true;
  };

  useImperativeHandle(ref, () => ({
    triggerValidation: () => validateValue(),
    reset: () => setBoxState('neutral'),
  }));

  return (
    <FormControllerContainer
      id={props.id}
      boxState={currentBoxState}
      label={props.label}
      errorMessage={errorString}
      helpMessage={props.helpMessage}
      requiredText={props.requiredDetails?.formLabel}
      hidden={props.hidden}
      displayMode={props.displayMode}
      readonly={props.readonly}
    >
      <textarea
        id={props.id}
        cols={30}
        rows={5}
        data-testid={props.id}
        value={props.value}
        className="form-textBox Text-Understated"
        onBlur={(e) => validateValue()}
        maxLength={props.maxLength !== undefined ? props.maxLength : undefined}
        onChange={handleChange}
        readOnly={props.readonly}
      />
    </FormControllerContainer>
  );
};

export default forwardRef(FormTextCapture);
