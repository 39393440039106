import AppointmentTypes from '../../sysObjects/apiModels/Appointment.types';
import System_Types from '../../sysObjects/apiModels/System.types';
import { getHostConfigAsync } from '../common/HostConfigActions';
import settings from '../../config/services.json';
import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import { confirmAction } from '../common/APIHelper';

namespace AppointmentRepoActions {
  export const setOutcomeAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    obj: AppointmentTypes.SetOutcome
  ): Promise<FunctionalResult<void>> => {
    if (
        headerInfo.isFailure ||
        headerInfo.result === null ||
        headerInfo.result === undefined
      ) {
        return FunctionalResult.Error('Failed to load host configuration');
      }
      const host = await getHostConfigAsync();
  
      if (host.isFailure) {
        return FunctionalResult.Error('Failed to load host configuration');
      }
  
      return confirmAction({
        hostPath: host!.result!.path,
        apiPath: `${settings.Appointments.setOutcome.path!}`,
        userId: headerInfo.result!.userId,
        accessToken: headerInfo.result!.token,
        formData: obj,
      });
  };

  export const updateChargeableAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    obj: AppointmentTypes.SetOutcome
  ): Promise<FunctionalResult<void>> => {
    if (
        headerInfo.isFailure ||
        headerInfo.result === null ||
        headerInfo.result === undefined
      ) {
        return FunctionalResult.Error('Failed to load host configuration');
      }
      const host = await getHostConfigAsync();
  
      if (host.isFailure) {
        return FunctionalResult.Error('Failed to load host configuration');
      }
  
      return confirmAction({
        hostPath: host!.result!.path,
        apiPath: `${settings.Appointments.updateChargeable.path!}`,
        userId: headerInfo.result!.userId,
        accessToken: headerInfo.result!.token,
        formData: obj,
      });
  };
}

export default AppointmentRepoActions;
