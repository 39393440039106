import React from 'react';
import BreadCrumbBarTypes from '../pageLevel/breadcrumbs/Breadcrumbs.types';
import { messageDetails } from '../../../sysObjects/common.types';
const CommonPageContext = React.createContext<
  | {
      handleCrumbUpdate: (items: BreadCrumbBarTypes.BreadcrumbsLinks[]) => void;
      handleMessage: (details: messageDetails) => void;
    }
  | undefined
>(undefined);

export default CommonPageContext;
