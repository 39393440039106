import React, { useState, useEffect } from 'react';
import BillableItemRowTypes from './BillableItemRow.types';
import BillableItem_Types from '../../../../../sysObjects/apiModels/BillableItem.types';
import StatusLabel from '../../../../sharedControls/reusableBlocks/statusLabel/StatusLabel';
import StatusBlock from '../../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import Expander from '../../../../sharedControls/general/expander/Expander';
import { getCurrencyString, getTimeSince } from '../../../../../systemUtils/common/CommonHelpers';
import { ControlState } from '../../../../../sysObjects/common.types';
import { SvgClock } from '../../../../sharedControls/svg';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import FormTextCapture from '../../../../sharedControls/formControls/formTextCapture/FormTextCapture';
import FormTextArea from '../../../../sharedControls/formControls/formTextArea/FormTextArea';
import { FormControlRef } from '../../../../sharedControls/formControls/formControlContainer/FormControlContainer.types';
import ButtonBoxTypes from '../../../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox.types';

const BillableItemRow: React.FC<BillableItemRowTypes.BillableItemRowProps> = (
  props,
) => {
  const [row, setRow] = React.useState<
  BillableItemRowTypes.BillableItemRowItem
>(props.item);

  const [isExpanded, setExpandedState] = useState<Boolean>(
    props.item.isExpanded,
  );
  const formRef = React.useRef<FormControlRef>(null);

  React.useEffect(() => {
    setRow(props.item);
  }, [props.item]);

  const [note, setNote] = React.useState<string>("");

  const [taxAmount, setTaxAmount] = React.useState(props.item.taxAmount.toString() ?? '');
  const [priceAmount, setPriceAmount] = React.useState(props.item.price.toString() ?? '');

  const boundPriceAmount = (newPrice: string) => {
    if (newPrice === '') {
      setPriceAmount(newPrice);
      return;
    }

    const priceAsFloat = parseFloat(newPrice);
    if (isNaN(priceAsFloat) || priceAsFloat <= 0) {
      setPriceAmount('')
      return;
    }
    setPriceAmount(newPrice);
  }

  const boundTaxAmountPrice = (newPrice: string) => {
    if (newPrice === '') {
      setTaxAmount(newPrice);
      return;
    }

    const priceAsFloat = parseFloat(newPrice);
    if (isNaN(priceAsFloat) || priceAsFloat <= 0) {
      setTaxAmount('')
      return;
    }
    setTaxAmount(newPrice);
  }

  const DetermineUserButtons = (
    row: BillableItemRowTypes.BillableItemRowItem,
  ): ButtonBoxTypes.Button[] => {
    let buttons: ButtonBoxTypes.Button[] = [];

    if (row.invoiceRaised) {
      return buttons;
    }
    
    if (!row.isEditing) {
      buttons.push({
        controlState: 'positive',
        id: row.itemID,
        label: props.labels.buttons.edit,
        onClick: () => { 
          props.eventHandlers.editBillableItem(row);
        }
      });  

      buttons.push({
        controlState: 'positive',
        id: row.itemID,
        label: props.labels.buttons.invoice,
        onClick: () => {
          props.eventHandlers.raiseInvoice(row.caseId, [row.itemID]);
        }
      });
    }
    else{
      

        buttons.push({
          controlState: 'positive',
          id: row.itemID,
          label: props.labels.buttons.save,
          onClick: () => {
            if (formRef.current !== null) {
              if (!formRef.current.triggerValidation()) {
                return;
              }
            }

            const modifiedBillableItem = {
              id: row.itemID,
              caseId: row.caseId,
              serviceToFulfillId: row.serviceToFulfillId,
              unitPrice: parseFloat(priceAmount),
              taxAmount: parseFloat(taxAmount),
              note: note
            } as BillableItem_Types.ModifiedBillableItemPriceType;

            props.eventHandlers.updateBillableItem(modifiedBillableItem);
          }
        });  

        buttons.push({
          controlState: 'positive',
          id: row.itemID,
          label: props.labels.buttons.cancel,
          onClick: () => {
            props.eventHandlers.cancelEdit(row);
            setNote(props.item.note ?? '');
            setTaxAmount(props.item.taxAmount.toString() ?? '');
            setPriceAmount(props.item.price.toString() ?? '');
          }
        });  
    }
    return buttons;
  };

 
  const renderExpander = () => {
    return (
      <div>
        <Expander
          isExpanded={isExpanded}
          id={`${props.item.itemID}_Expander`}
          key={`${props.item.itemID}_Expander`}
          eventHandler={{ onClick: () => setExpandedState(!isExpanded) }}
        />
      </div>
    );
  };

  const renderCollapsedHeader = () => {
    const currentState = props.states.find(
      (state) => state.key === (props.item.invoiceRaised ? 1 : 2),
    );

    return (
      <>
        {renderExpander()}
        <div className="td">
          {props.item.name}
        </div>
        <div className="td">
        <StatusLabel
                label={props.labels.itemId}
                renderAs="span"
                key={`billable_item_item_id_${props.item.itemID}`}
                status="neutral"
              />
              {props.item.invoiceNumber}
        </div>
        <div className="td">
          {getCurrencyString(props.item.price, props.item.currency)}
        </div>
        <div className="td">
          {getCurrencyString(props.item.taxAmount, props.item.currency)}
        </div>
        <div className="td">
          {currentState !== undefined ? (
            <StatusLabel
              label={currentState.value}
              renderAs="div"
              status={currentState.state as ControlState}
            />
          ) : (
            ''
          )}
        </div>
      </>
    );
  };

  const renderExpandedHeader = () => {
    return (
      <>
        {renderExpander()}
        <div className="td">{props.item.name}</div>
        <div />
        <div />
        <div />
        <div />
      </>
    );
  };

  const renderExpandedRow = () => {
    const currentState = props.states.find(
      (state) => state.key === (props.item.invoiceRaised ? 1 : 2),
    );

    return (
      <div className="Expanded-row">      
        {props.item.isEditing ? (
          <>
              <FormTextCapture
                textInputType={'number'}
                fieldId="priceAmount"
                id="priceAmount"
                label={`${props.labels.price} (${props.item.currency})`}
                value={priceAmount}
                ref={formRef}
                requiredDetails=
                {{
                  formLabel: '',
                  message: props.labels.required
                }}
                displayMode={'Column'}
                  onChange={(e) => boundPriceAmount(e.value as string)}
              />
          </> 
          ):(
          <>
          <div className="col Text-Regular">{props.labels.price}</div>
            <div className="Value-Column">
              <div className="col Text-Regular">
                {getCurrencyString(props.item.price, props.item.currency)}
              </div>
            </div>
          </>
        )}

        {props.item.price !== props.item.originalPrice && (
          <>
            <div className="col Text-Regular">{props.labels.originalPrice}</div>
            <div className="Value-Column">
              <div className="col Text-Regular">
                {getCurrencyString(
                  props.item.originalPrice,
                  props.item.currency
                )}
              </div>
            </div>
          </>
        )}         

        {props.item.isEditing ? (
          <>
              <FormTextCapture
                textInputType={'number'}
                fieldId="taxAmount"
                id="taxAmount"
                label={`${props.labels.taxAmount} (${props.item.currency})`}
                value={taxAmount}
                ref={formRef}
                requiredDetails=
                {{
                  formLabel: '',
                  message: props.labels.required
                }}
                displayMode={'Column'}
                onChange={(e) => boundTaxAmountPrice(e.value as string)}
              />
          </> 
          ):(
          <>
         <div className="col Text-Regular">{props.labels.taxAmount}</div>
        <div className="Value-Column">
          <div className="col Text-Regular">
            {getCurrencyString(props.item.taxAmount, props.item.currency)}
          </div>
        </div>
          </>
        )}    

      {props.item.isEditing && (
        <>
          <FormTextArea
            fieldId="note"
            id="note"
            label={props.labels.note}
            displayMode="Column"
            maxLength={255}
            value={note || ''}
            requiredDetails={{
              formLabel: '',
              message: props.labels.required
            }}
            onChange={(e) => {
               setNote(e.value as string);
            }}
           ref={formRef}
          />
         </>
      )}  

        {props.item.taxAmount !== props.item.originalTaxAmount && (
          <>
            <div className="col Text-Regular">
              {props.labels.originalTaxAmount}
            </div>
            <div className="Value-Column">
              <div className="col Text-Regular">
                {getCurrencyString(
                  props.item.originalTaxAmount,
                  props.item.currency
                )}
              </div>
            </div>
          </>
        )}

        {props.item.note && (
          <>
            <div className="col Text-Regular">{props.labels.note}</div>
            <div className="Value-Column">
              <div className="col Text-Regular">{props.item.note}</div>
            </div>
          </>
        )}

        <div className="col Text-Regular">{props.labels.createdDateTime}</div>
        <div className="Value-Column">
          <StatusBlock
            boxSize="small"
            boxState="positive"
            key={`created_Clock_${props.item.itemID}`}
            showIcon={false}
            content={<SvgClock width={30} height={30} fill={'white'} />}
          />
          {getTimeSince(props.item.dateCreated)}
        </div>

        <div className="col Text-Regular">
          {props.labels.billableOrganisationName}
        </div>
        <div className="Value-Column">
          <StatusLabel
            label={props.labels.organisation}
            renderAs="span"
            key={`billable_item_row_org_name_${props.item.itemID}`}
            status="neutral"
          />
          <p>{props.item.billableOrganisationName}</p>
        </div>

        {props.item.invoiceNumber && (
          <>
            <div className="col Text-Regular">{props.labels.itemId}</div>
            <div className="Value-Column">
              <StatusLabel
                label={props.labels.itemId}
                renderAs="span"
                key={`billable_item_item_id_${props.item.itemID}`}
                status="neutral"
              />
              {props.item.invoiceNumber}
            </div>
          </>
        )}

        <div className="col Text-Regular">{props.labels.codes}</div>
        <div className="Value-Column">
          <StatusLabel
            label={props.labels.nominalCode}
            renderAs="span"
            key={`billable_item_nominal_code_${props.item.itemID}`}
            status="neutral"
          />
          {props.item.nominalCode}

          <StatusLabel
            label={props.labels.serviceCode}
            renderAs="span"
            key={`billable_item_service_code_${props.item.itemID}`}
            status="neutral"
          />
          {props.item.serviceCode}
        </div>

        {(props.item.invoiceRaised || props.item.invoiceDueDate) && (
          <>
            <div className="col Text-Regular">{props.labels.invoice}</div>

            <div className="Value-Column">
              {props.item.invoiceRaised && (
                <>
                  <StatusLabel
                    label={props.labels.dateRaised}
                    renderAs="span"
                    key={`invoice_date_raised_${props.item.itemID}`}
                    status="neutral"
                  />
                  <StatusBlock
                    boxSize="small"
                    boxState="positive"
                    key={`raised_Clock_${props.item.itemID}`}
                    showIcon={false}
                    content={<SvgClock width={30} height={30} fill={'white'} />}
                  />
                  {getTimeSince(props.item.invoiceDate!)}
                </>
              )}

              {props.item.invoiceDueDate && (
                <>
                  <StatusLabel
                    label={props.labels.dateDue}
                    renderAs="span"
                    key={`billable_item_date_due_${props.item.itemID}`}
                    status="neutral"
                  />
                  <StatusBlock
                    boxSize="small"
                    boxState="positive"
                    key={`invoiced_Clock_${props.item.itemID}`}
                    showIcon={false}
                    content={<SvgClock width={30} height={30} fill={'white'} />}
                  />
                  {getTimeSince(props.item.invoiceDueDate!)}
                </>
              )}
            </div>
          </>
        )}

        <div className="col Text-Regular">{props.labels.status}</div>
        <div className="Value-Column">
          {currentState !== undefined ? (
            <StatusLabel
              label={currentState.value}
              renderAs="div"
              status={currentState.state as ControlState}
            />
          ) : (
            ''
          )}
        </div>
        
        {!props.item.invoiceRaised && (
          <>
           <div className="col Text-Regular">{props.labels.actions}</div>
            <div className="col-buttons">
                <ButtonBox
                  key={`billable_item_buttons_${props.item.itemID}`}
                  id={`billable_item_buttons_${props.item.itemID}`}
                  className="Billable-Item-Buttons"
                  displayBorder={true}
                  buttons={DetermineUserButtons(props.item)}
                />
            </div>
          </>
        )}
       
      </div>
    );
  };

  const renderRow = () => {
    return (
      <>
        {isExpanded ? renderExpandedHeader() : renderCollapsedHeader()}
        {isExpanded && renderExpandedRow()}
      </>
    );
  };

  return <div className="Billable-Item">{renderRow()}</div>;
};

export default BillableItemRow;
