import React from 'react';
import SVGTypes from './svg.types';

export const Document: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3783_99452)">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
          fill={props.fill}
          fillOpacity="0.0001"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 40V40.5H40V40H0ZM30 11L26 7H10V33H30V11ZM25.5858 8L29 11.4142V32H11V8H25.5858ZM27 14H13V16H27V14ZM13 18H27V20H13V18ZM24 22H13V24H24V22Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3783_99452">
          <path
            d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
            fill={props.fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Document;
