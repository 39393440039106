import React from 'react';
import SVGTypes from './svg.types';

export const User: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      className={props.className}
      width={props.width}
      data-testid={props.testId}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3783_99448)">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
          fill={props.fill}
          fillOpacity="0.0001"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 40V40.5H40V40H0ZM20 20C21.4587 20 22.8576 19.4205 23.8891 18.3891C24.9205 17.3576 25.5 15.9587 25.5 14.5C25.5 13.0413 24.9205 11.6424 23.8891 10.6109C22.8576 9.57946 21.4587 9 20 9C18.5413 9 17.1424 9.57946 16.1109 10.6109C15.0795 11.6424 14.5 13.0413 14.5 14.5C14.5 15.9587 15.0795 17.3576 16.1109 18.3891C17.1424 19.4205 18.5413 20 20 20ZM31 29.1667C31 31 29.1667 31 29.1667 31H10.8333C10.8333 31 9 31 9 29.1667C9 27.3333 10.8333 21.8333 20 21.8333C29.1667 21.8333 31 27.3333 31 29.1667ZM22.5927 17.0927C23.2804 16.4051 23.6667 15.4725 23.6667 14.5C23.6667 13.5275 23.2804 12.5949 22.5927 11.9073C21.9051 11.2196 20.9725 10.8333 20 10.8333C19.0275 10.8333 18.0949 11.2196 17.4073 11.9073C16.7196 12.5949 16.3333 13.5275 16.3333 14.5C16.3333 15.4725 16.7196 16.4051 17.4073 17.0927C18.0949 17.7804 19.0275 18.1667 20 18.1667C20.9725 18.1667 21.9051 17.7804 22.5927 17.0927ZM27.6413 26.1087C28.8843 27.3517 29.1648 28.7083 29.1667 29.1593H10.8333C10.837 28.7083 11.1157 27.3517 12.3587 26.1087C13.554 24.9133 15.8017 23.6667 20 23.6667C24.1965 23.6667 26.446 24.9133 27.6413 26.1087Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3783_99448">
          <path
            d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
            fill={props.fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default User;
