import React, { ReactNode, useState, useEffect } from 'react';
import AgreementText from '../agreementText/AgreementText';
import Button from '../../general/button/Button';
import AgreementTextTypes from '../agreementText/AgreementText.types';
import UserAgreementTypes  from './UserAgreement.types';

const UserAgreement: React.FC<UserAgreementTypes.Props> = ({
  agreeEvent,
  disagreeEvent,
  text,
  displayAgreement,
}) => {
  const [disableAgree, setDisableAgree] = useState<boolean>(false);
  const [checked, setCheck] = useState<boolean>(false);

  const handleScrollStateChange = (scrollState: AgreementTextTypes.ScrollState) => {
    // if (scrollState.hasScrollbar == false) {
    //   setDisableAgree(false);
    //   setCheck(false);
    //   return;
    // }
    // setDisableAgree(!scrollState.isAtBottom);
    // if (!scrollState.isAtBottom) {
    //   setCheck(false);
    // }
  };

  return (
    <div className="agreement">
      <AgreementText
        onScrollStateChange={handleScrollStateChange}
        text={text}
      />
      <div hidden={!displayAgreement}>
        <label>
          <input
            type="checkbox"
            checked={checked}
            role='checkbox'
            onChange={() => setCheck(!checked)}
            disabled={disableAgree}
          />
          {' I accept the terms and conditions'}
        </label>
      </div>
      <div hidden={!displayAgreement}>
        <Button
          mode="neutral"
          itemKey="userDisagrees"
          clickEvent={disagreeEvent}
          label="Disagree"
        />
      </div>
      <div hidden={!displayAgreement}>
        <Button
          itemKey="userAgrees"
          mode='positive'
          clickEvent={agreeEvent}
          disabled={!checked}
          label="Agree"
        />
      </div>
    </div>
  );
};

export default UserAgreement;
