import React from "react";
import { useMsal } from "@azure/msal-react";

export const UnauthenticatedFilter: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  return !activeAccount
    ? <> {children} </>
    : null;
};

