// src/pages/Consent/Consent.tsx
import React, { useContext } from 'react';
import currentPolicyText from '../../config/currentPolicyText.json';
import intUser from '../../systemUtils/userUtils/SystemUserActions';
import UserCore from '../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import { UserClaimsContext } from '../../systemComponents/sharedControls/contexts/UserClaimsContext';
import SharedLogger, { LogLevel } from '../../systemUtils/common/logging/SharedLogger';
import UserAgreement from '../../systemComponents/sharedControls/targetedControls/userAgreement/UserAgreement';
import { getHeadersAsync } from '../../systemUtils/common/CommonHelpers';

const Consent: React.FC<ConsentProps> = ({ displayAgreement,  }) => {
  const { userClaims, updateUserClaims } = useContext(UserClaimsContext);
  const { instance } = useMsal();

  const userDisagrees = () => {
    var result = window.confirm('Are you sure you reject the agreement? ');

    if (result) {
      alert("You've rejected the agreement");
    }
  };

  const userAgrees = () => {
    UserCore.fetchIPInfo().then(async (ipInfo) => {
      let ip = '';

      if (ipInfo.isSuccess) {
        ip = ipInfo.result!;
      }

      const headers = await getHeadersAsync(userClaims, instance);

      UserCore.saveConsentAsync(headers, ip).then((rst) => {
        if (rst.isFailure) {
          return;
        }

        try {
          intUser.refreshClaimsAsync(headers).then((refUser) => {
            if (refUser.isSuccess) {
              updateUserClaims(refUser.result!);
            }
          });
          SharedLogger(LogLevel.Info, 'User claims updated successfully');
        } catch (error) {
          SharedLogger(LogLevel.Error, {message: 'Error updating user claims:', Error: error});
        }
      });
    });
  };

  return (
    <UserAgreement
      agreeEvent={userAgrees}
      disagreeEvent={userDisagrees}
      key={'Agreement'}
      text={currentPolicyText.text}
      displayAgreement={displayAgreement}
    />
  );
};

export default Consent;

interface ConsentProps {
  displayAgreement: boolean;
  id?: string;
}
