import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';

// MSAL imports
import { ConfigProvider } from './systemComponents/authentication/ConfigProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigProvider>
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

//reportWebVitals(console.log);
