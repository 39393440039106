import React from 'react';
import SVGTypes from './svg.types';

export const ToEnd: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
        fill={props.fill}
        fillOpacity="0.0001"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3478 19.192C10.1251 19.4063 10 19.6969 10 20C10 20.303 10.1251 20.5937 10.3478 20.808C10.5704 21.0223 10.8725 21.1427 11.1874 21.1427L24.9442 21.1427L19.8456 26.0469C19.6227 26.2615 19.4974 26.5525 19.4974 26.8559C19.4974 27.1593 19.6227 27.4503 19.8456 27.6649C20.0686 27.8795 20.371 28 20.6863 28C21.0016 28 21.304 27.8795 21.527 27.6649L28.6512 20.809C28.7617 20.7029 28.8495 20.5768 28.9093 20.4379C28.9692 20.2991 29 20.1503 29 20C29 19.8497 28.9692 19.7009 28.9093 19.5621C28.8495 19.4232 28.7617 19.2971 28.6512 19.191L21.527 12.3351C21.304 12.1205 21.0016 12 20.6863 12C20.371 12 20.0686 12.1205 19.8456 12.3351C19.6227 12.5497 19.4974 12.8407 19.4974 13.1441C19.4974 13.4475 19.6227 13.7385 19.8456 13.9531L24.9442 18.8573L11.1874 18.8574C10.8725 18.8574 10.5704 18.9777 10.3478 19.192ZM31.5 28C30.5254 28 30 27.403 30 26.6667L30 13.3333C30 12.597 30.5254 12 31.5 12C32.4746 12 33 12.597 33 13.3333L33 26.6667C33 27.403 32.4746 28 31.5 28Z"
        fill={props.fill}
      />
    </svg>
  );
};
export default ToEnd;
