
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageLoader from '../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import { UserClaimsContext } from '../../systemComponents/sharedControls/contexts/UserClaimsContext';

const LoadingPage: React.FC = () => {
  const { userClaims } = useContext(UserClaimsContext);
  const navigate = useNavigate();

  if (userClaims!.user) {
    navigate('/');
  }

  useEffect(() => {
    if (userClaims!.loaded) {
      navigate('/');
    }
  }, [userClaims!.user, userClaims!.loaded]);

  return <PageLoader alt="Loading" />;
};

export default LoadingPage;
