import React from 'react';
import './Footer.css';
import { FooterProps } from './Footer.types';

export const LexxicFooter: React.FC<FooterProps> = (props) => {
  return (
    <div className="App-Footer">
      <div className='Text-Regular'>{props.title}</div>
      <div className="footer__imageContainer">
        <img src={props.image} className='logo' alt="Lexxic Logo" />
      </div>
      <div className='Text-Regular'>{props.address}</div>
    </div>
  );
};
export default LexxicFooter;
