import React from 'react';
import CommonPageContext from '../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { ControlState } from '../../sysObjects/common.types';
const TestPage = () => {
  const context = React.useContext(CommonPageContext);

  React.useEffect(() => {
    context?.handleCrumbUpdate([{ label: 'Test Page', link: '/test', key: 'test' }]);
    const alertTypes: ControlState[] = [
      'positive',
      'negative',
      'neutral',
      'warning',
      'locked',
    ];
    let id = 0;
    const getRandomAlertType = () => {
      if (id >= alertTypes.length) {
        id = 0;
      }
      const item = alertTypes[id];
      id = id + 1;
      return item;
    };

    const timer = setInterval(() => {
        context?.handleMessage({
            alertType: getRandomAlertType(),
            message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco',
        });

    }, 17000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <h1>Test Page</h1>
    </div>
  );
};

export default TestPage;
