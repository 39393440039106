import React from 'react';
import ProgressSideBarTypes from './ProgressSideBar.types';

import './ProgressSideBar.css';
const SideBar: React.FC<ProgressSideBarTypes.Props> = (props) => {
  const defaultBoxState = props.boxState || 'neutral';

  return (
    <div className="Progress-Side-Bar" style={props.style} aria-hidden="true" >
      <div className={`thin-bar ${defaultBoxState}`} aria-hidden="true"></div>

      {props.location !== 'none' && (
        <div
          aria-hidden="true"
          className={`square-bar ${defaultBoxState} ${props.location}`}
        >
          <label aria-hidden="true" className="fill">
            {props.boxState === 'positive'
              ? '+'
              : props.boxState === 'negative'
              ? '!'
              : props.boxState === 'warning'
              ? '-'
              : ''}
          </label>
        </div>
      )}
    </div>
  );
};

export default SideBar;
