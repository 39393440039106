import React from 'react';
import DurationTypes from './Duration.types';

import './Duration.css';

const Duration: React.FC<DurationTypes.Props> = (props) => {
  const hours = Array.from(Array(24).keys()).map(
    (i) => `${i.toString().padStart(2, '0')}`
  );

  const [minutes, setMinutes] = React.useState<string[]>([]);

  React.useEffect(() => {
    const increment = props.increment || 5;
    setMinutes(
      Array.from(Array(60 / increment).keys()).map(
        (i) => `${(i * increment).toString().padStart(2, '0')}`
      )
    );
  }, []);
  const breakDuration = (input?: string | null) => {
    if (!input || input === undefined || input === null) {
      return { hours: '', minutes: '' };
    }

    let [hours, minutes] = input ? input.split(':') : ['', ''];
    hours = hours || '';
    minutes = minutes || '';
    return { hours, minutes };
  };

  const [hour, setHour] = React.useState<string>('');
  const [minute, setMinute] = React.useState<string>('');

  const validateValue = () => {
    if (props.hidden !== undefined && props.hidden) {
      return true;
    }
    if (props.readonly !== undefined && props.readonly) {
      return true;
    }
    if (!props.requiredDetails) {
      if (hour === '' || (hour === null && parseInt(hour) < 24)) {
        return false;
      }

      if (minute === '' || (minute === null && parseInt(minute) < 60)) {
        return false;
      }
    }
    return true;
  };

  const handleInputChange = (fieldName: string, fieldValue: string) => {
    if (props.readonly) {
      return;
    }

    let hours = hour;
    let minutes = minute;
    let isValid = false;

    if (fieldName === 'hours') {
      isValid = !(
        fieldValue === '' ||
        (fieldValue === null && parseInt(fieldValue) < 24)
      );
      if (isValid) {
        hours = fieldValue.padStart(2, '0');
        setHour(hours);
      }
    }
    if (fieldName === 'minutes') {
      isValid = !(
        fieldValue === '' ||
        (fieldValue === null && parseInt(fieldValue) < 60)
      );
      if (isValid) {
        minutes = fieldValue.padStart(2, '0');
        setMinute(minutes);
      }
    }

    if (isValid) {
      isValid =
        parseInt(hours) * 60 + parseInt(minutes) >= props.smallestTimeInMinutes;
    }

    if (validateValue()) {
      if (props.onChange) {
        props.onChange({
          id: props.id,
          value: {
            fireResult: isValid,
            isValid: isValid,
            time: `${hours}:${minutes}:00`,
          },
          fieldId: props.fieldId,
        });
      }
    }
  };

  React.useEffect(() => {
    const { hours, minutes } = breakDuration(props.value);
    setHour(hours);
    setMinute(minutes);
  }, [props.value]);

  return (
    <div className="date-box" key={props.id}>
      <div className="name">{props.label}</div>
      <div className="date-div duration-hours">
        <label htmlFor="hours" className="date-font">
          {props.labels.hours}
        </label>
        <select
          role="combobox"
          id="hours"
          onChange={(e) => handleInputChange('hours', e.target.value)}
          value={hour}
        >
          {hours.map((h) => (
            <option key={h} value={h}>
              {h}
            </option>
          ))}
        </select>
      </div>

      <div className="date-div duration-minutes">
        <label htmlFor="minutes" className="date-font">
          {props.labels.minutes}
        </label>
        <select
          role="combobox"
          onChange={(e) => handleInputChange('minutes', e.target.value)}
          id="minutes"
          value={minute}
        >
          {minutes.map((m) => (
            <option key={m} value={m}>
              {m}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Duration;
