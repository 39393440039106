import { AssessmentAppointmentTypes } from '../../sysObjects/apiModels/AssessmentAppointments.types';
import settings from '../../config/services.json';
import { getHostConfigAsync } from '../common/HostConfigActions';
import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import { createAction, fetchAction, updateAction } from '../common/APIHelper';
import System_Types from '../../sysObjects/apiModels/System.types';
namespace AssessmentAppointmentsActions {
  /**
   * Saves the record to the database
   * @param obj -The record to save.
   * @param { FunctionalResult<System_Types.ApiHeader> } headerInfo - The headers for the request
   * @returns The result of the action
   */
  export const saveRecordAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    obj: AssessmentAppointmentTypes.Definition,
    id?: string
  ): Promise<FunctionalResult<void | string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    if (id) {
      return updateAction({
        hostPath: host!.result!.path,
        apiPath: `${settings.AssessmentAppointmentDefinitions.update
          .path!}/${id}`,
        userId: headerInfo.result.userId,
        formData: obj,
        accessToken: headerInfo.result.token,
      });
    }
    return createAction({
      hostPath: host!.result!.path,
      apiPath: settings.AssessmentAppointmentDefinitions.create.path!,
      userId: headerInfo.result.userId,
      formData: obj,
      accessToken: headerInfo.result.token,
    });
  };

  /**
   * finds the obj with the given id.
   * @param { string } orgId The of top org find the related orgs
   * @param { FunctionalResult<System_Types.ApiHeader> } headerInfo - The headers for the request
   * @returns The result of the action
   */
  export const getByIDAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    id: string
  ): Promise<FunctionalResult<AssessmentAppointmentTypes.Definition>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.AssessmentAppointmentDefinitions.get.path!}/${id}`,
      userId: headerInfo.result.userId,
      accessToken: headerInfo.result.token,
    });
  };

  export const createDefaultDefinitionAsync =
    (): AssessmentAppointmentTypes.Definition => {
      return {
        serviceDefinitionId: '',
        msBookingsBusinessId: '',
        ignorePersonalAppointments: false,
        appointmentRequiresReport: true,
        msBookingsServiceName: '',
        msBookingsServiceId: '',
        bufferEnd: '00:30:00',
        bufferStart: '00:30:00',
        duration: '00:30:00',
      };
    };
}

export default AssessmentAppointmentsActions;
