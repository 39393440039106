export enum LogLevel {
  Error,
  Warning,
  Info,
  Verbose,
  Trace,
}

const recordLog = true;
const currentLevel = LogLevel.Error;

export const SystemLogger = (level: LogLevel, message: any) => {
  if (!recordLog) {
    return;
  }

  if (level <= currentLevel) {
    return;
  }
  switch (level) {
    case LogLevel.Error:
      console.error(message);
      break;
    case LogLevel.Info:
      console.info(message);
      break;
    case LogLevel.Verbose:
      console.debug(message);
      break;
    case LogLevel.Warning:
      console.warn(message);
      break;
    default:
      break;
  }
};
