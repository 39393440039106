import React, { FC } from 'react';
import { SideBarProps } from './SideBar.types';

import './SideBar.css';

const SideBar: FC<SideBarProps> = (props) => {
  const defaultBoxState = props.boxState || 'neutral';

  return (
    <div
      className={`side-panel  ${
        props.includeInnerBar  ? 'neutral' : defaultBoxState
      }`}
      aria-hidden="true"
    >
      {props.includeInnerBar && (
        <div className={`side-panel-inner ${defaultBoxState}`}></div>
      )}
    </div>
  );
};

export default SideBar;
