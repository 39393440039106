import React from 'react';
import { SystemUserClaims } from '../sharedControls/contexts/UserClaimsContextType.types';
import { UserClaimsContext } from '../sharedControls/contexts/UserClaimsContext';


const UserClaimsConsumer: React.FC<{ children: (userClaims: SystemUserClaims | null) => React.ReactNode }> = ({ children }) => {
  const { userClaims } = React.useContext(UserClaimsContext);
  return <>{children(userClaims)}</>;
};

export default UserClaimsConsumer;
