import React from 'react';
import SVGTypes from './svg.types';

export const Warning: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
        fill={props.fill}
        fillOpacity="0.0001"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7319 8.5C20.9621 7.16667 19.0376 7.16667 18.2678 8.5L6.14349 29.5C5.37369 30.8333 6.33594 32.5 7.87554 32.5H32.1243C33.6639 32.5 34.6261 30.8333 33.8563 29.5L21.7319 8.5ZM18.1958 15.5C18.1692 15.2477 18.1959 14.9926 18.2742 14.7513C18.3526 14.5099 18.4807 14.2878 18.6505 14.0992C18.8202 13.9106 19.0277 13.7598 19.2595 13.6566C19.4912 13.5533 19.7421 13.5 19.9958 13.5C20.2496 13.5 20.5004 13.5533 20.7322 13.6566C20.964 13.7598 21.1715 13.9106 21.3412 14.0992C21.5109 14.2878 21.6391 14.5099 21.7174 14.7513C21.7958 14.9926 21.8225 15.2477 21.7958 15.5L21.0958 22.514C21.0674 22.7859 20.9392 23.0377 20.7361 23.2207C20.533 23.4037 20.2692 23.505 19.9958 23.505C19.7224 23.505 19.4587 23.4037 19.2556 23.2207C19.0524 23.0377 18.9243 22.7859 18.8958 22.514L18.1958 15.5ZM17.9998 27.51C17.9998 27.2474 18.0516 26.9873 18.1521 26.7446C18.2526 26.502 18.3999 26.2815 18.5856 26.0958C18.7713 25.9101 18.9918 25.7628 19.2345 25.6622C19.4771 25.5617 19.7372 25.51 19.9998 25.51C20.2625 25.51 20.5226 25.5617 20.7652 25.6622C21.0079 25.7628 21.2283 25.9101 21.414 26.0958C21.5998 26.2815 21.7471 26.502 21.8476 26.7446C21.9481 26.9873 21.9998 27.2474 21.9998 27.51C21.9998 28.0404 21.7891 28.5491 21.414 28.9242C21.039 29.2993 20.5303 29.51 19.9998 29.51C19.4694 29.51 18.9607 29.2993 18.5856 28.9242C18.2105 28.5491 17.9998 28.0404 17.9998 27.51Z"
        fill={props.fill}
      />
    </svg>
  );
};
export default Warning;
