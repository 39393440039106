import React from 'react';
import StatusLabelTypes from './StatusLabel.types';

import './StatusLabel.css';

const StatusLabel: React.FC<StatusLabelTypes.Props> = (props) => {
  return (
    <>
      {props.renderAs === 'span' ? (
        <span
          className={`Status-Label span Impact-Strong ${
            props.status === undefined ? '' : props.status
          } `}
        >
          {props.label}
        </span>
      ) : (
        <div className={`Status-Label div Impact-Strong ${props.status}`}>{props.label}</div>
      )}
    </>
  );
};

export default StatusLabel;
