import React from 'react';
import PageSelectorTypes from './PageSelector.types';
import './PageSelector.css';
import { SvgBack, SvgForward, SvgToEnd, SvgToStart } from '../../../svg';
import { pagingDirection } from '../../../../../sysObjects/common.types';

const PageSelector: React.FC<PageSelectorTypes.Props> = (props) => {
  const handleClick = (progress: boolean, step: pagingDirection) => {
    if (!progress || !props.pageChangeClick) {
      return;
    }

    props.pageChangeClick(step);
  };

  return (
    <div className="paging-details-container">
      <div className="page-details-container Text-Regular">
        {`${props.from} - ${props.to} ${props.ofLabel} ${props.total}`}
      </div>
      <div className="page-steps-container">
        <SvgToStart
          testId="to-start"
          className={`icon ${props.from > 1 ? '' : 'disabled'}`}
          onClick={() => handleClick(props.from > 0, 'Start')}
        />
        <SvgBack
          testId="back"
          className={`icon ${props.from > 1 ? '' : 'disabled'}`}
          onClick={() => handleClick(props.from > 0, 'Back')}
        />
        <SvgForward
          testId="forward"
          className={`icon ${props.to < props.total ? '' : 'disabled'}`}
          onClick={() => handleClick(props.to <= props.total, 'Forward')}
        />
        <SvgToEnd
          testId="to-end"
          className={`icon ${props.to < props.total ? '' : 'disabled'}`}
          onClick={() => handleClick(props.to < props.total, 'End')}
        />
      </div>
    </div>
  );
};

export default PageSelector;
