import React from 'react';
import FormCheckboxListTypes from './FormCheckboxList.types';
import FormControllerContainer from '../formControlContainer/FormControlContainer';
import { ControlState } from '../../../../sysObjects/common.types';
import { FormControlRef } from '../formControlContainer/FormControlContainer.types';
import './FormCheckboxList.css';


const FormCheckboxList: React.ForwardRefRenderFunction<
  FormControlRef,
  FormCheckboxListTypes.Props
> = (props, ref) => {
  const [currentBoxState, setBoxState] =
    React.useState<ControlState>('neutral');
  const [errorString, setErrorString] = React.useState<string>('');

  React.useImperativeHandle(ref, () => ({
    triggerValidation: () => validateValue(),
    reset: () => setBoxState('neutral'),
  }));

  React.useEffect(() => {}, [props.value]);

  const handleChange = (key: string | number, checked: boolean) => {
    if (props.readonly) {
      return;
    }
    let returnValue: any;
    if (props.allowManySelect) {
      returnValue = Array.isArray(props.value) ? [...props.value] : [];
      if (checked && !returnValue.includes(key)) {
        returnValue.push(key);
      } else {
        returnValue = (props.value as any).filter((i: any) => i !== key);
      }
    } else {
      returnValue = checked ? key : null;
    }
    validateValue();
    props.onChange &&
      props.onChange({
        id: props.id,
        value: returnValue,
        fieldId: props.fieldId,
      });
  };

  const validateValue = () => {
    if (props.hidden !== undefined && props.hidden) {
      return true;
    }
    if (props.readonly !== undefined && props.readonly) {
      return true;
    }
    if (props.value === null || props.value === undefined) {
      setBoxState('negative');
      setErrorString(
        props.requiredDetails?.message || 'Please select at least one option'
      );
      return false;
    }

    if (
      props.requiredDetails &&
      props.allowManySelect &&
      (props.value as any[]).length === 0
    ) {
      setBoxState('negative');
      setErrorString(
        props.requiredDetails?.message! || 'Please select at least one option'
      );
      return false;
    }
    setBoxState('positive');
    setErrorString('');
    return true;
  };

  return (
    <FormControllerContainer
      id={props.id}
      boxState={currentBoxState}
      label={props.label}
      hidden={props.hidden}
      requiredText={props.requiredDetails?.formLabel}
      requiredDetails={props.requiredDetails}
      displayMode={props.displayMode}
      errorMessage={errorString}
      readonly={props.readonly}
    >
      <fieldset className="checkBox-list">
        <legend>{props.helpMessage}</legend>
        {props.items?.map((item: any) => (
          <label
            key={`${props.id}_${item.key}`}
            className="check-label Text-Regular"
            htmlFor={`${props.id}_${item.key}`}
          >
            <input
              id={`${props.id}_${item.key}`}
              data-testid={`${props.id}_${item.key}`}
              onBlur={validateValue}
              type="checkbox"
              name={`${props.id}_${item.key}`}
              value={item.value}
              checked={
                !!props.value &&
                (props.allowManySelect
                  ? Array.isArray(props.value) &&
                    (props.value as any[]).includes(item.key)
                  : item.key === props.value)
              }
              readOnly={props.readonly}
              onChange={(e) => handleChange(item.key, e.target.checked)}
            />
            {item.value}
          </label>
        ))}
      </fieldset>
    </FormControllerContainer>
  );
};

export default React.forwardRef(FormCheckboxList);
