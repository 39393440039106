import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import { fetchAction, createAction, updateAction } from '../../systemUtils/common/APIHelper';
import settings from '../../config/services.json';
import { getHostConfigAsync } from '../common/HostConfigActions';
import SlaDefinition_Types from '../../sysObjects/apiModels/SlaDefinition.types';
import System_Types from '../../sysObjects/apiModels/System.types';

namespace SlaDefinitionActions {
  /**
   * Finds the object based on the lookup criteria.
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header information containing the user and access token.
   * @param {SlaDefinition_Types.findQuery} obj The Query object to search for.
   * @returns
   */
  export const findAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    obj: SlaDefinition_Types.findQuery
  ): Promise<FunctionalResult<SlaDefinition_Types.listItem[]>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.SlaDefinitions.find.path}/${obj.orgId}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  /**
   * Saves the object.
   * @param {FunctionalResult<System_Types.ApiHeader>} headerInfo The header information containing the user and access token.
   * @param {SlaDefinition_Types.slaDefinitionItem} The record to save.
   * @returns The result of the action
   */
  export const saveAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    obj: SlaDefinition_Types.slaDefinitionItem,
    id?: string
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (!id) {
      return createAction({
        hostPath: host!.result!.path,
        apiPath: settings.SlaDefinitions.create.path!,
        userId: headerInfo.result!.userId,
        formData: obj,
        accessToken: headerInfo.result!.token,
      });
    }
    return updateAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.SlaDefinitions.update.path!}/${id}`,
      userId: headerInfo.result!.userId,
      formData: obj,
      accessToken: headerInfo.result!.token,
    });
  };

  export const getAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    id: string
  ): Promise<FunctionalResult<SlaDefinition_Types.slaDefinitionItem>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.SlaDefinitions.get.path}/${id}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  /**
   * Creates an empty SLA definition object for the UX to bind to.
   * @returns A default SLA definition object.
   */
  export const createDefault = (billingId: string): SlaDefinition_Types.slaDefinitionItem => {
    return {
      name: '',
      description: '',
      billingDetailsId: billingId,
      serviceDefinitionId: undefined,
      duration: undefined,
      startStatus: undefined,
      endStatus: undefined,
      startServiceStatus: undefined,
      endServiceStatus: undefined,
    };
  };
}

export default SlaDefinitionActions;
