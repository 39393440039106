import React from 'react';
import SVGTypes from './svg.types';

export const Open: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
        fill={props.fill}
        fillOpacity="0.0001"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2937 9.24856C13.3866 9.16977 13.4969 9.10726 13.6184 9.06461C13.7398 9.02195 13.87 9 14.0015 9C14.133 9 14.2632 9.02195 14.3847 9.06461C14.5062 9.10726 14.6165 9.16977 14.7094 9.24856L26.7063 19.4012C26.7994 19.4798 26.8733 19.5732 26.9237 19.676C26.9741 19.7787 27 19.8889 27 20.0002C27 20.1115 26.9741 20.2217 26.9237 20.3245C26.8733 20.4273 26.7994 20.5206 26.7063 20.5992L14.7094 30.7519C14.5216 30.9108 14.267 31 14.0015 31C13.736 31 13.4814 30.9108 13.2937 30.7519C13.106 30.593 13.0005 30.3775 13.0005 30.1529C13.0005 29.9282 13.106 29.7127 13.2937 29.5539L24.5848 20.0002L13.2937 10.4466C13.2006 10.368 13.1267 10.2746 13.0763 10.1718C13.0259 10.069 13 9.95885 13 9.84757C13 9.73628 13.0259 9.62609 13.0763 9.5233C13.1267 9.42052 13.2006 9.32715 13.2937 9.24856Z"
        fill={props.fill}
      />
    </svg>
  );
};
export default Open;
