import React from 'react';
import { EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import systemUserActions from '../../systemUtils/userUtils/SystemUserActions';
import { IUserClaims } from '../../sysObjects/UserClaims.types';
import useUserClaims from '../sharedControls/authentication/UserClaimsState';
import {UserClaimsContext } from '../sharedControls/contexts/UserClaimsContext';
import { handleLogout } from '../../systemUtils/common/CommonHelpers';
export const UserClaimsProvider: React.FC<{ children: React.ReactNode, msalInitialised: boolean }> = ({
  children,
  msalInitialised
}) => {
  const claimsStorage = React.useRef<boolean>(false);
  const claimsInitialised = React.useRef<boolean>(false);
  const { instance } = useMsal();
  const { userClaims, initialisationComplete, loadClaimsAsync, removeClaims, saveClaims } = useUserClaims(systemUserActions.retrieveUserClaims);

  React.useEffect(() => {
    if(!msalInitialised){
      return; 
    }

    if(!claimsInitialised.current){
      claimsInitialised.current = true;
      handleRedirectAsync();

      instance.addEventCallback((event: any) => {
        if (event.eventType === EventType.LOGOUT_SUCCESS ||
          event.eventType === EventType.ACCOUNT_REMOVED ||
          event.eventType === EventType.ACTIVE_ACCOUNT_CHANGED ||
          event.eventType === EventType.LOGOUT_END) {
          removeClaims();
        }
      });
    }

    const account = instance.getActiveAccount();

    if (account === null) {
      return;
    }

    loadClaimsAsync(account).then(async (result) => {
      if (!result) {
        await handleLogout(instance);
      }
    });
  }, [instance, msalInitialised]);

  /**
   * Handles the redirect asynchronously and performs various checks and actions based on the token response.
   */
  const handleRedirectAsync = async () => {
    instance
      .handleRedirectPromise()
      .then(async (tokenResponse) => {
        // If the tokenResponse === null, you are not coming back from an auth redirect.
        if (
          tokenResponse !== null &&
          !claimsStorage.current &&
          !userClaims.user
        ) {
          claimsStorage.current = true;
          let account = instance.getActiveAccount()!;

          if (
            account.idTokenClaims === undefined ||
            account.idTokenClaims === null
          ) {
            return;
          }

          loadClaimsAsync(account);
        }
      })
  };

  const updateUserClaims = (updatedClaims: IUserClaims) => {
    saveClaims(updatedClaims);
  };

  return (
    <UserClaimsContext.Provider
      value={{ userClaims, initialisationComplete, updateUserClaims }}
    >
      {children}
    </UserClaimsContext.Provider>
  );
};
