import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import UserCore from '../../systemUtils/userUtils/SystemUserActions';
import assessmentTypes from '../../sysObjects/apiModels/AssessmentAppointments.types';
import actions from '../../systemUtils/appointment/AssessmentAppointmentsActions';
import ServiceDefinitionActions from '../../systemUtils/services/ServiceDefinitionActions';
import {
  getHeadersAsync,
  getServerErrors,
} from '../../systemUtils/common/CommonHelpers';
import BookingTypes from '../../sysObjects/apiModels/Booking.Types';
import BookingRepoActions from '../../systemUtils/booking/BookingRepoActions';
import { useMsal } from '@azure/msal-react';
import { UserClaimsContext } from '../../systemComponents/sharedControls/contexts/UserClaimsContext';
import CommonPageContext from '../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import {
  FormControlOnChangeData,
  FormControlRef,
} from '../../systemComponents/sharedControls/formControls/formControlContainer/FormControlContainer.types';
import { ControlState, KeyValuePair } from '../../sysObjects/common.types';
import PageLoader from '../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import PillControl from '../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import FormDropDown from '../../systemComponents/sharedControls/formControls/formDropDown/FormDropDown';
import FormTextCapture from '../../systemComponents/sharedControls/formControls/formTextCapture/FormTextCapture';
import FormCheckbox from '../../systemComponents/sharedControls/formControls/formCheckbox/FormCheckbox';
import InformationButton from '../../systemComponents/sharedControls/general/InformationButton/InformationButton';

const AssessmentAppointmentsDefinitionCreateUpdate: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { id, serviceId } = useParams();
  const { instance } = useMsal();
  const context = React.useContext(CommonPageContext);

  const formRefs = [
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
  ];

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [pageStates, setPageState] = React.useState<KeyValuePair<string>[]>([
    {
      key: locales.controls.pages[0],
      value: 'neutral',
    },
  ]);

  const [bookingList, setBookingList] = React.useState<BookingTypes.Service[]>(
    [],
  );

  const [bookingServices, setBookingServices] = React.useState<
    KeyValuePair<string>[]
  >([]);
  const [services, setServices] = React.useState<KeyValuePair<string>[]>([]);
  const [formData, setFormData] = React.useState<assessmentTypes.Definition>(
    actions.createDefaultDefinitionAsync(),
  );

  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    if (result.fieldId === 'cancellationPolicyPermittedTime') {
      setFormData((prevData) => ({
        ...prevData,
        [result.fieldId]: parseInt(result.value as string),
      }));
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));
  };

  const handleBookingChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    const booking = bookingList.find((b) => b.serviceId === result.value);

    if (!booking) {
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      msBookingsBusinessId: booking.bookingsContainerId,
      msBookingsServiceId: booking.serviceId,
      msBookingsServiceName: booking.serviceName,
      bufferEnd: booking.postBuffer,
      duration: booking.defaultDuration,
      bufferStart: booking.preBuffer,
    }));
  };

  const handleCheckFormChange = (result: FormControlOnChangeData<boolean>) => {
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));
  };

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const loadDataAsync = async () => {
    const headers = await getHeadersAsync(userClaims, instance);
    if (id) {
      actions
        .getByIDAsync(
          headers,
          id
        )
        .then((result) => {
          if (result.isFailure) {
            showMessage(
              locales.errorDetails.loadingFailed,
              'negative',
              '/services',
            );
            return;
          }
          setIsLoading(false);
          context?.handleCrumbUpdate(
            [...locales.breadcrumbs]
              .slice(0, locales.breadcrumbs.length - 1)
              .concat({
                label: locales.editLabel,
                key: id,
              }),
          );
          setFormData(result.result!);
        })
        .catch(() => {
          showMessage(
            locales.errorDetails.loadingFailed,
            'negative',
            '/services',
          );
        });
    } else {
      context?.handleCrumbUpdate([...locales.breadcrumbs]);
      setIsLoading(false);
    }

    ServiceDefinitionActions.fetchServiceDefinitions(
      headers,
      intl.locale
    ).then((rst) => {
      if (rst.isFailure) {
        showMessage(
          locales.errorDetails.loadingFailed,
          'negative',
          '/services',
        );
        return;
      }
      setServices(rst.result!);
      setIsLoading(false);
    });

    BookingRepoActions.findBookableServicesAsync(
      headers
    )
      .then((result) => {
        if (result.isFailure) {
          showMessage(
            locales.errorDetails.loadingFailed,
            'negative',
            '/services',
          );
          return;
        }

        setBookingServices(
          result.result!.map((service) => {
            return { key: service.serviceId, value: service.serviceName };
          }),
        );
        setBookingList(result.result!);
      })
      .catch(() => {
        showMessage(
          locales.errorDetails.loadingFailed,
          'negative',
          '/services',
        );
      });
  };

  React.useEffect(() => {
    context?.handleCrumbUpdate(
      [...locales.breadcrumbs].slice(0, locales.breadcrumbs.length - 1)
    );
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      showMessage(locales.errorDetails.permissionError, 'negative', '/');
      return;
    }

    if (!serviceId) {
      showMessage(locales.errorDetails.invalidID, 'negative', '/services');
      return;
    }

    formData.serviceDefinitionId = serviceId;
    loadDataAsync();
  }, []);

  const save = async () => {
    const validationResults = formRefs.map((innerRef) =>
      innerRef.current?.triggerValidation(),
    );
    const tabValid = validationResults.every((result) => result === true);
    if (!tabValid) {
      setPageState((prevPages) => {
        const updatedPages = [...prevPages];
        updatedPages[0] = {
          ...updatedPages[0],
          value: tabValid ? 'positive' : 'negative',
        };
        return updatedPages;
      });
      return;
    }
    actions
      .saveRecordAsync(
        await getHeadersAsync(userClaims, instance),
        formData,
        id,
      )
      .then((result) => {
        if (result.isFailure) {
          showMessage(getServerErrors(locales, result.errorCode), 'negative');
          return;
        }
        showMessage(
          locales.successDetails.saveSuccess,
          'positive',
          '/services',
        );
      })
      .catch(() => {
        showMessage(locales.errorDetails.saveFailed, 'negative');
      });
  };

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <PillControl
        pages={[
          {
            mode: pageStates[0].value as ControlState,
            name: pageStates[0].key,
            enabled: true,
            orderNo: 1,
            showAsOrdered: true,
            content: (
              <>
                <FormDropDown
                  displayMode="Box"
                  id="serviceDefinitionId"
                  fieldId="serviceDefinitionId"
                  items={services}
                  label={locales.controls.serviceId}
                  defaultText={locales.common.select_Default}
                  value={formData.serviceDefinitionId}
                  onChange={handleFormChange}
                  ref={formRefs[0]}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.serviceId} ${locales.common.requiredMessage}`,
                  }}
                  readonly={true}
                />
                <FormDropDown
                  displayMode="Box"
                  id="msBookingsServiceId"
                  fieldId="msBookingsServiceId"
                  items={bookingServices}
                  label={locales.controls.serviceName}
                  defaultText={locales.common.select_Default}
                  value={formData.msBookingsServiceId}
                  onChange={handleBookingChange}
                  ref={formRefs[1]}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.serviceName} ${locales.common.requiredMessage}`,
                  }}
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="duration"
                  id="duration"
                  label={locales.controls.duration}
                  value={formData.duration.toString()}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.duration} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[2]}
                  textInputType="text"
                  helpMessage="Set by selecting an MS Bookings service"
                  readonly={true}
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="bufferStart"
                  id="bufferStart"
                  label={locales.controls.bufferStart}
                  value={formData.bufferStart}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.bufferStart} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[3]}
                  textInputType="text"
                  helpMessage="Set by selecting an MS Bookings service"
                  readonly={true}
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="bufferEnd"
                  id="bufferEnd"
                  label={locales.controls.bufferEnd}
                  value={formData.bufferEnd}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.bufferEnd} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[4]}
                  textInputType="text"
                  helpMessage="Set by selecting an MS Bookings service"
                  readonly={true}
                />
                <FormCheckbox
                  displayMode="Box"
                  fieldId="ignorePersonalAppointments"
                  id="ignorePersonalAppointments"
                  label={locales.controls.ignorePersonalAppointments}
                  value={formData.ignorePersonalAppointments}
                  onChange={handleCheckFormChange}
                  ref={formRefs[5]}
                />
                <FormCheckbox
                  displayMode="Box"
                  fieldId="appointmentRequiresReport"
                  id="appointmentRequiresReport"
                  label={locales.controls.appointmentRequiresReport}
                  value={formData.appointmentRequiresReport}
                  onChange={handleCheckFormChange}
                  ref={formRefs[6]}
                />
              </>
            ),
          },
        ]}
        key={'Organisation'}
      />
      <InformationButton
        key="informationSave"
        buttonDetails={{
          itemKey: 'informationSave',
          clickEvent: () => save(),
          label: locales.controls.saveButton,
          mode: 'positive',
        }}
      >
        <>{locales.controls.summary}</>
      </InformationButton>
    </div>
  );
};

export default AssessmentAppointmentsDefinitionCreateUpdate;
