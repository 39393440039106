import React from 'react';
import SVGTypes from './svg.types';

export const DarkMode: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19_78470)">
        <path
          d="M18.25 9.38132C18.3842 9.54441 18.4664 9.744 18.4861 9.95431C18.5057 10.1646 18.4618 10.376 18.36 10.5611C17.5651 12.0206 17.1499 13.6566 17.1528 15.3186C17.1528 20.8474 21.66 25.3244 27.215 25.3244C27.9396 25.3244 28.645 25.2488 29.3229 25.1044C29.5307 25.0594 29.7471 25.0767 29.9452 25.1539C30.1433 25.2312 30.3142 25.3651 30.4366 25.5389C30.5659 25.7197 30.6317 25.9381 30.624 26.1601C30.6163 26.3822 30.5355 26.5955 30.394 26.7668C29.3156 28.0915 27.9551 29.1589 26.4118 29.8911C24.8685 30.6232 23.1812 31.0018 21.473 30.9991C15.1342 30.9991 10 25.8923 10 19.6003C10 14.8648 12.9068 10.8031 17.0455 9.08157C17.2517 8.99444 17.4805 8.97655 17.6977 9.0306C17.9149 9.08466 18.1087 9.20772 18.25 9.38132V9.38132ZM16.6797 10.8017C15.0883 11.6553 13.7579 12.9242 12.8299 14.4734C11.9019 16.0227 11.411 17.7944 11.4094 19.6003C11.4094 25.1278 15.918 29.6048 21.473 29.6048C22.8032 29.6071 24.1207 29.3456 25.3492 28.8353C26.5777 28.325 27.6927 27.5762 28.6299 26.6321C28.1665 26.6898 27.6949 26.7187 27.215 26.7187C20.8763 26.7187 15.7434 21.6119 15.7434 15.3199C15.7434 13.7153 16.0761 12.1877 16.6797 10.8017V10.8017Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_19_78470">
          <path
            d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
            fill={props.fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DarkMode;
