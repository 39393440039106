import React, { useState } from 'react';
import { getTimeSince } from '../../../../../systemUtils/common/CommonHelpers';
import { SvgClock } from '../../../../sharedControls/svg';
import InformationButton from '../../../../sharedControls/general/InformationButton/InformationButton';
import StatusBlock from '../../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import Expander from '../../../../sharedControls/general/expander/Expander';
import CaseNoteRowTypes from './CaseNoteRow.types';

const CaseNoteRow: React.FC<CaseNoteRowTypes.CaseNoteRowProps> = (props) => {
  const [isExpanded, setExpandedState] = useState<Boolean>(
    props.item.isExpanded,
  );

  const renderExpander = () => {
    return (
      <div>
        <Expander
          id={`${props.item.itemID}_Status`}
          key={`${props.item.itemID}_Status`}
          isExpanded={isExpanded}
          eventHandler={{ onClick: () => setExpandedState(!isExpanded) }}
        />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <>
        {renderExpander()}
        <div className="td Clipped-Text">{props.item.content}</div>
        <div className="td">
          <StatusBlock
            boxSize="small"
            boxState="positive"
            showIcon={false}
            allowHover={false}
            content={<SvgClock className='icon' />}
          />
          {props.item.modifiedDateTime
            ? getTimeSince(props.item.modifiedDateTime)
            : getTimeSince(props.item.createdDateTime)}
        </div>
        <div className="td">
          <StatusBlock
            boxSize="small"
            boxState="locked"
            showIcon={false}
            allowHover={false}
            className="Status-User-Int"
            content={props.item.createdByInitials}
          />
          {props.item.createdByName}
        </div>
        <div className="td">{props.item.documentName}</div>
      </>
    );
  };

  const renderExpandedRow = () => {
    return (
      <div className="Expanded-row">
        <div className="col Text-Regular">{props.item.content}</div>
        <div />
        <div className="col-buttons">
          {props.buttons &&
            props.buttons.map((button, index) => (
              <InformationButton
                key={`note_action_button_${index}`}
                buttonDetails={{
                  label: button.label,
                  itemKey: `note_action_button_${index}`,
                  mode: button.controlState,
                  clickEvent: () => {
                    if (button.onClick) {
                      button.onClick(props.item.itemID);
                    }
                  },
                }}
              >
                <>{button.description}</>
              </InformationButton>
            ))}
        </div>
      </div>
    );
  };

  const renderRow = () => {
    return (
      <>
        {renderHeader()}
        {isExpanded && renderExpandedRow()}
      </>
    );
  };

  return <div className="CaseNote-Item">{renderRow()}</div>;
};

export default CaseNoteRow;
