import settings from '../../config/services.json';
import System_Types from '../../sysObjects/apiModels/System.types';
import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import { confirmAction, updateAction } from '../../systemUtils/common/APIHelper';
import { getHostConfigAsync } from '../../systemUtils/common/HostConfigActions';
import BillableItem_Types  from '../../sysObjects/apiModels/BillableItem.types';
namespace BillableItemActions {
  /**
   * Creates an invoice for the billable items
   * @param headers - The headers to be sent with the request
   * @param caseId - The case ID
   * @param billableIds - The billable IDs
   */
  export const createInvoiceAsync = async (
    headers: FunctionalResult<System_Types.ApiHeader>,
    caseId: string,
    billableIds: string[]
  ): Promise<FunctionalResult<void>> => {
    if (headers.isFailure) {
      return FunctionalResult.Error('Failed to get headers');
    }
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return confirmAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.BillableItems.createInvoice.path}/${caseId}`,
      userId: headers.result!.userId,
      accessToken: headers.result!.token,
      formData: billableIds,
    });
  };

  /**
   * Modifys the pricing for a billable item, prior to invoicing.
   * @param headers - The headers to be sent with the request.
   * @param obj -The data to send in the request.
   * @returns The result of the action.
   */
    export const modifyBillableItemAsync = async (
      headers: FunctionalResult<System_Types.ApiHeader>,
      obj:  BillableItem_Types.ModifiedBillableItemPriceType
    ): Promise<FunctionalResult<void | string>> => {
      if (headers.isFailure) {
        return FunctionalResult.Error('Failed to get headers');
      }
      const host = await getHostConfigAsync();
      if (host.isFailure) {
        return FunctionalResult.Error('Failed to load host configuration');
      }
  
      return updateAction({
        hostPath: host!.result!.path,
        apiPath: settings.BillableItems.ModifyBillableItem.path,
        userId: headers.result!.userId,
        accessToken: headers.result!.token,
        formData: obj,
      });
    };
}

export default BillableItemActions;
