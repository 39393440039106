import React from 'react';
import { useIntl } from 'react-intl';
import actions from '../../../systemUtils/userUtils/InternalUserActions';
import { useNavigate } from 'react-router-dom';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import InternalUsers_IndexTypes from './InternalUsersIndex.types';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import {
  getHeadersAsync,
  LocalEnumerations,
} from '../../../systemUtils/common/CommonHelpers';
import InternalUser_Types from '../../../sysObjects/apiModels/InternalUser.types';

import './InternalUsersIndex.css';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import ButtonBoxTypes from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox.types';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import CommonTable from '../../../systemComponents/sharedControls/tables/commonTable/CommonTable';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import { ControlState } from '../../../sysObjects/common.types';

const InternalUsersIndex: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const context = React.useContext(CommonPageContext);
  const { userSettings } = useUserSettingsContext();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [reload, setReload] = React.useState<boolean>(true);

  const [intUsers, setIntUsers] = React.useState<
    InternalUsers_IndexTypes.rowData[]
  >([]);

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const DetermineUserButtons = (
    user: InternalUser_Types.ByRoleObj,
    checked?: boolean,
  ): ButtonBoxTypes.Button[] => {
    let buttons: ButtonBoxTypes.Button[] = [];

    if (user.status !== LocalEnumerations.UserStatuses.Disabled) {
      buttons.push({
        id: `edit-${user.id}`,
        label: locales.statusButtons.edit,
        controlState: 'positive',
        onClick: () => {
          navigate(
            `/internal/users/edit/${locales.roles[user.role]}/${user.id}`,
          );
        },
      });
    } else {
      buttons.push({
        id: `changeState-${user.id}`,
        controlState: 'positive',
        label: locales.statusButtons.enable,
        isChecked: checked,
        onClick: () => {
          updateStatus(user, LocalEnumerations.BasicEntityStatus.Active);
        },
      });
    }
    if (user.status !== LocalEnumerations.UserStatuses.Active) {
      return buttons;
    }

    buttons.push({
      id: `changeState-${user.id}`,
      controlState: 'warning',
      label: locales.statusButtons.disable,
      isChecked: checked,
      onClick: () => {
        setIntUsers((prevData) => {
          const newData = [...prevData];
          const index = newData.findIndex((item) => item.id === user.id);
          newData[index].buttonColumn = (
            <div className="InternalUser-Confirm-Box">
              <ButtonBox
                id={`user-${user.id}`}
                key={`user-${user.id}`}
                displayBorder={false}
                buttons={DetermineUserButtons(user, true)}
              />
              <div className="InternalUser-Confirm-Box-Text">
                <span>{locales.statusButtons.check}</span>
                <ButtonBox
                  id={`confirm-user-${user.id}-render`}
                  key={`confirm-user-${user.id}-render`}
                  displayBorder={true}
                  buttons={[
                    {
                      id: `confirm-user-${user.id}-yes`,
                      controlState: 'negative',
                      label: locales.statusButtons.confirm,
                      onClick: () => {
                        updateStatus(
                          user,
                          LocalEnumerations.BasicEntityStatus.Deactivated,
                        );
                      },
                    },
                    {
                      id: `confirm-user-${user.id}-no`,
                      controlState: 'positive',
                      label: locales.statusButtons.cancel,
                      onClick: () => {
                        setIntUsers((prevData) => {
                          const newData = [...prevData];
                          const index = newData.findIndex(
                            (item) => item.id === user.id,
                          );
                          newData[index].buttonColumn = (
                            <ButtonBox
                              id={`user-${user.id}-confirmation`}
                              key={`user-${user.id}-confirmation`}
                              displayBorder={false}
                              buttons={DetermineUserButtons(user, false)}
                            />
                          );
                          return newData;
                        });
                      },
                    },
                  ]}
                />
              </div>
            </div>
          );
          return newData;
        });
      },
    });

    return buttons;
  };

  const updateStatus = async (user: InternalUser_Types.ByRoleObj, state: number) => {
    actions
      .setUserStateAsync(await getHeadersAsync(userClaims, instance), user.id, state)
      .then((result) => {
        if (result.isFailure) {
          showMessage(locales.errorDetails.editState, 'negative');
          return;
        }
        setIsLoading(true);
        setReload(true);
      });
  };

  const loadDataAsync = async () => {
    actions
      .queryUsersAsync(await getHeadersAsync(userClaims, instance), {
        status: [
          LocalEnumerations.UserStatuses.Active,
          LocalEnumerations.UserStatuses.Invited,
          LocalEnumerations.UserStatuses.Disabled,
        ],
      })
      .then((result) => {
        setIsLoading(false);
        if (result.isFailure || !result.result) {
          showMessage(locales.errorDetails.loadingFailed, 'negative', '/');
          return;
        }

        setIntUsers(
          result.result.map((usr, index) => {
            return {
              id: usr.id,
              obj: usr,
              data: [
                `${usr.surname}, ${usr.name}`,
                locales.roles[usr.role],
                locales.statuses[usr.status || 0],
              ],
              buttonColumn: (
                <ButtonBox
                  id={`user-${usr.id}-update`}
                  key={`user-${usr.id}-update`}
                  displayBorder={false}
                  buttons={DetermineUserButtons(
                    usr,
                    usr.status === LocalEnumerations.UserStatuses.Active
                      ? false
                      : undefined,
                  )}
                />
              ),
            };
          }),
        );
      });
  };

  React.useEffect(() => {
    if (!reload) {
      return;
    }
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      navigate('/');
      return;
    }

    setReload(false);
    loadDataAsync();
  }, [reload]);

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <CommonTable
        className="InternalUser-Table"
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        headers={locales.colNames}
        rows={intUsers}
        labels={{
          of: locales.of,
          size: locales.size,
          tableName: locales.tableHeading,
          emptyMessage: locales.emptyMessage,
        }}
        key="fk"
      />
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'button-create-admin',
            label: locales.createAdmin.Button,
            mode: 'positive',
            clickEvent: () => navigate('/internal/users/create/admin'),
          }}
        >
          {locales.createAdmin.Summary}
        </InformationButton>
        <InformationButton
          buttonDetails={{
            itemKey: 'button-create-case',
            label: locales.createCase.Button,
            mode: 'positive',
            clickEvent: () => navigate('/internal/users/create/case'),
          }}
        >
          {locales.createCase.Summary}
        </InformationButton>
        <InformationButton
          buttonDetails={{
            itemKey: 'button-create-assessor',
            label: locales.createAssessor.Button,
            mode: 'positive',
            clickEvent: () => navigate('/internal/users/create/assessor'),
          }}
        >
          {locales.createAssessor.Summary}
        </InformationButton>
      </div>
    </div>
  );
};

export default InternalUsersIndex;
