import React from 'react';
import { BsAsterisk } from 'react-icons/bs';
import { FormControllerContainerProps } from './FormControlContainer.types';
import SideBar from '../../reusableBlocks/sideBar/SideBar';
import StatusBlock from '../../reusableBlocks/statusBlock/StatusBlock';
import { ControlState } from '../../../../sysObjects/common.types';

import './FormControlContainer.css';

const FormControllerContainer: React.FC<FormControllerContainerProps> = (
  props
) => {
  const [currentBoxState, setBoxState] = React.useState<ControlState>(
    props.boxState
  );

  React.useEffect(() => {
    setBoxState(props.boxState);
  }, [props.boxState]);

  const renderRequiredBar = () => {
    return (
      props.requiredText && (
        <div
          key={`container_required_${props.id}`}
          className="required-bar"
          data-testid={`required-bar-${props.id}`}
        >
          <BsAsterisk size={10} />
          <label className="Text-Understated">{props.requiredText}</label>
        </div>
      )
    );
  };

  const renderRequiredCol = () => {
    return (
      props.requiredText && (
        <span className="required-col Text-Understated">
          <BsAsterisk size={10} />
          {props.requiredText}
        </span>
      )
    );
  };

  const renderErrorMessage = () => {
    return (
      currentBoxState === 'negative' && (
        <div
          key={`container_error_${props.id}`}
          data-testid={`error-bar-${props.id}`}
          className="error-bar Text-Regular"
        >
          {props.errorMessage}
        </div>
      )
    );
  };

  const renderHelpMessage = () => {
    return props.helpMessage ? (
      <div
        key={`container_message_${props.id}`}
        className="help-bar Text-Regular"
      >
        {props.helpMessage}
      </div>
    ) : null;
  };

  return (
    <>
      {props.displayMode === 'Column' && (
        <div
          className={`Form-Control-Column-Container ${
            props.hidden === true ? 'lexxic-hidden' : ''
          }`}
        >
          <label className="Text-Strong" htmlFor={props.id}>
            {props.label}
          </label>
          {renderRequiredCol()}
        </div>
      )}
      <div
        data-testid={`form-control-container-${props.id}`}
        className={`form-control-container${
          props.displayMode === 'Bordered' ? ' Bordered' : ''
        }${props.displayMode === 'Box' ? ' Box-Border' : ''}${
          props.hidden === true ? ' lexxic-hidden' : ''
        }`}
      >
        {props.displayMode === 'Box' && (
          <SideBar
            boxState={currentBoxState}
            FieldKey={`container_${props.id}`}
            includeInnerBar={true}
          />
        )}
        <div
          className={`control-panel ${
            props.displayMode === 'Box' ? 'vBox' : ''
          }`}
        >
          {props.displayMode === 'Box' && (
            <div className="top-bar">
              <div className="label-bar">
                <label className="Text-Strong" htmlFor={props.id}>
                  {props.label}
                </label>
              </div>
              {renderRequiredBar()}
            </div>
          )}
          {props.displayMode === 'Bordered' && (
            <div className="top-bar">
              <div className="label-bar">
                <label className="Text-Strong" htmlFor={props.id}>
                  {props.label}
                </label>
              </div>
            </div>
          )}

          <div className="control-bar">
            <div className="control">{props.children}</div>
            <div className="Status-Holder">
              {props.insertPadder === true ? (
                <div className="Status-Padding"></div>
              ) : null}
              <StatusBlock
                boxSize="medium"
                id={props.id}
                boxState={
                  props.readonly === true ? 'positive' : currentBoxState
                }
                showIcon={true}
                readonly={props.readonly}
              />
            </div>
          </div>
          <div className="info-bar">
            {renderHelpMessage()}
            {renderErrorMessage()}
          </div>
        </div>
      </div>
    </>
  );
};

export default FormControllerContainer;
