import React from 'react';
import './Breadcrumbs.css';
import BreadCrumbBarTypes from './Breadcrumbs.types';
import Breadcrumb from './breadCrumbItem/Breadcrumb';

export const Breadcrumbs: React.FC<BreadCrumbBarTypes.Props> = (props) => {
  return (
    <nav className="breadcrumbs">
      <ol className="breadcrumb_list">
        {props.breadcrumbLinks
          ? props.breadcrumbLinks.map((breadcrumbLink, i) => (
              <Breadcrumb
                key={`breadcrumb_Item${i}`}
                id={breadcrumbLink.key}
                to={breadcrumbLink.link}
                labelText={breadcrumbLink.label}
                onClick={props.onClick}
              ></Breadcrumb>
            ))
          : null}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
