import React from 'react';
import { TaskItemProps } from './TaskItem.types';
import { useIntl } from 'react-intl';
import './TaskItem.css';
import DateHelper from '../../../../../systemUtils/common/DateHelpers';
import StatusBlock from '../../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import SideBar from '../../../../sharedControls/reusableBlocks/sideBar/SideBar';

const TaskItem: React.FC<TaskItemProps> = (props) => {
  const intl = useIntl();

  const renderDates = () => {
    return props.raisedDate || props.dueDate ? (
      <div role="grid" className="Task-Dates">
        {props.raisedDate && (
          <div role="grid" className="Task-Date-Item">
            <p className="Label">Raised</p>
            <p>{DateHelper.getLocalDateString(props.raisedDate, intl, 'MM')}</p>
          </div>
        )}
        {props.dueDate && (
          <div role="grid" className="Task-Date-Item">
            <p className="Label">Due</p>
            <p>{DateHelper.getLocalDateString(props.dueDate, intl, 'MM')}</p>
          </div>
        )}
      </div>
    ) : null;
  };

  const renderAssigned = (name?: string) => {
    if (!name) {
      return null;
    }
    const nameParts = name!.split(' ');
    const initials = nameParts.map((part) => part[0]).join('');

    return (
      <div className="assigned">
        <StatusBlock
          className="dark-background"
          showIcon={false}
          boxSize="small"
          content={initials}
          boxState="neutral"
          id="personStatus"
        />
        {name}
      </div>
    );
  };

  const renderPeopleAndCase = () => {
    return props.createdBy ? (
      <div role="grid" className="Task-Dates">
        {props.createdBy && (
          <div role="grid" className="Task-Date-Item">
            <div className="Label">Created By</div>
            {renderAssigned(props.createdBy)}
          </div>
        )}
        {renderCaseReference()}
      </div>
    ) : null;
  };

  const renderCaseReference = () => {
    return props.caseReference ? (
      <div className="Task-Case">
        <div className="Label">Case</div>
        <div>{props.caseReference}</div>
      </div>
    ) : null;
  };

  return (
    <div className="Task-Container">
      <SideBar
        FieldKey={'item'}
        boxState={props.controlState}
        includeInnerBar={false}
      />
      <div className="Task-Header">
        <StatusBlock
          boxState={props.controlState}
          boxSize="medium"
          id={`container_${props.id}`}
        />
        <h3 className="Task-Title Font-Heading">{props.headerLabel}</h3>
      </div>
      <div className="Task-Body">
        <p role="status" className={`Task-Status ${props.controlState}`}>
          {props.status}
        </p>
        {renderDates()}
        {renderPeopleAndCase()}
        {props.notes && (
          <div className="Task-Notes" key="notes">
            {props.notes.map((note, index) => {
              return (
                <>
                  <StatusBlock
                    boxState="neutral"
                    boxSize="small"
                    id={`${props.id}_statusBlock_${index}`}
                    key={`${props.id}_statusBlock_${index}`}
                  />
                  <p
                    className="lexxic-text"
                    id={`${props.id}_note_${index}`}
                    key={`${props.id}_note_${index}`}
                  >
                    {note}
                  </p>
                </>
              );
            })}
          </div>
        )}
        {props.completable && !props.completedDate && (
          <div className="Task-Completion">
            <label className="check-label lexxic-text">
              <input
                type="checkbox"
                onClick={() => {
                  if (!props.CompleteTicked) {
                    return;
                  }
                  props.CompleteTicked(props.id);
                }}
              />
              Mark As Complete
            </label>
          </div>
        )}
      </div>
      {props.taskFooter && props.taskFooter.showFooter && (
        <div className="Task-Footer lexxic-text">
          {props.taskFooter.footerText ?? ''}
        </div>
      )}
    </div>
  );
};

export default TaskItem;
