import React from 'react';
import BreadcrumbTypes from './Breadcrumb.types';

import { BsHouse } from 'react-icons/bs';

export const Breadcrumb: React.FC<BreadcrumbTypes.Props> = (props) => {
  return props.to === '/' ? (
    <li className="breadcrumb" key={`breadcrumb_home_${props.id}`}>
      <div
        className="breadcrumb_content breadcrumb_content--home"
        onClick={() => {
          if (props.onClick && props.to) props.onClick(props.to);
        }}
      >
        <BsHouse />
      </div>
    </li>
  ) : (
    <li
      key={`breadcrumb_navItem_${props.id}`}
      aria-label={props.labelText}
      className="breadcrumb Impact-Regular"
      onClick={() => {
        if (props.onClick && props.to) props.onClick(props.to);
      }}
    >
      <div className="breadcrumb_content">{props.labelText}</div>
    </li>
  );
};
export default Breadcrumb;
