import React, { FC, useEffect, useRef, useState } from 'react';
import { ServiceRowProps } from './ServiceRow.types';
import { FaCircle, FaClock } from 'react-icons/fa6';
import './ServiceRow.css';
import { FormControlRef } from '../../../../sharedControls/formControls/formControlContainer/FormControlContainer.types';
import { UserClaimsContext } from '../../../../sharedControls/contexts/UserClaimsContext';
import Expander from '../../../../sharedControls/general/expander/Expander';
import StatusLabel from '../../../../sharedControls/reusableBlocks/statusLabel/StatusLabel';
import UserCore from '../../../../../systemUtils/userUtils/SystemUserActions';
import FormTextCapture from '../../../../sharedControls/formControls/formTextCapture/FormTextCapture';
import StatusBlock from '../../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import BaseBlock from '../../../../sharedControls/reusableBlocks/baseBlock/BaseBlock';
import { getTimeSince } from '../../../../../systemUtils/common/CommonHelpers';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import FormDropDown from '../../../../sharedControls/formControls/formDropDown/FormDropDown';
/**
 * Renders a view row for a case.
 *
 * @param id - The ID of the case.
 * @param selected - Indicates whether the case is selected.
 * @param personName - The name of the person associated with the case.
 * @param lastModified - The date when the case was last modified.
 * @param serviceDisplayMode - The display mode of the case row.
 * @param orgName - The name of the organization associated with the case.
 * @param status - The status of the case.
 * @param assignedTo - The person assigned to the case.
 * @returns The JSX element representing the case table row.
 */
const ServiceRow: FC<ServiceRowProps> = (props) => {
  const formRef = React.useRef<FormControlRef>(null);
  const [isExpanded, setExpandedState] = useState<Boolean>(false);
  const [poNumber, setPoNumber] = useState<string>('');
  const [requiresProofing, setRequiresProofing] = useState<boolean>(false);
  const [reportReviewComplete, setReportReviewComplete] = useState<boolean>(false);
  const [assessorId, setAssessorId] = useState<string>('');
  const poNumberSet = useRef(false);
  const { userClaims } = React.useContext(UserClaimsContext);

  useEffect(() => {
    if (poNumberSet.current === true) {
      return;
    }

    poNumberSet.current = true;
    setPoNumber(props.poNumber || '');
  }, []);

  const handleExpandClick = () => {
    setExpandedState(!isExpanded);
  };

  const updatePoNumber = () => {
    if (poNumber.trim() === '') {
      return;
    }

    props.onUpdatePoNumber(props.id, poNumber);
  };

  /**
   * Renders the expand box for the case row.
   * @returns The JSX element representing the expand box.
   */
  const renderExpand = () => {
    return (
      <div className="expand-toggle">
        <Expander
          id={`${props.id}_Status`}
          key={`${props.id}_Status`}
          isExpanded={isExpanded}
          eventHandler={{ onClick: handleExpandClick }}
        />
      </div>
    );
  };

  const renderCollapsedHeader = () => {
    return (
      <>
        <div className="col">
          <span className=" Text-Regular">{props.serviceType}</span>
        </div>
        <div />
        <div />
        <div />
      </>
    );
  };

  const renderExpandedHeader = () => {
    return (
      <>
        <div className="col">
          <span className=" Text-Regular">{props.serviceType}</span>
        </div>
        <div className="col">
          <StatusLabel
            label={props.deliveryFormat}
            renderAs="span"
            key={`service_row_status_${props.id}`}
            status="neutral"
          />
        </div>
        <div className="col">
          <StatusLabel
            label={props.status}
            renderAs="span"
            key={`service_row_status_${props.id}`}
            status="positive"
          />
        </div>
        <div className="col">
          <StatusLabel
            label={props.relatedCondition}
            renderAs="span"
            key={`service_row_relatedCondition_${props.id}`}
          />
        </div>
      </>
    );
  };

  const renderExpandedRow = () => {
    return (
      <div className="Expanded-row">
        {UserCore.userIsCaseManagerOrHigher(userClaims!.user!) && (
          <>
            <div className="col Text-Regular">{props.labels.price}</div>
            {props.price ? (
              <div className="col Text-Regular Value-Column">{props.price}</div>
            ) : (
              <div className="col Text-Strong Value-Column">
                {props.labels.noPrice}
              </div>
            )}
            <div className="PoNumber Value-Column">
              <FormTextCapture
                fieldId="poNumber"
                id="poNumber"
                label={props.labels.poNumber}
                textInputType="text"
                value={poNumber}
                displayMode="Column"
                maxLength={250}
                onChange={(e) => setPoNumber(e.value as string)}
                requiredDetails={{ formLabel: '', message: '' }}
                /* set this to false when LX-1212 is complete*/
                readonly={true}
              />
              <div className="col">
                {/* todo: re-add this button when LX-1212 is complete
                    
                <Button
                  label='Update PO Number'
                  clickEvent={updatePoNumber}
                  itemKey={'updatePoNumber'}
                  disabled={poNumber.trim() === '' || poNumber === props.poNumber}
                  mode='positive'
                /> */}
              </div>
            </div>
          </>
        )}
        <div className="col Text-Regular">{props.labels.deliveryMethod}</div>
        <div className="Value-Column">
          <StatusLabel
            label={props.deliveryFormat}
            renderAs="span"
            key={`service_row_status_${props.id}`}
            status="neutral"
          />
        </div>
        <div className="col Text-Regular">{props.labels.statusHistory}</div>
        <div className="col Text-Regular Value-Column">
          {props.statusHistory &&
            props.statusHistory.map((status, index) => (
              <div
                key={`service_history_${props.id}_${index}`}
                className="Col-History"
              >
                <StatusBlock
                  boxSize="small"
                  boxState="neutral"
                  key={`service_history_${props.id}_${index}`}
                  showIcon={false}
                  content={<FaCircle color="#D9D9D9" size={7} />}
                />
                <div className="col">{status.statusName}</div>
                <BaseBlock
                  boxSize="small"
                  key={`service_Clock_${props.id}_${index}`}
                  content={<FaClock />}
                />
                <div className="col">{getTimeSince(status.statusDate)}</div>
              </div>
            ))}
        </div>
        <div className="col Text-Regular">{props.labels.relatedCondition}</div>
        <div className="col Text-Regular Value-Column">
          {props.relatedCondition}
        </div>
        {props?.otherRelatedCondition && (
          <>
            <div className="col Text-Regular">{props.labels.other}</div>
            <div className="col Text-Regular Value-Column">
              {props?.otherRelatedCondition}
            </div>
          </>
        )}

        <div className="col Text-Regular">{props.labels.serviceCode}</div>
        <div className="col Text-Regular Value-Column">{props.serviceCode}</div>

        <div className="col Text-Regular">{[props.labels.nominalCode]}</div>
        <div className="col Text-Regular Value-Column">{props.nominalCode}</div>

        {props.description && props.description.length > 0 && (
          <>
            <div className="col Text-Regular">{props.labels.description}</div>
            <div className="col Text-Regular Value-Column">
              {props.description}
            </div>
          </>
        )}

        <div />
        <div className="col-buttons">
          {props.buttons && (
            <>
              <ButtonBox
                id={`button-box-${props.id}`}
                key={`button-box-${props.id}`}
                className="Service-Add-Row-Button-Box"
                buttons={props.buttons.map((button, index) => {
                  if (button.id === 'button-box-report-review-proofing') {
                    return {
                      id: `${button.id}-${index}`,
                      label: button.label,
                      controlState: 'positive',
                      onClick: () => {
                        setRequiresProofing(!requiresProofing);
                      },
                      isChecked: requiresProofing,
                    };
                  }
                  if (button.id === 'button-box-report-review-complete') {
                    return {
                      id: `${button.id}-${index}`,
                      label: button.label,
                      controlState: 'positive',
                      onClick: () => {
                        setReportReviewComplete(!reportReviewComplete);
                      },
                      isChecked: reportReviewComplete,
                    };
                  }

                  return {
                    id: `${button.id}-${index}`,
                    label: button.label,
                    controlState: 'positive',
                    onClick: () => {
                      button.onClick();
                    },
                    isChecked: button.isChecked,
                  };
                })}
                displayBorder={true}
              />
              {requiresProofing && (
                <>
                  <FormDropDown
                    fieldId="serviceType"
                    id="serviceType"
                    label={props.labels.serviceType}
                    displayMode="Bordered"
                    items={props.assessors ?? []}
                    requiredDetails={{
                      formLabel: '',
                      message: props.labels.serviceError,
                    }}
                    value={assessorId}
                    onChange={(e) => setAssessorId(e.value as string)}
                    defaultText={props.labels.defaultText}
                    ref={formRef}
                  />
                  <ButtonBox
                    id={`button-box-commit-proofing-${props.id}`}
                    buttons={[
                      {
                        id: `button-box-commit-proofing-confirm-${props.id}`,
                        label: props.labels.commitButton,
                        controlState: 'positive',
                        onClick: () => {
                          if (formRef.current) {
                            const isValid = formRef.current.triggerValidation();
                            if (!isValid) {
                              return;
                            }
                            props.eventHandlers
                              ?.requiresProofing(props.id, assessorId)
                              .then((rst) => {
                                if (rst.isSuccess) {
                                  setRequiresProofing(false);
                                  setAssessorId('');
                                  return;
                                }
                                props.eventHandlers?.onError(rst.error!);
                              });
                          }
                        },
                      },
                      {
                        id: `button-box-commit-proofing-cancel-${props.id}`,
                        label: props.labels.cancelButton,
                        controlState: 'negative',
                        onClick: () => {
                          setRequiresProofing(false);
                          setAssessorId('');
                        },
                      },
                    ]}
                    className="Service-Add-Row-Button-Box"
                    key={`button-box-commit-proofingkey-${props.id}`}
                    displayBorder={true}
                  />
                </>
              )}
              {reportReviewComplete && (
                <>
                  <ButtonBox
                    id={`button-box-commit-review-${props.id}`}
                    buttons={[
                      {
                        id: `button-box-commit-review-confirm-${props.id}`,
                        label: props.labels.commitButton,
                        controlState: 'positive',
                        onClick: () => {
                          props.eventHandlers
                            ?.reportReviewComplete(props.id)
                            .then((rst) => {
                              if (rst.isSuccess) {
                                setReportReviewComplete(false);
                                return;
                              }
                              props.eventHandlers?.onError(rst.error!);
                            })
                            .catch((error) => {
                              props.eventHandlers?.onError(error.message || 'Unknown error');
                            });
                        }
                      },
                      {
                        id: `button-box-commit-review-cancel-${props.id}`,
                        label: props.labels.cancelButton,
                        controlState: 'negative',
                        onClick: () => {
                          setReportReviewComplete(false);
                        },
                      },
                    ]}
                    className="Service-Add-Row-Button-Box"
                    key={`button-box-commit-reviewkey-${props.id}`}
                    displayBorder={true}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="Service-Tab-Row">
      <Expander
        id={`${props.id}_Status_Main`}
        key={`${props.id}_Status`}
        isExpanded={isExpanded}
        eventHandler={{ onClick: handleExpandClick }}
      />
      {isExpanded ? renderCollapsedHeader() : renderExpandedHeader()}
      {isExpanded && renderExpandedRow()}
    </div>
  );
};

export default ServiceRow;
