import BookingTypes from '../../sysObjects/apiModels/Booking.Types';
import { getHostConfigAsync } from '../common/HostConfigActions';
import settings from '../../config/services.json';
import System_Types from '../../sysObjects/apiModels/System.types';
import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import { createAction, fetchAction } from '../common/APIHelper';

namespace BookingRepoActions {
  /**
   * Finds the related booking services
   * @param {IUserClaims} performingUser - The user under which the action
   * @returns
   */
  export const findBookableServicesAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<BookingTypes.Service[]>> => {
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    const host = await getHostConfigAsync();

    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.BookingRepository.FindBookableServices.path!}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  export const findStaffAvailabilityForServiceAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    query: BookingTypes.staffAvailabilityQuery
  ): Promise<FunctionalResult<BookingTypes.UserAvailabilityDetails[]>> => {
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    const host = await getHostConfigAsync();

    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    let queryParams: string[] = [];
    queryParams.push(
      `${settings.BookingRepository.FindStaffAvailabilityForService.QueryServiceId}=${encodeURIComponent(query.serviceId)}`
    );
    queryParams.push(
      `${
        settings.BookingRepository.FindStaffAvailabilityForService
          .QueryAvailabilityStart
      }=${encodeURIComponent(query.availabilityStartDateTime)}`
    );
    queryParams.push(
      `${
        settings.BookingRepository.FindStaffAvailabilityForService
          .QueryAvailabilityEnd
      }=${encodeURIComponent(query.availabilityEndDateTime)}`
    );

    queryParams.push(
      `${
        settings.BookingRepository.FindStaffAvailabilityForService
          .QueryAppointmentDurationOverride
      }=${encodeURIComponent(query.overrideTicks)}`
    );

    // Join all query parameters with '&' if there are any
    let queryPath = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${
        settings.BookingRepository.FindStaffAvailabilityForService!.path
      }${queryPath}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
    });
  };

  export const bookAppointmentAsync = async (
    headerInfo: FunctionalResult<System_Types.ApiHeader>,
    bookingRequest: BookingTypes.BookReq
  ): Promise<FunctionalResult<string>> => {
    if (
      headerInfo.isFailure ||
      headerInfo.result === null ||
      headerInfo.result === undefined
    ) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    const host = await getHostConfigAsync();

    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return createAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.BookingRepository.BookAppointment.path!}`,
      userId: headerInfo.result!.userId,
      accessToken: headerInfo.result!.token,
      formData: bookingRequest,
    });
  };
}
export default BookingRepoActions;
