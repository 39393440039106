import React from 'react';
import PagingSize from '../paging/pagingSize/PagingSize';
import PageSelector from '../paging/pageSelector/PageSelector';
import { pagingDirection } from '../../../../sysObjects/common.types';
import CommonTableTypes from './CommonTable.types';

import './CommonTable.css';

const CommonTable: React.FC<CommonTableTypes.Props> = (props) => {
  const [currentPageSize, setCurrentPageSize] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [startRange, setStartRange] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  React.useEffect(() => {
    setCurrentPageSize(props.pagingDetails.currentPageSize);
    setTotalPages(Math.ceil(props.rows.length / currentPageSize));
  }, []);

  return !props.headers ? null : (
    <div
      className={`Common-Table-Container${
        props.className ? ` ${props.className}` : ''
      }`}
    >
      <div className="Table-Title Font-Heading">{props.labels.tableName}</div>
      {props.rows.length > 0 ? (
        <table>
          <thead>
            <tr>
              {props.headers.map((header, index) => (
                <th key={`CTH_${index}`} className="Text-Regular">
                  {header}
                </th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!props.rows ? null : props.rows.length > 0 ? (
              props.rows
                .slice(
                  Math.max(startRange - 1, 0),
                  Math.max(startRange - 1, 0) === 0
                    ? currentPageSize
                    : startRange - 1 + currentPageSize,
                )
                .map((row, rowIndex) => (
                  <tr
                    key={`CT_Row_${row.id}_Index${rowIndex}`}
                    className="Text-Regular"
                  >
                    {row.data.map((data, colIndex) => (
                      <td
                        key={`CT_Row_${row.id}_Index${rowIndex}_Col_${colIndex}`}
                      >
                        {data}
                      </td>
                    ))}
                    <td className="Button-Column">{row.buttonColumn}</td>
                  </tr>
                ))
            ) : (
              <tr>
                <td
                  colSpan={props.headers.length + 1}
                  className="Table-Empty"
                ></td>
              </tr>
            )}
          </tbody>
          {props.rows.length > Math.min(...props.pagingDetails.pageSizes) ? (
            <tfoot>
              <tr>
                <td colSpan={props.headers.length + 2}>
                  <div className="CommonTable-Paging">
                    <div></div>
                    <div>
                      <PagingSize
                        label={props.labels.size}
                        pageSizes={props.pagingDetails.pageSizes}
                        value={currentPageSize}
                        onChange={(size) => {
                          setCurrentPageSize(size);
                          setTotalPages(Math.ceil(props.rows.length / size));
                          setStartRange(1);
                          setCurrentPage(1);
                        }}
                      />
                    </div>
                    <PageSelector
                      from={startRange < 1 ? 1 : startRange - 1}
                      to={
                        startRange + currentPageSize > props.rows.length
                          ? props.rows.length
                          : startRange < 1
                            ? currentPageSize
                            : startRange + currentPageSize - 2
                      }
                      total={props.rows.length}
                      ofLabel={props.labels.of}
                      pageChangeClick={(step: pagingDirection) => {
                        const totalPages = Math.ceil(
                          props.rows.length / currentPageSize,
                        );

                        switch (step) {
                          case 'Start':
                            setStartRange(1);
                            setCurrentPage(1);
                            break;
                          case 'Back':
                            let back = currentPage - 1;
                            if (back < 1) {
                              back = 1;
                            }
                            const backStartRange =
                              (back - 1) * currentPageSize + 1;
                            const adjustedBackStartRange =
                              backStartRange > props.rows.length
                                ? props.rows.length -
                                  ((props.rows.length - 1) % currentPageSize)
                                : backStartRange;
                            setStartRange(adjustedBackStartRange);
                            setCurrentPage(back);
                            break;
                          case 'Forward':
                            let next = currentPage + 1;
                            if (next > totalPages) {
                              next = totalPages;
                            }
                            setStartRange((next - 1) * currentPageSize + 1);
                            setCurrentPage(next);
                            break;
                          case 'End':
                            const lastPageStartRange =
                              (totalPages - 1) * currentPageSize + 1;
                            const adjustedStartRange =
                              lastPageStartRange > props.rows.length
                                ? props.rows.length -
                                  ((props.rows.length - 1) % currentPageSize)
                                : lastPageStartRange;
                            setStartRange(adjustedStartRange);
                            setCurrentPage(totalPages);
                            break;
                        }
                      }}
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          ) : null}
        </table>
      ) : (
        <div className="Table-No-Data">{props.labels.emptyMessage}</div>
      )}
    </div>
  );
};

export default CommonTable;
