import React from 'react';
import SVGTypes from './svg.types';

export const Bad: React.FC<SVGTypes.Props> = (props) => {
  return (
    <svg
      aria-label={props.ariaLabel}
      role="img"
      data-testid={props.testId}
      className={props.className}
      onClick={props.onClick}
      width={props.width}
      height={props.height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
        fill={props.fill}
        fillOpacity="0.0001"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2923 13.2923C13.3852 13.1991 13.4956 13.1252 13.6171 13.0748C13.7385 13.0244 13.8688 12.9985 14.0003 12.9985C14.1319 12.9985 14.2621 13.0244 14.3836 13.0748C14.5051 13.1252 14.6154 13.1991 14.7083 13.2923L20.0003 18.5863L25.2923 13.2923C25.3853 13.1993 25.4957 13.1255 25.6172 13.0752C25.7386 13.0249 25.8688 12.999 26.0003 12.999C26.1318 12.999 26.262 13.0249 26.3835 13.0752C26.505 13.1255 26.6153 13.1993 26.7083 13.2923C26.8013 13.3852 26.8751 13.4956 26.9254 13.6171C26.9757 13.7386 27.0016 13.8688 27.0016 14.0003C27.0016 14.1317 26.9757 14.2619 26.9254 14.3834C26.8751 14.5049 26.8013 14.6153 26.7083 14.7083L21.4143 20.0003L26.7083 25.2923C26.8013 25.3852 26.8751 25.4956 26.9254 25.6171C26.9757 25.7386 27.0016 25.8688 27.0016 26.0003C27.0016 26.1317 26.9757 26.2619 26.9254 26.3834C26.8751 26.5049 26.8013 26.6153 26.7083 26.7083C26.6153 26.8012 26.505 26.875 26.3835 26.9253C26.262 26.9756 26.1318 27.0015 26.0003 27.0015C25.8688 27.0015 25.7386 26.9756 25.6172 26.9253C25.4957 26.875 25.3853 26.8012 25.2923 26.7083L20.0003 21.4143L14.7083 26.7083C14.6153 26.8012 14.505 26.875 14.3835 26.9253C14.262 26.9756 14.1318 27.0015 14.0003 27.0015C13.8688 27.0015 13.7386 26.9756 13.6172 26.9253C13.4957 26.875 13.3853 26.8012 13.2923 26.7083C13.1993 26.6153 13.1256 26.5049 13.0753 26.3834C13.025 26.2619 12.9991 26.1317 12.9991 26.0003C12.9991 25.8688 13.025 25.7386 13.0753 25.6171C13.1256 25.4956 13.1993 25.3852 13.2923 25.2923L18.5863 20.0003L13.2923 14.7083C13.1992 14.6154 13.1253 14.505 13.0749 14.3835C13.0245 14.262 12.9985 14.1318 12.9985 14.0003C12.9985 13.8687 13.0245 13.7385 13.0749 13.617C13.1253 13.4955 13.1992 13.3852 13.2923 13.2923Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default Bad;
